import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <title>{'number_sign [#110]'}</title>
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M6 10h4V6H6v4Zm10-4V4h-4V0h-2v4H6V0H4v4H0v2h4v4H0v2h4v4h2v-4h4v4h2v-4h4v-2h-4V6h4Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
