import { map, z } from 'zod';
import { ValorantMap } from '../../../types';
import { ZAgentWinRate } from '../entities';

export const ZGetTopAgentsForMapRequest = z.object({
  params: z.object({
    map: z.nativeEnum(ValorantMap),
  }),
});

export const ZTopAgentsForMapResult = z.object({
  playerTopAgents: ZAgentWinRate.array(),
  communityTopAgents: ZAgentWinRate.array(),
});

export type ITopAgentsForMapResult = z.infer<typeof ZTopAgentsForMapResult>;
