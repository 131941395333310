import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { AppStatus } from '@guardian/common';
import { LocalStorageKey, getItem, CacheKeys } from '@guardian/ui-common';
import { baseURL } from '../utils';

export interface UseApiOptions {
  method?: AxiosRequestConfig['method'];
  cacheKey?: CacheKeys;
  refetchInterval?: number;
  overrideAppStatus?: boolean;
}

export interface UseQueryOptions<T> extends UseApiOptions {
  data?: T;
  enabled?: boolean;
}

export interface UseMutationOptions extends UseApiOptions {
  invalidateTags?: Array<CacheKeys>;
  timeout?: number;
}

function getFullUrl(path: string) {
  if (path.startsWith('/')) path = path.slice(1);
  return `${baseURL}/api/${path}`;
}

function getRequestHeaders() {
  const headers: any = {};

  const token = getItem(LocalStorageKey.UserToken);

  if (token) headers['authorization'] = `Bearer ${token}`;
  headers['Content-Type'] = 'application/json';

  return headers;
}

function getFetchDataFunction<TData>(url: string, options?: UseQueryOptions<TData>) {
  return async () => {
    try {
      const axiosConfig: AxiosRequestConfig = {};

      axiosConfig.url = url;
      axiosConfig.method = options?.method ?? 'GET';
      axiosConfig.headers = getRequestHeaders();

      if (options?.data) {
        axiosConfig.data = options.data;
      }

      const response = await axios.request(axiosConfig);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Unexpected status code: ' + response.status);
      }
    } catch (error) {
      throw error;
    }
  };
}

export function useWebsiteApiQuery<TData, TResponse>(path: string, options?: UseQueryOptions<TData>) {
  const fullUrl = getFullUrl(path);

  const queryKey = [options?.cacheKey ?? CacheKeys.default, fullUrl, JSON.stringify(options?.data) ?? ''];

  const query = useQuery<unknown, unknown, TResponse, string[]>(queryKey, getFetchDataFunction(fullUrl, options), {
    enabled: options?.enabled,
    suspense: false,
    refetchInterval: options?.refetchInterval,
  });

  return query;
}

export const useWebsiteApiMutation = <TData, TResult = unknown>(path: string, options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const url = getFullUrl(path);

  const mutation = useMutation<TResult, unknown, TData>(
    options?.cacheKey ?? url,
    (data?: TData) => {
      return new Promise((resolve, reject) => {
        const appStatus = getItem(LocalStorageKey.AppStatus);

        if (!options?.overrideAppStatus && appStatus !== AppStatus.live) {
          return;
        }

        const axiosConfig: AxiosRequestConfig = {};

        axiosConfig.url = url;
        axiosConfig.method = options?.method ?? 'POST';
        axiosConfig.headers = getRequestHeaders();
        axiosConfig.timeout = options?.timeout;

        if (data) {
          axiosConfig.data = data;
        }

        axios
          .request(axiosConfig)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    {
      onSuccess: () => {
        if (!options?.invalidateTags) return;

        options.invalidateTags.forEach(x => {
          queryClient.invalidateQueries(x);
        });
      },
    },
  );

  return {
    fetch: mutation.mutate,
    fetchAsync: mutation.mutateAsync,
    data: mutation.data,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
  };
};
