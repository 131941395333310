import { z } from 'zod';
import { ZEntity } from '../../_base';
import { BugArea } from '../../../enums';

export const ZBugReport = ZEntity.extend({
  description: z.string().min(1).max(1000),
  area: z.nativeEnum(BugArea),
  email: z.string().email().optional(),
  userId: z.string(),
  overwolfVersion: z.string(),
  appVersion: z.string(),
  handled: z.boolean().nullable(),
  handledBy: z.string().nullable(),
  handledAt: z.date().nullable(),
  handledNotes: z.string().nullable(),
});

export type IBugReport = z.infer<typeof ZBugReport>;
