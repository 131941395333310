import React from 'react';
import { RouteObject, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { WebsiteLayout, HomePage, Showcase } from '../components';
import { PrivacyPolicy } from '../components/privacy-policy/PrivacyPolicy';
import { TermsOfService } from '../components/terms-of-service/TermsOfService';
import { RiotLogin } from '../components/riot-login/RiotLogin';

export const MainRoutes: RouteObject[] = [
  {
    path: '/',
    element: <WebsiteLayout />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: '/showcase/:userId',
        element: <Showcase />,
      },
      {
        path: '/privacypolicy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/tos',
        element: <TermsOfService />,
      },
      {
        path: '/riot/:authAction',
        element: <RiotLogin />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const router = createBrowserRouter(MainRoutes);

export const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};
