import { z } from 'zod';
import { ZGuardianBehaviour } from '../entities';
import { GuardianBehaviourType } from '../../../enums';

export const ZApiGuardianBehaviour = ZGuardianBehaviour.omit({
  data: true,
}).extend({
  type: z.nativeEnum(GuardianBehaviourType),
});

export const ZAddBehavioursArgs = z.object({
  guardianBehaviours: ZGuardianBehaviour.array(),
});

export type IAddBehavioursArgs = z.infer<typeof ZAddBehavioursArgs>;

export const ZAddBehavioursRequest = z.object({
  body: ZAddBehavioursArgs,
  params: z
    .object({
      source: z.string().optional(),
    })
    .optional(),
});
