import { BehaviourNature, GuardianBehaviourType } from '../enums';
import { GuardianBehaviour } from '../models';
import { z } from 'zod';

export const ZMatchInsight = z.object({
  behaviourType: z.nativeEnum(GuardianBehaviourType),
  tag: z.string(),
  text: z.string(),
  importance: z.number(),
  instancesCount: z.number(),
  nature: z.literal(BehaviourNature.negative).or(z.literal(BehaviourNature.positive)).or(z.literal(BehaviourNature.neutral)),
  rounds: z.number().optional(),
});

export type IMatchInsight = z.infer<typeof ZMatchInsight>;

export type GuardianBehaviourInstance = {
  behaviour: GuardianBehaviour;
  isRoundWon: boolean;
  previousBehaviours: GuardianBehaviour[]; // Order is reverse, index 0 is the previous, index 1 is the one before that, etc.
  followingBehaviours: GuardianBehaviour[];
};

export type MatchDetailsBehaviourTypeSummary = {
  type: GuardianBehaviourType;
  roundsWon: number;
  roundsLost: number;
  totalRounds: number;
  instances: GuardianBehaviourInstance[];
};

export type GuardianBehaviourSummaries = Partial<Record<GuardianBehaviourType, MatchDetailsBehaviourTypeSummary>>;

export type GenerateInsightsArgs = {
  summaries: GuardianBehaviourSummaries;
};
