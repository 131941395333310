import { z } from "zod";
import { defaultPage, defaultPageSize } from "../../../consts";

export const ZFilterBase = z.object({
    ids: z.array(z.string()).optional(),
    page: z.number().optional().default(defaultPage),
    pageSize: z.number().optional().default(defaultPageSize),
})

export const ZFilterResponseBase = z.object({
    count: z.number(),
})