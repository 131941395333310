import { z } from 'zod';
import { ValorantAgent, ValorantMap } from '../../../types';

export const ZGetAgentInsightsArgs = z.object({
  agent: z.nativeEnum(ValorantAgent).optional(),
  map: z.nativeEnum(ValorantMap).optional(),
});

export const ZGetAgentInsightsRequest = z.object({
  body: ZGetAgentInsightsArgs,
});

export type IGetAgentInsightsArgs = z.infer<typeof ZGetAgentInsightsArgs>;
