import { z } from 'zod';
import { LoginFailReason } from '../../../enums';

export const ZLoginArgsBase = z.object({
  userId: z.string(),
  refferedByUserId: z.string().optional(),
});

export const ZLoginArgs = z.discriminatedUnion('isGuest', [
  ZLoginArgsBase.extend({ isGuest: z.literal(true) }),
  ZLoginArgsBase.extend({
    isGuest: z.literal(false),
    userToken: z.string(),
    userName: z.string().min(1),
    extensionId: z.string(),
  }),
]);

export const ZLoginRequest = z.object({
  body: ZLoginArgs,
});

export const ZLoginResponse = z.object({
  success: z.boolean(),
  reason: z.nativeEnum(LoginFailReason).optional(),
  authToken: z.string().optional(),
  userId: z.string().optional(),
  userName: z.string().optional(),
});

export type ILoginArgs = z.infer<typeof ZLoginArgs>;
export type ILoginResponse = z.infer<typeof ZLoginResponse>;
