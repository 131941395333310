import { WebsiteHeader } from '../website-header/WebsiteHeader';
import { contentContainerCss, footerCss, linkCss, termsOfServiceLinkCss, websiteLayoutCss } from './website-layout.css';
import { Outlet, useNavigate } from 'react-router-dom';

export const WebsiteLayout: React.FC = () => {
  const navigate = useNavigate();
  const onPrivacyPolicyClick = () => {
    navigate('/privacypolicy');
  };

  const onTermsOfServiceClick = () => {
    navigate('/tos');
  };

  return (
    <div className={websiteLayoutCss}>
      <WebsiteHeader />
      <div className={contentContainerCss}>
        <Outlet />
      </div>
      <div className={footerCss}>
        <span onClick={onPrivacyPolicyClick} className={linkCss}>
          Privacy Policy
        </span>
        <span onClick={onTermsOfServiceClick} className={termsOfServiceLinkCss}>
          Terms of Service
        </span>
      </div>
    </div>
  );
};
