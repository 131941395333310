import { z } from 'zod';
import { ZEntity } from '../../_base';
import { UserActivityType } from '../../../enums';

export const ZUserActivity = ZEntity.extend({
  userId: z.string(),
  type: z.nativeEnum(UserActivityType),
});

export type IUserActivity = z.infer<typeof ZUserActivity>;

export const ZUserActivityWithCreationDate = ZUserActivity.extend({
  createdAt: z.coerce.date(),
});

export type IUserActivityWithCreationDate = z.infer<typeof ZUserActivityWithCreationDate>;
