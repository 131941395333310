import { SizeTypeInfoMap } from '@/shared/types/display-window';
import { ResolutionSizeType } from '@guardian/common';

export const desktopSizeTypeInfoMap: SizeTypeInfoMap = {
  [ResolutionSizeType.small]: {
    sizeType: ResolutionSizeType.small,
    width: 1200,
    height: 700,
    text: '1200 x 700',
  },
  [ResolutionSizeType.normal]: {
    sizeType: ResolutionSizeType.normal,
    width: 1500,
    height: 875,
    text: '1500 x 875',
  },
  [ResolutionSizeType.large]: {
    sizeType: ResolutionSizeType.large,
    width: 1920,
    height: 1120,
    text: '1920 x 1120',
  },
};
