import { BehaviourNature, CharacterMood, GuardianBehaviourType } from '../../../enums';
import { ValorantAgent, Team, ValorantMap, Ranks } from '../../../types';
import { z } from 'zod';

export const ZPLayerBehaviourInfo = z.object({
  playerId: z.string().optional(),
  agent: z.nativeEnum(ValorantAgent).optional(),
  name: z.string().optional(),
  isLocalPlayer: z.boolean(),
});

export type PlayerBehaviourInfo = z.infer<typeof ZPLayerBehaviourInfo>;

export const ZGuardianBehaviourInfoBase = z.object({
  matchId: z.string(),
  roundId: z.string(),
  isLocalPlayer: z.boolean(),
  agent: z.nativeEnum(ValorantAgent).optional(),
  rank: z.nativeEnum(Ranks).optional(),
  name: z.string().optional(),
  playerId: z.string().optional(),
  team: z.nativeEnum(Team).optional(),
  isWinning: z.boolean(),
  hasAdvantage: z.boolean(),
  isEven: z.boolean(),
  map: z.nativeEnum(ValorantMap).nullable().optional(),
  roundNumber: z.number(),
  roundsWon: z.number(),
  roundsLost: z.number(),
  enemyPlayersAlive: z.number(),
  allyPlayersAlive: z.number(),
  kills: z.number(),
  deaths: z.number(),
  assists: z.number(),
  isSpikePlanted: z.boolean(),
  matchTimestamp: z
    .number()
    .nullable()
    .transform(x => (!x ? -1 : x)),
  roundTimestamp: z.number(),
});

export type GuardianBehaviourInfoBase = z.infer<typeof ZGuardianBehaviourInfoBase>;

export const ZTradeBehaviourInfoBase = z.object({
  tradeOpener: ZPLayerBehaviourInfo,
});

export const ZAssistBehaviourInfoBase = z.object({
  victim: ZPLayerBehaviourInfo,
  attacker: ZPLayerBehaviourInfo,
});

const ZFailedTradeInfoBase = ZTradeBehaviourInfoBase.extend({ type: z.literal(GuardianBehaviourType.failedSwiftTrade) });
const ZFailedLateTradeInfoBase = ZTradeBehaviourInfoBase.extend({ type: z.literal(GuardianBehaviourType.failedTrade) });
const ZTradedInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.swiftTraded),
  tradeVictim: ZPLayerBehaviourInfo,
  tradeCloser: ZPLayerBehaviourInfo,
  timeForTrade: z.number(),
  potentialTradeId: z.string(),
});
const ZTradedLateInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.traded),
  tradeVictim: ZPLayerBehaviourInfo,
  tradeCloser: ZPLayerBehaviourInfo,
  timeForTrade: z.number(),
  potentialTradeId: z.string(),
});
const ZClosedTradeInfoBase = ZTradeBehaviourInfoBase.extend({
  type: z.literal(GuardianBehaviourType.closedSwiftTrade),
  timeForTrade: z.number(),
  potentialTradeId: z.string(),
  tradeVictim: ZPLayerBehaviourInfo,
});
const ZClosedLateTradeInfoBase = ZTradeBehaviourInfoBase.extend({
  type: z.literal(GuardianBehaviourType.closedTrade),
  timeForTrade: z.number(),
  potentialTradeId: z.string(),
  tradeVictim: ZPLayerBehaviourInfo,
});
const ZFirstKillInfoBase = z.object({ type: z.literal(GuardianBehaviourType.firstKill), victim: ZPLayerBehaviourInfo });
const ZFirstDeathInfoBase = z.object({ type: z.literal(GuardianBehaviourType.firstDeath), attacker: ZPLayerBehaviourInfo });
const ZFirstAssistInfoBase = ZAssistBehaviourInfoBase.extend({ type: z.literal(GuardianBehaviourType.firstAssist) });
const ZAssistInfoBase = ZAssistBehaviourInfoBase.extend({ type: z.literal(GuardianBehaviourType.assist) });
const ZLostAdvantageInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.lostAdvantage),
  advantageLostTo: ZPLayerBehaviourInfo,
});
const ZGainedAdvantageInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.gainedAdvantage),
  advantageGainedFrom: ZPLayerBehaviourInfo,
});
const ZEvenedAdvantageInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.evenedAdvantage),
  advantageEvenedWith: ZPLayerBehaviourInfo,
});
const ZGaveAdvantageInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.gaveAdvantage),
  advantageGivenTo: ZPLayerBehaviourInfo,
});
const ZDiedNoTradeInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.diedNoTrade),
  attacker: ZPLayerBehaviourInfo,
  potentialTradeId: z.string(),
});
const ZKilledNoTradeInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.killedNoTrade),
  victim: ZPLayerBehaviourInfo,
  potentialTradeId: z.string(),
});
const ZKilledBehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.killed),
  victim: ZPLayerBehaviourInfo,
});
const ZDiedBehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.died),
  attacker: ZPLayerBehaviourInfo,
});
const ZClutch1v2BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.clutch1v2),
});
const ZClutch1v3BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.clutch1v3),
});
const ZClutch1v4BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.clutch1v4),
});
const ZClutch1v5BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.clutch1v5),
});
const ZLostClutch2v1BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.lostClutch2v1),
});
const ZLostClutch3v1BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.lostClutch3v1),
});
const ZLostClutch4v1BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.lostClutch4v1),
});
const ZLostClutch5v1BehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.lostClutch5v1),
});
const ZSpikePlantedBehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.spikePlanted),
});
const ZSpikeDefusedBehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.spikeDefused),
});
const ZSpikeDetonatedBehaviourInfoBase = z.object({
  type: z.literal(GuardianBehaviourType.spikeDetonated),
});

export const ZGuardianBehaviourInfo = z.discriminatedUnion('type', [
  ZFailedTradeInfoBase,
  ZTradedInfoBase,
  ZClosedTradeInfoBase,
  ZFirstKillInfoBase,
  ZFirstDeathInfoBase,
  ZFirstAssistInfoBase,
  ZAssistInfoBase,
  ZLostAdvantageInfoBase,
  ZGainedAdvantageInfoBase,
  ZEvenedAdvantageInfoBase,
  ZGaveAdvantageInfoBase,
  ZDiedNoTradeInfoBase,
  ZKilledNoTradeInfoBase,
  ZKilledBehaviourInfoBase,
  ZDiedBehaviourInfoBase,
  ZClutch1v2BehaviourInfoBase,
  ZClutch1v3BehaviourInfoBase,
  ZClutch1v4BehaviourInfoBase,
  ZClutch1v5BehaviourInfoBase,
  ZTradedLateInfoBase,
  ZClosedLateTradeInfoBase,
  ZFailedLateTradeInfoBase,
  ZLostClutch2v1BehaviourInfoBase,
  ZLostClutch3v1BehaviourInfoBase,
  ZLostClutch4v1BehaviourInfoBase,
  ZLostClutch5v1BehaviourInfoBase,
  ZSpikePlantedBehaviourInfoBase,
  ZSpikeDefusedBehaviourInfoBase,
  ZSpikeDetonatedBehaviourInfoBase,
]);

export type GuardianBehaviourInfo = z.infer<typeof ZGuardianBehaviourInfo>;

export const ZGuardianBehaviourData = ZFailedTradeInfoBase.or(ZTradedInfoBase)
  .or(ZClosedTradeInfoBase)
  .or(ZFirstKillInfoBase)
  .or(ZFirstDeathInfoBase)
  .or(ZFirstAssistInfoBase)
  .or(ZAssistInfoBase)
  .or(ZLostAdvantageInfoBase)
  .or(ZGainedAdvantageInfoBase)
  .or(ZEvenedAdvantageInfoBase)
  .or(ZGaveAdvantageInfoBase)
  .or(ZDiedNoTradeInfoBase)
  .or(ZKilledNoTradeInfoBase)
  .or(ZKilledBehaviourInfoBase)
  .or(ZDiedBehaviourInfoBase)
  .or(ZClutch1v2BehaviourInfoBase)
  .or(ZClutch1v3BehaviourInfoBase)
  .or(ZClutch1v4BehaviourInfoBase)
  .or(ZClutch1v5BehaviourInfoBase)
  .or(ZTradedLateInfoBase)
  .or(ZClosedLateTradeInfoBase)
  .or(ZFailedLateTradeInfoBase)
  .or(ZLostClutch2v1BehaviourInfoBase)
  .or(ZLostClutch3v1BehaviourInfoBase)
  .or(ZLostClutch4v1BehaviourInfoBase)
  .or(ZLostClutch5v1BehaviourInfoBase)
  .or(ZSpikePlantedBehaviourInfoBase)
  .or(ZSpikeDefusedBehaviourInfoBase)
  .or(ZSpikeDetonatedBehaviourInfoBase);

export type GuardianBehaviourData = z.infer<typeof ZGuardianBehaviourData>;

export const ZGuardianBehaviour = ZGuardianBehaviourInfoBase.extend({
  data: ZGuardianBehaviourData,
});

export type GuardianBehaviour = z.infer<typeof ZGuardianBehaviour>;

export const BehaviourToNature: Record<GuardianBehaviourType, BehaviourNature> = {
  [GuardianBehaviourType.failedSwiftTrade]: BehaviourNature.negative,
  [GuardianBehaviourType.failedTrade]: BehaviourNature.negative,
  [GuardianBehaviourType.closedSwiftTrade]: BehaviourNature.positive,
  [GuardianBehaviourType.closedTrade]: BehaviourNature.positive,
  [GuardianBehaviourType.firstKill]: BehaviourNature.positive,
  [GuardianBehaviourType.firstDeath]: BehaviourNature.negative,
  [GuardianBehaviourType.firstAssist]: BehaviourNature.positive,
  [GuardianBehaviourType.lostAdvantage]: BehaviourNature.negative,
  [GuardianBehaviourType.gainedAdvantage]: BehaviourNature.positive,
  [GuardianBehaviourType.evenedAdvantage]: BehaviourNature.positive,
  [GuardianBehaviourType.swiftTraded]: BehaviourNature.positive,
  [GuardianBehaviourType.traded]: BehaviourNature.positive,
  [GuardianBehaviourType.gaveAdvantage]: BehaviourNature.negative,
  [GuardianBehaviourType.assist]: BehaviourNature.positive,
  [GuardianBehaviourType.diedNoTrade]: BehaviourNature.negative,
  [GuardianBehaviourType.killedNoTrade]: BehaviourNature.positive,
  [GuardianBehaviourType.killed]: BehaviourNature.neutral,
  [GuardianBehaviourType.died]: BehaviourNature.neutral,
  [GuardianBehaviourType.clutch1v2]: BehaviourNature.positive,
  [GuardianBehaviourType.clutch1v3]: BehaviourNature.positive,
  [GuardianBehaviourType.clutch1v4]: BehaviourNature.positive,
  [GuardianBehaviourType.clutch1v5]: BehaviourNature.positive,
  [GuardianBehaviourType.spikeDefused]: BehaviourNature.teamDependant,
  [GuardianBehaviourType.spikeDetonated]: BehaviourNature.teamDependant,
  [GuardianBehaviourType.spikePlanted]: BehaviourNature.teamDependant,
  [GuardianBehaviourType.lostClutch2v1]: BehaviourNature.negative,
  [GuardianBehaviourType.lostClutch3v1]: BehaviourNature.negative,
  [GuardianBehaviourType.lostClutch4v1]: BehaviourNature.negative,
  [GuardianBehaviourType.lostClutch5v1]: BehaviourNature.negative,
};

export const BehaviourToScore: Record<GuardianBehaviourType, number> = {
  [GuardianBehaviourType.firstKill]: 6,
  [GuardianBehaviourType.firstDeath]: -8,
  [GuardianBehaviourType.firstAssist]: 6,

  [GuardianBehaviourType.killed]: 1,
  [GuardianBehaviourType.died]: -1,
  [GuardianBehaviourType.assist]: 3,

  [GuardianBehaviourType.failedSwiftTrade]: -4,
  [GuardianBehaviourType.failedTrade]: -3,
  [GuardianBehaviourType.closedSwiftTrade]: 10,
  [GuardianBehaviourType.closedTrade]: 8,
  [GuardianBehaviourType.swiftTraded]: 10,
  [GuardianBehaviourType.traded]: 8,
  [GuardianBehaviourType.killedNoTrade]: 4,
  [GuardianBehaviourType.diedNoTrade]: -6,

  [GuardianBehaviourType.lostAdvantage]: -4,
  [GuardianBehaviourType.gaveAdvantage]: -6,
  [GuardianBehaviourType.gainedAdvantage]: 6,
  [GuardianBehaviourType.evenedAdvantage]: 4,

  [GuardianBehaviourType.clutch1v2]: 3,
  [GuardianBehaviourType.clutch1v3]: 5,
  [GuardianBehaviourType.clutch1v4]: 7,
  [GuardianBehaviourType.clutch1v5]: 9,

  [GuardianBehaviourType.lostClutch2v1]: -5,
  [GuardianBehaviourType.lostClutch3v1]: -9,
  [GuardianBehaviourType.lostClutch4v1]: -11,
  [GuardianBehaviourType.lostClutch5v1]: -15,

  [GuardianBehaviourType.spikeDefused]: 3,
  [GuardianBehaviourType.spikeDetonated]: 1,
  [GuardianBehaviourType.spikePlanted]: 3,
};

export function getGuardianBehaviourScore(behaviourType: GuardianBehaviourType, team?: Team | 'Unknown') {
  if ([GuardianBehaviourType.spikeDetonated, GuardianBehaviourType.spikePlanted].includes(behaviourType)) {
    return team === Team.attacker ? BehaviourToScore[behaviourType] : BehaviourToScore[behaviourType] * -1;
  }

  if ([GuardianBehaviourType.spikeDefused].includes(behaviourType)) {
    return team === Team.defender ? BehaviourToScore[behaviourType] : BehaviourToScore[behaviourType] * -1;
  }

  return BehaviourToScore[behaviourType];
}

export const GuardianBehaviourToDefaultCharacterMood: Record<GuardianBehaviourType, CharacterMood | undefined> = {
  [GuardianBehaviourType.failedSwiftTrade]: CharacterMood.encouraging,
  [GuardianBehaviourType.failedTrade]: CharacterMood.encouraging,
  [GuardianBehaviourType.closedSwiftTrade]: CharacterMood.hyped,
  [GuardianBehaviourType.closedTrade]: CharacterMood.default,
  [GuardianBehaviourType.firstKill]: CharacterMood.hyped,
  [GuardianBehaviourType.firstDeath]: CharacterMood.angry,
  [GuardianBehaviourType.firstAssist]: CharacterMood.hyped,
  [GuardianBehaviourType.lostAdvantage]: CharacterMood.angry,
  [GuardianBehaviourType.gainedAdvantage]: CharacterMood.default,
  [GuardianBehaviourType.evenedAdvantage]: CharacterMood.default,
  [GuardianBehaviourType.swiftTraded]: CharacterMood.hyped,
  [GuardianBehaviourType.traded]: CharacterMood.default,
  [GuardianBehaviourType.gaveAdvantage]: CharacterMood.angry,
  [GuardianBehaviourType.assist]: CharacterMood.hyped,
  [GuardianBehaviourType.diedNoTrade]: CharacterMood.default,
  [GuardianBehaviourType.killedNoTrade]: CharacterMood.default,
  [GuardianBehaviourType.killed]: CharacterMood.default,
  [GuardianBehaviourType.died]: CharacterMood.default,
  [GuardianBehaviourType.clutch1v2]: CharacterMood.hyped,
  [GuardianBehaviourType.clutch1v3]: CharacterMood.hyped,
  [GuardianBehaviourType.clutch1v4]: CharacterMood.hyped,
  [GuardianBehaviourType.clutch1v5]: CharacterMood.hyped,
  [GuardianBehaviourType.lostClutch2v1]: CharacterMood.tired,
  [GuardianBehaviourType.lostClutch3v1]: CharacterMood.tired,
  [GuardianBehaviourType.lostClutch4v1]: CharacterMood.tired,
  [GuardianBehaviourType.lostClutch5v1]: CharacterMood.tired,
  [GuardianBehaviourType.spikeDefused]: CharacterMood.default,
  [GuardianBehaviourType.spikeDetonated]: CharacterMood.default,
  [GuardianBehaviourType.spikePlanted]: CharacterMood.default,
};

export function getGuardianBehaviourCharacterMood(behaviourType: GuardianBehaviourType, team?: Team | 'Unknown'): CharacterMood {
  if (GuardianBehaviourToDefaultCharacterMood[behaviourType] !== undefined) {
    return GuardianBehaviourToDefaultCharacterMood[behaviourType]!;
  }

  if ([GuardianBehaviourType.spikeDetonated, GuardianBehaviourType.spikePlanted].includes(behaviourType)) {
    return team === Team.attacker ? CharacterMood.hyped : CharacterMood.default;
  }

  if ([GuardianBehaviourType.spikeDefused].includes(behaviourType)) {
    return team === Team.defender ? CharacterMood.hyped : CharacterMood.default;
  }

  return CharacterMood.default;
}

export const BehaviourToPluralTitle: Record<GuardianBehaviourType, string> = {
  [GuardianBehaviourType.failedSwiftTrade]: 'Failed Swift Trades',
  [GuardianBehaviourType.failedTrade]: 'Failed Trades',
  [GuardianBehaviourType.closedSwiftTrade]: 'Closed Swift Trades',
  [GuardianBehaviourType.closedTrade]: 'Closed Trades',
  [GuardianBehaviourType.firstKill]: 'First Kills',
  [GuardianBehaviourType.firstDeath]: 'First Deaths',
  [GuardianBehaviourType.firstAssist]: 'First Assists',
  [GuardianBehaviourType.lostAdvantage]: 'Lost Advantages',
  [GuardianBehaviourType.gainedAdvantage]: 'Gained Advantages',
  [GuardianBehaviourType.evenedAdvantage]: 'Evened Advantages',
  [GuardianBehaviourType.swiftTraded]: 'Swift Trades',
  [GuardianBehaviourType.traded]: 'Trades',
  [GuardianBehaviourType.gaveAdvantage]: 'Gave Advantages',
  [GuardianBehaviourType.assist]: 'Assists',
  [GuardianBehaviourType.diedNoTrade]: 'Deaths without being traded',
  [GuardianBehaviourType.killedNoTrade]: 'Kills without dying to trades',
  [GuardianBehaviourType.killed]: 'Kills',
  [GuardianBehaviourType.died]: 'Deaths',
  [GuardianBehaviourType.clutch1v2]: '1v2 Clutches',
  [GuardianBehaviourType.clutch1v3]: '1v3 Clutches',
  [GuardianBehaviourType.clutch1v4]: '1v4 Clutches',
  [GuardianBehaviourType.clutch1v5]: '1v5 Clutches',
  [GuardianBehaviourType.lostClutch2v1]: '2v1 Clutches Lost',
  [GuardianBehaviourType.lostClutch3v1]: '3v1 Clutches Lost',
  [GuardianBehaviourType.lostClutch4v1]: '4v1 Clutches Lost',
  [GuardianBehaviourType.lostClutch5v1]: '5v1 Clutches Lost',
  [GuardianBehaviourType.spikeDefused]: 'Spikes Defused',
  [GuardianBehaviourType.spikeDetonated]: 'Spikes Detonated',
  [GuardianBehaviourType.spikePlanted]: 'Spikes Planted',
};

export const BehaviourToTitle: Record<GuardianBehaviourType, string> = {
  [GuardianBehaviourType.failedSwiftTrade]: 'Failed Swift Trade',
  [GuardianBehaviourType.failedTrade]: 'Failed Trade',
  [GuardianBehaviourType.closedSwiftTrade]: 'Closed Swift Trade',
  [GuardianBehaviourType.closedTrade]: 'Closed Trade',
  [GuardianBehaviourType.firstKill]: 'First Kill',
  [GuardianBehaviourType.firstDeath]: 'First Death',
  [GuardianBehaviourType.firstAssist]: 'First Assist',
  [GuardianBehaviourType.lostAdvantage]: 'Lost Advantage',
  [GuardianBehaviourType.gainedAdvantage]: 'Gained Advantage',
  [GuardianBehaviourType.evenedAdvantage]: 'Evened Advantage',
  [GuardianBehaviourType.swiftTraded]: 'Swift Trade',
  [GuardianBehaviourType.traded]: 'Trade',
  [GuardianBehaviourType.gaveAdvantage]: 'Gave Advantage',
  [GuardianBehaviourType.assist]: 'Assist',
  [GuardianBehaviourType.diedNoTrade]: 'Died without being traded',
  [GuardianBehaviourType.killedNoTrade]: 'Killed without dying to a trade',
  [GuardianBehaviourType.killed]: 'Kill',
  [GuardianBehaviourType.died]: 'Die',
  [GuardianBehaviourType.clutch1v2]: '1v2 Clutch',
  [GuardianBehaviourType.clutch1v3]: '1v3 Clutch',
  [GuardianBehaviourType.clutch1v4]: '1v4 Clutch',
  [GuardianBehaviourType.clutch1v5]: '1v5 Clutch',
  [GuardianBehaviourType.lostClutch2v1]: '2v1 Clutch Lost',
  [GuardianBehaviourType.lostClutch3v1]: '3v1 Clutch Lost',
  [GuardianBehaviourType.lostClutch4v1]: '4v1 Clutch Lost',
  [GuardianBehaviourType.lostClutch5v1]: '5v1 Clutch Lost',
  [GuardianBehaviourType.spikeDefused]: 'Spike Defused',
  [GuardianBehaviourType.spikeDetonated]: 'Spike Detonated',
  [GuardianBehaviourType.spikePlanted]: 'Spike Planted',
};
