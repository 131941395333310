import { z } from 'zod';
import { FileItemKey } from '../../../enums';

export const ZGetFileParams = z.object({
  fileItemKey: z.nativeEnum(FileItemKey),
});

export const ZGetFileRequest = z.object({
  params: ZGetFileParams,
});
