import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 1024 1024" width="1em" height="1em" {...props}>
    <g fill={props.fill ?? '#F5F5F5'}>
      <path d="M512 85.333 761.6 384H262.4z" />
      <path d="M426.667 320h170.666v576H426.667z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
