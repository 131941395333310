import '@guardian/ui-common/src/style/global.css';
import { Router } from './routing';
import clsx from 'clsx';
import { globalThemeClass } from '@guardian/ui-common';
import { guardianWebsite, versionContainer } from './app.css';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient();

function App() {
  return (
    <div className={clsx(globalThemeClass, guardianWebsite)}>
      <QueryClientProvider client={queryClient}>
        <div className={versionContainer}>v0.0.1</div>
        <Router />
      </QueryClientProvider>
    </div>
  );
}

export default App;
