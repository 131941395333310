import { z } from 'zod';
import { GuardianBehaviourType } from '../../../enums';

export const ZGetPercentilesByValuesArgs = z.object({
  firstKills: z.coerce.number(),
  firstAssists: z.coerce.number(),
  closedTrades: z.coerce.number(),
  traded: z.coerce.number(),
  gainedAdvantage: z.coerce.number(),
  evenedAdvantage: z.coerce.number(),
  assists: z.coerce.number(),
  killedNoTrade: z.coerce.number(),
  diedNoTrade: z.coerce.number(),
  firstDeaths: z.coerce.number(),
  lostAdvantage: z.coerce.number(),
  gaveAdvantage: z.coerce.number(),
  failedTrade: z.coerce.number(),
});

export type IGetPercentilesByValuesArgs = z.infer<typeof ZGetPercentilesByValuesArgs>;

export const ZGetPerformanceByValuesRequest = z.object({
  body: ZGetPercentilesByValuesArgs,
});

export function getValueByBehaviour(behaviour: GuardianBehaviourType, values: IGetPercentilesByValuesArgs): number {
  switch (behaviour) {
    case GuardianBehaviourType.firstKill:
      return values.firstKills;
    case GuardianBehaviourType.firstAssist:
      return values.firstAssists;
    case GuardianBehaviourType.closedSwiftTrade:
    case GuardianBehaviourType.closedTrade:
      return values.closedTrades;
    case GuardianBehaviourType.swiftTraded:
    case GuardianBehaviourType.traded:
      return values.traded;
    case GuardianBehaviourType.gainedAdvantage:
      return values.gainedAdvantage;
    case GuardianBehaviourType.evenedAdvantage:
      return values.evenedAdvantage;
    case GuardianBehaviourType.assist:
      return values.assists;
    case GuardianBehaviourType.killedNoTrade:
      return values.killedNoTrade;
    case GuardianBehaviourType.diedNoTrade:
      return values.diedNoTrade;
    case GuardianBehaviourType.firstDeath:
      return values.firstDeaths;
    case GuardianBehaviourType.lostAdvantage:
      return values.lostAdvantage;
    case GuardianBehaviourType.gaveAdvantage:
      return values.gaveAdvantage;
    case GuardianBehaviourType.failedSwiftTrade:
    case GuardianBehaviourType.failedTrade:
      return values.failedTrade;
    default:
      throw new Error(`Unknown behaviour: ${behaviour}`);
  }
}
