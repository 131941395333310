import { z } from 'zod';
import { ZMatch, ZMatchPlayer } from '../entities';

export const ZEndMatchArgs = ZMatch.pick({
  id: true,
  gameMode: true,
  agent: true,
  map: true,
  startingTeam: true,
  playerRank: true,
  isSurrendered: true,
  gameTag: true,
  tagline: true,
  bestWeapon: true,
  analysisTitle: true,
}).extend({
  roundsWon: z.number(),
  roundsLost: z.number(),
  kills: z.number(),
  deaths: z.number(),
  assists: z.number(),
  guardianScore: z.number(),
  otherMatchPlayers: ZMatchPlayer.array(),
});

export type IEndMatchArgs = z.infer<typeof ZEndMatchArgs>;

export const ZEndMatchRequest = z.object({
  body: ZEndMatchArgs,
});
