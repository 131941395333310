import { z } from 'zod';

export enum GameMode {
  ranked = 'ranked',
  unrated = 'unrated',
  spikeRush = 'spikeRush',
  deathmatch = 'deathmatch',
  escalation = 'escalation',
  custom = 'customBomb',
  teamDeathmatch = 'teamDeathmatch',
  unknown = 'unknown',
  swiftPlay = 'swiftPlay',
  range = 'range',
}

export const ZGameMode = z.nativeEnum(GameMode);
