import { z } from 'zod';
import { WidgetType, ZTrend } from '../../../enums/widget';
import { GuardianBehaviourType } from '../../../enums';
import { ValorantAgent, ValorantMap } from '../../../types';
import { ZAgentInsights } from '../../insights';
import { ZBehaviourPercentile, ZBehaviourPercentileValue } from '../../behaviour';

export const ZUserWidgetBase = z.object({
  userId: z.string(),
  like: z.boolean(),
  type: z.nativeEnum(WidgetType),
});

export type IUserWidgetBase = z.infer<typeof ZUserWidgetBase>;

export const ZFirstEngagementUserUserWidgetData = z.object({
  type: z.literal(WidgetType.firstEngagement),
  firstKills: z.number(),
  firstDeaths: z.number(),
  firstAssists: z.number(),
});

export const ZFirstEngagementUserUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.firstEngagement),
  data: ZFirstEngagementUserUserWidgetData,
  settings: z.null().optional(),
});

export const ZTradedToDeathsUserWidgetData = z.object({
  type: z.literal(WidgetType.tradedToDeathRatio),
  traded: z.number(),
  swiftyTraded: z.number(),
  totalTrades: z.number(),
  deaths: z.number(),
  ratio: z.number(),
});

export const ZTradedToDeathsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.tradedToDeathRatio),
  data: ZTradedToDeathsUserWidgetData,
  settings: z.null().optional(),
});

export const ZClosedTradeToKillsUserWidgetData = z.object({
  type: z.literal(WidgetType.closedTradeToKillsRatio),
  closedTrades: z.number(),
  swiftlyClosedTrades: z.number(),
  totalClosedTrades: z.number(),
  kills: z.number(),
  ratio: z.number(),
});

export const ZClosedTradeToKillsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.closedTradeToKillsRatio),
  data: ZClosedTradeToKillsUserWidgetData,
  settings: z.null().optional(),
});

export const ZScoreToRoundOutcomeUserWidgetData = z.object({
  type: z.literal(WidgetType.scoreToRoundOutcome),
  roundsWon: z
    .object({
      round: z.number(),
      score: z.number(),
      matchId: z.string(),
    })
    .array(),
  roundsLost: z
    .object({
      round: z.number(),
      score: z.number(),
      matchId: z.string(),
    })
    .array(),
});

export const ZScoreToRoundOutcomeUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.scoreToRoundOutcome),
  data: ZScoreToRoundOutcomeUserWidgetData,
  settings: z.null().optional(),
});

export const ZNotTradedAfterFirstDeathsCausesRoundLossUserWidgetData = z.object({
  type: z.literal(WidgetType.notTradedAfterFirstDeathsCausesRoundLoss),
  roundsLost: z.number(),
  roundsWon: z.number(),
  ratio: z.number(),
});

export const ZNotTradedAfterFirstDeathsCausesRoundLossUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.notTradedAfterFirstDeathsCausesRoundLoss),
  data: ZNotTradedAfterFirstDeathsCausesRoundLossUserWidgetData,
  settings: z.null().optional(),
});

export const ZFirstKillDeathRatioUserWidgetData = z.object({
  type: z.literal(WidgetType.firstKillDeathRatio),
  firstKills: z.number(),
  firstDeaths: z.number(),
  ratio: z.number(),
});

export const ZFirstKillDeathRatioUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.firstKillDeathRatio),
  data: ZFirstKillDeathRatioUserWidgetData,
  settings: z.null().optional(),
});

export const ZFirstDeathRoundLostRatioWidgetData = z.object({
  type: z.literal(WidgetType.firstDeathRoundLostRatio),
  firstDeaths: z.number(),
  firstDeathsRoundLost: z.number(),
  ratio: z.number(),
});

export const ZFirstDeathRoundLostRatioUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.firstDeathRoundLostRatio),
  data: ZFirstDeathRoundLostRatioWidgetData,
  settings: z.null().optional(),
});

export const ZFirstKillRoundWonRatioWidgetData = z.object({
  type: z.literal(WidgetType.firstKillRoundWonRatio),
  firstKills: z.number(),
  firstKillsRoundWon: z.number(),
  ratio: z.number(),
});

export const ZFirstKillRoundWonRatioUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.firstKillRoundWonRatio),
  data: ZFirstKillRoundWonRatioWidgetData,
  settings: z.null().optional(),
});

export const ZAverageGuardianScoreWidgetData = z.object({
  type: z.literal(WidgetType.averageGuardianScore),
  guardianScore: z.number(),
});

export const ZGuardianScoreWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.averageGuardianScore),
  data: ZAverageGuardianScoreWidgetData,
  settings: z.null().optional(),
});

export const ZGuardianScoreOverTimeWidgetData = z.object({
  type: z.literal(WidgetType.guardianScoreOverTime),
  rounds: z
    .object({
      guardianScoreDelta: z.number(),
      roundNumber: z.number(),
    })
    .array(),
});

export const ZGuardianScoreOverTimeWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.guardianScoreOverTime),
  data: ZGuardianScoreOverTimeWidgetData,
  settings: z.null().optional(),
});

export const ZAdvantageDynamicsUserWidgetData = z.object({
  type: z.literal(WidgetType.advantageDynamics),
  gainedAdvantages: z.number(),
  gainedAdvantagesRoundsWon: z.number(),
  gainedAdvantagesRoundsLost: z.number(),
  evenedAdvantages: z.number(),
  evenedAdvantagesRoundsWon: z.number(),
  evenedAdvantagesRoundsLost: z.number(),
  lostAdvantages: z.number(),
  lostAdvantagesRoundsWon: z.number(),
  lostAdvantagesRoundsLost: z.number(),
  gaveAdvantages: z.number(),
  gaveAdvantagesRoundsWon: z.number(),
  gaveAdvantagesRoundsLost: z.number(),
});

export const ZAdvantageDynamicsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.advantageDynamics),
  data: ZAdvantageDynamicsUserWidgetData,
  settings: z.null().optional(),
});

export const ZBehaviourItem = z.object({
  type: z.nativeEnum(GuardianBehaviourType),
  count: z.number(),
  roundsWon: z.number(),
  roundsLost: z.number(),
  ratio: z.number(),
  countTrend: ZTrend,
  roundsWonTrend: ZTrend,
  roundsLostTrend: ZTrend,
  ratioTrend: ZTrend,
});

export type BehaviourItem = z.infer<typeof ZBehaviourItem>;

export const ZTopBehavioursUserWidgetData = z.object({
  type: z.literal(WidgetType.topBehaviours),
  behaviours: ZBehaviourItem.array(),
});

export const ZTopBehavioursUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.topBehaviours),
  data: ZTopBehavioursUserWidgetData,
  settings: z.null().optional(),
});

export const ZFirstEngagementRoundsUserWidgetData = z.object({
  type: z.literal(WidgetType.firstEngagementRounds),
  firstKills: z.number(),
  firstKillsRoundWon: z.number(),
  firstKillsRoundLost: z.number(),
  firstDeaths: z.number(),
  firstDeathsRoundWon: z.number(),
  firstDeathsRoundLost: z.number(),
  firstAssists: z.number(),
  firstAssistsRoundWon: z.number(),
  firstAssistsRoundLost: z.number(),
});

export const ZFirstEngagementRoundsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.firstEngagementRounds),
  data: ZFirstEngagementRoundsUserWidgetData,
  settings: z.null().optional(),
});

export const ZAgentInfo = ZAgentInsights.extend({
  map: z.nativeEnum(ValorantMap).optional(),
});

export type IAgentInfo = z.infer<typeof ZAgentInfo>;

export const ZTopAgentsUserWidgetData = z.object({
  agents: ZAgentInfo.array(),
});

export const ZTopAgentsUserWidgetSettings = z.object({
  map: z.nativeEnum(ValorantMap).optional(),
  agentsToHide: z.nativeEnum(ValorantAgent).array(),
  isLatest: z.boolean(),
});

export const ZTopAgentsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.topAgents),
  data: ZTopAgentsUserWidgetData,
  settings: ZTopAgentsUserWidgetSettings.optional(),
});

export const ZLatestAgentsUserWidgetData = z.object({
  type: z.literal(WidgetType.latestAgents),
  agents: ZAgentInfo.array(),
});

export const ZLatestAgentsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.latestAgents),
  data: ZLatestAgentsUserWidgetData,
});

export const ZContributeUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.contribute),
  data: z.null().optional(),
});

export const ZMatchHistoryDescriptionUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.matchHistoryDescription),
  data: z.null().optional(),
});

export const ZPerformanceUserWidgetData = z.object({
  type: z.literal(WidgetType.performanceStrengths),
  behaviourPercentiles: ZBehaviourPercentileValue.array(),
});

export type IPerformanceUserWidgetData = z.infer<typeof ZPerformanceUserWidgetData>;

export const ZPerformanceStrengthsUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.performanceStrengths),
  data: ZPerformanceUserWidgetData,
});

export type IPerformanceStrengthsUserWidget = z.infer<typeof ZPerformanceStrengthsUserWidget>;

export const ZPerformanceWeaknessesUserWidget = ZUserWidgetBase.extend({
  type: z.literal(WidgetType.performanceWeaknesses),
  data: ZPerformanceUserWidgetData,
});

export type IPerformanceWeaknessesUserWidget = z.infer<typeof ZPerformanceStrengthsUserWidget>;

export const ZUserWidgetData = z.union([
  ZFirstEngagementUserUserWidgetData,
  ZTradedToDeathsUserWidgetData,
  ZClosedTradeToKillsUserWidgetData,
  ZScoreToRoundOutcomeUserWidgetData,
  ZFirstKillDeathRatioUserWidgetData,
  ZFirstDeathRoundLostRatioWidgetData,
  ZFirstKillRoundWonRatioWidgetData,
  ZAdvantageDynamicsUserWidgetData,
  ZAverageGuardianScoreWidgetData,
  ZTopBehavioursUserWidgetData,
  ZFirstEngagementRoundsUserWidgetData,
  ZNotTradedAfterFirstDeathsCausesRoundLossUserWidgetData,
  ZTopAgentsUserWidgetData,
  ZGuardianScoreOverTimeWidgetData,
  ZLatestAgentsUserWidgetData,
  ZPerformanceUserWidgetData,
]);

export type IFirstEngagementUserWidgetData = z.infer<typeof ZFirstEngagementUserUserWidgetData>;
export type ITradedToDeathUserWidgetData = z.infer<typeof ZTradedToDeathsUserWidgetData>;
export type IClosedTradeToKillsUserWidgetData = z.infer<typeof ZClosedTradeToKillsUserWidgetData>;
export type IScoreToRoundOutcomeUserWidgetData = z.infer<typeof ZScoreToRoundOutcomeUserWidgetData>;
export type IFirstKillDeathRatioUserWidgetData = z.infer<typeof ZFirstKillDeathRatioUserWidgetData>;
export type IFirstDeathRoundLostRatioWidgetData = z.infer<typeof ZFirstDeathRoundLostRatioWidgetData>;
export type IFirstKillRoundWonRatioWidgetData = z.infer<typeof ZFirstKillRoundWonRatioWidgetData>;
export type IAdvantageDynamicsUserWidgetData = z.infer<typeof ZAdvantageDynamicsUserWidgetData>;
export type IAverageGuardianScoreWidgetData = z.infer<typeof ZAverageGuardianScoreWidgetData>;
export type ITopBehavioursUserWidgetData = z.infer<typeof ZTopBehavioursUserWidgetData>;
export type IFirstEngagementRoundsUserWidgetData = z.infer<typeof ZFirstEngagementRoundsUserWidgetData>;
export type INotTradedAfterFirstDeathsCausesRoundLossUserWidgetData = z.infer<
  typeof ZNotTradedAfterFirstDeathsCausesRoundLossUserWidgetData
>;
export type ITopAgentsUserWidgetData = z.infer<typeof ZTopAgentsUserWidgetData>;
export type IGuardianScoreOverTimeWidgetData = z.infer<typeof ZGuardianScoreOverTimeWidgetData>;
export type ILatestAgentsUserWidgetData = z.infer<typeof ZLatestAgentsUserWidgetData>;
export type IUserWidgetData = z.infer<typeof ZUserWidgetData>;

export const ZUserWidget = z.union([
  ZFirstEngagementUserUserWidget,
  ZTradedToDeathsUserWidget,
  ZClosedTradeToKillsUserWidget,
  ZScoreToRoundOutcomeUserWidget,
  ZFirstKillDeathRatioUserWidget,
  ZFirstDeathRoundLostRatioUserWidget,
  ZFirstKillRoundWonRatioUserWidget,
  ZAdvantageDynamicsUserWidget,
  ZGuardianScoreWidget,
  ZTopBehavioursUserWidget,
  ZFirstEngagementRoundsUserWidget,
  ZNotTradedAfterFirstDeathsCausesRoundLossUserWidget,
  ZTopAgentsUserWidget,
  ZContributeUserWidget,
  ZMatchHistoryDescriptionUserWidget,
  ZGuardianScoreOverTimeWidget,
  ZLatestAgentsUserWidget,
  ZPerformanceStrengthsUserWidget,
  ZPerformanceWeaknessesUserWidget,
]);

export type IFirstEngagementUserWidget = z.infer<typeof ZFirstEngagementUserUserWidget>;
export type ITradedToDeathUserWidget = z.infer<typeof ZTradedToDeathsUserWidget>;
export type IClosedTradeToKillsUserWidget = z.infer<typeof ZClosedTradeToKillsUserWidget>;
export type IScoreToRoundOutcomeUserWidget = z.infer<typeof ZScoreToRoundOutcomeUserWidget>;
export type IFirstKillDeathRatioUserWidget = z.infer<typeof ZFirstKillDeathRatioUserWidget>;
export type IFirstDeathRoundLostRatioUserWidget = z.infer<typeof ZFirstDeathRoundLostRatioUserWidget>;
export type IFirstKillRoundWonRatioUserWidget = z.infer<typeof ZFirstKillRoundWonRatioUserWidget>;
export type IAdvantageDynamicsUserWidget = z.infer<typeof ZAdvantageDynamicsUserWidget>;
export type IAverageGuardianScoreWidget = z.infer<typeof ZGuardianScoreWidget>;
export type ITopBehavioursUserWidget = z.infer<typeof ZTopBehavioursUserWidget>;
export type IFirstEngagementRoundsUserWidget = z.infer<typeof ZFirstEngagementRoundsUserWidget>;
export type INotTradedAfterFirstDeathsCausesRoundLossUserWidget = z.infer<
  typeof ZNotTradedAfterFirstDeathsCausesRoundLossUserWidget
>;
export type ITopAgentsUserWidget = z.infer<typeof ZTopAgentsUserWidget>;
export type IGuardianScoreOverTimeWidget = z.infer<typeof ZGuardianScoreOverTimeWidget>;
export type ILatestAgentsUserWidget = z.infer<typeof ZLatestAgentsUserWidget>;
export type IUserWidget = z.infer<typeof ZUserWidget>;
