import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="#060820"
      fillRule="evenodd"
      d="M3.536 5.299a1 1 0 0 1 .893.275L6.9 8.045a.812.812 0 1 0 1.148-1.148l-2.47-2.47a1 1 0 0 1 .308-1.624 5.967 5.967 0 0 1 8.272 6.441l6.879 6.879a3.475 3.475 0 1 1-4.914 4.914l-6.879-6.879A5.967 5.967 0 0 1 2.805 5.88a1 1 0 0 1 .731-.581Zm.77 2.981a3.963 3.963 0 0 0 5 3.82 1 1 0 0 1 .968.26l7.263 7.262a1.475 1.475 0 0 0 2.085-2.085l-7.262-7.263a1 1 0 0 1-.26-.967 3.963 3.963 0 0 0-3.814-5l1.177 1.176A2.812 2.812 0 1 1 5.486 9.46l-1.18-1.18Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
