import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 26" {...props}>
    <path
      fill={props.fill || '#000'}
      d="M23.325 0h4.411L18.1 11.013 29.436 26H20.56l-6.952-9.09L5.653 26H1.24l10.307-11.78L.672 0h9.101l6.284 8.308L23.325 0Zm-1.548 23.36h2.444L8.445 2.501H5.823L21.777 23.36Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
