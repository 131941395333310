import { z } from 'zod';
import { ZAgentInsights } from './agent-insights';

export const ZCommunityInsights = z.object({
  controller: ZAgentInsights.optional(),
  initiator: ZAgentInsights.optional(),
  duelist: ZAgentInsights.optional(),
  sentinel: ZAgentInsights.optional(),
});

export type ICommunityInsights = z.infer<typeof ZCommunityInsights>;
