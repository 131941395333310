import { z } from 'zod';
import { UserOnboardingStatus } from '../../../enums';

export const ZSetOnboardingStatusArgs = z.object({
  status: z.nativeEnum(UserOnboardingStatus),
});

export type ISetOnboardingStatusArgs = z.infer<typeof ZSetOnboardingStatusArgs>;

export const ZSetOnboardingStatusRequest = z.object({
  body: ZSetOnboardingStatusArgs,
});
