export enum AppEnv {
  local = 'glocal',
  dev = 'dev',
  preprod = 'preprod',
  prod = 'production',
}

export enum AppStatus {
  live = 'live',
  underMaintenance = 'underMaintenance',
  unknown = 'unknown',
}

export enum TimeFrame {
  match = 'match',
  pastXMatches = 'pastXMatches',
  XMatchesAgo = 'XMatchesAgo',
  day = 'day',
  week = 'week',
}
