import { z } from 'zod';
import { ValorantAgent } from '../../../types';

export const ZAgentWinRate = z.object({
  agent: z.nativeEnum(ValorantAgent),
  winRate: z.number(),
  matchCount: z.number(),
  guardianScore: z.number(),
});

export type IAgentWinRate = z.infer<typeof ZAgentWinRate>;
