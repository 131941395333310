import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';

import { inject } from '@vercel/analytics';
import { SpeedInsights } from '@vercel/speed-insights/react';

inject();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
    <SpeedInsights />
  </React.StrictMode>,
);
