import { customAlphabet } from 'nanoid';

export const toSpacedPascal = (str: string): string => {
  return (
    str
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      .replace('_', ' ')
  );
};

export function numberWithCommas(x: number) {
  const xString = x.toString();

  if (xString.indexOf('.') !== -1) {
    const parts = xString.split('.');
    let numeralPart = parts[0];
    let decimalPart = parts[1];

    numeralPart = numeralPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    decimalPart = decimalPart.substring(0, 3);

    return `${numeralPart}.${decimalPart}`;
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const nanoid = customAlphabet(alphabet, 21);

export function gNanoId(size?: number) {
  return nanoid(size);
}
