import { z } from 'zod';
import { DashboardType, WidgetType } from '../../../enums';

export const ZEditUserDashboardItemArgs = z.object({
  gridColumn: z.number(),
  gridRow: z.number(),
  colSpan: z.number(),
  rowSpan: z.number(),
  dashboardType: z.nativeEnum(DashboardType),
  widgetType: z.nativeEnum(WidgetType),
});

export type IEditUserDashboardItemArgs = z.infer<typeof ZEditUserDashboardItemArgs>;

export const ZEditUserDashboardItemRequest = z.object({
  body: ZEditUserDashboardItemArgs,
});
