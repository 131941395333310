import { z } from 'zod';

export const ZFeatureTestingArgs = z.object({
  message: z.string(),
  extraData: z.any().optional(),
});

export type IFeatureTestingArgs = z.infer<typeof ZFeatureTestingArgs>;

export const ZFeatureTestingRequest = z.object({
  body: ZFeatureTestingArgs,
});
