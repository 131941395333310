import { ValorantAgent } from '@guardian/common';

export const agentToGradient: Record<ValorantAgent | 'Unknown', string> = {
  Unknown: `linear-gradient(
  45deg,
  hsl(213 83% 7%) 0%,
  hsl(341 32% 50%) 50%,
  hsl(209 100% 6%) 100%
)`,
  [ValorantAgent.Vyse]: `linear-gradient(
  45deg,
  hsl(249, 35%, 18%) 0%,
  hsl(271, 43%, 45%) 50%,
  hsl(316, 47%, 65%) 70%,
  hsl(45, 77%, 74%) 100%
)`,
  [ValorantAgent.Clove]: `linear-gradient(
  45deg,
  hsl(249 92% 35%) 0%,
  hsl(316 47% 65%) 70%,
  hsl(45 77% 74%) 100%
)`,
  [ValorantAgent.Iso]: `linear-gradient(
  45deg,
  hsl(246deg 30% 11%) 0%,
  hsl(250deg 25% 54%) 33%,
  hsl(219deg 9% 74%) 67%,
  hsl(271deg 36% 53%) 100%
)`,
  [ValorantAgent.Deadlock]: `linear-gradient(
  50deg,
  hsl(169deg 15% 14%) 0%,
  hsl(198deg 5% 20%) 16%,
  hsl(256deg 3% 25%) 23%,
  hsl(285deg 4% 28%) 29%,
  hsl(296deg 4% 31%) 34%,
  hsl(303deg 5% 34%) 39%,
  hsl(306deg 6% 37%) 43%,
  hsl(308deg 6% 39%) 48%,
  hsl(315deg 5% 45%) 52%,
  hsl(327deg 4% 52%) 57%,
  hsl(338deg 5% 59%) 61%,
  hsl(348deg 5% 65%) 66%,
  hsl(357deg 6% 70%) 71%,
  hsl(6deg 7% 75%) 77%,
  hsl(12deg 10% 80%) 84%,
  hsl(16deg 14% 84%) 100%
)`,
  [ValorantAgent.Gekko]: `linear-gradient(
  50deg,
  hsl(42deg 20% 13%) 0%,
  hsl(39deg 21% 13%) 16%,
  hsl(36deg 22% 13%) 23%,
  hsl(34deg 23% 14%) 29%,
  hsl(33deg 24% 14%) 34%,
  hsl(31deg 25% 14%) 39%,
  hsl(30deg 26% 15%) 43%,
  hsl(29deg 27% 15%) 48%,
  hsl(55deg 29% 15%) 52%,
  hsl(81deg 42% 17%) 57%,
  hsl(89deg 52% 19%) 61%,
  hsl(93deg 60% 20%) 66%,
  hsl(95deg 67% 22%) 71%,
  hsl(97deg 74% 23%) 77%,
  hsl(97deg 82% 23%) 84%,
  hsl(96deg 93% 23%) 100%
)`,
  [ValorantAgent.Astra]: `linear-gradient(
                                50deg,
                                hsl(287deg 18% 15%) 0%,
                                hsl(301deg 24% 24%) 16%,
                                hsl(303deg 27% 31%) 23%,
                                hsl(304deg 28% 36%) 29%,
                                hsl(305deg 28% 41%) 34%,
                                hsl(305deg 29% 45%) 39%,
                                hsl(305deg 29% 49%) 43%,
                                hsl(305deg 32% 53%) 48%,
                                hsl(301deg 34% 53%) 52%,
                                hsl(293deg 38% 53%) 57%,
                                hsl(286deg 43% 53%) 61%,
                                hsl(281deg 48% 53%) 66%,
                                hsl(278deg 53% 52%) 71%,
                                hsl(274deg 58% 52%) 77%,
                                hsl(272deg 63% 51%) 84%,
                                hsl(270deg 71% 49%) 100%
                              )`,
  [ValorantAgent.Breach]: `linear-gradient(
  50deg,
  hsl(0deg 7% 14%) 0%,
  hsl(7deg 5% 17%) 16%,
  hsl(14deg 4% 20%) 23%,
  hsl(20deg 3% 23%) 29%,
  hsl(25deg 3% 25%) 34%,
  hsl(30deg 2% 27%) 39%,
  hsl(34deg 2% 29%) 43%,
  hsl(38deg 2% 31%) 48%,
  hsl(21deg 6% 33%) 52%,
  hsl(16deg 13% 34%) 57%,
  hsl(16deg 19% 35%) 61%,
  hsl(16deg 25% 35%) 66%,
  hsl(16deg 31% 36%) 71%,
  hsl(17deg 36% 36%) 77%,
  hsl(17deg 42% 37%) 84%,
  hsl(18deg 48% 37%) 100%
)`,
  [ValorantAgent.Brimstone]: `linear-gradient(
  50deg,
  hsl(324deg 8% 13%) 0%,
  hsl(342deg 9% 17%) 16%,
  hsl(348deg 9% 20%) 23%,
  hsl(351deg 10% 23%) 29%,
  hsl(353deg 10% 25%) 34%,
  hsl(355deg 10% 28%) 39%,
  hsl(356deg 10% 30%) 43%,
  hsl(356deg 10% 32%) 48%,
  hsl(360deg 13% 33%) 52%,
  hsl(4deg 18% 33%) 57%,
  hsl(6deg 24% 33%) 61%,
  hsl(8deg 31% 33%) 66%,
  hsl(10deg 38% 33%) 71%,
  hsl(11deg 45% 32%) 77%,
  hsl(13deg 54% 31%) 84%,
  hsl(15deg 67% 30%) 100%
)`,
  [ValorantAgent.Chamber]: `linear-gradient(
  50deg,
  hsl(320deg 3% 23%) 0%,
  hsl(21deg 13% 28%) 16%,
  hsl(25deg 20% 33%) 23%,
  hsl(26deg 23% 37%) 29%,
  hsl(27deg 26% 40%) 34%,
  hsl(28deg 28% 43%) 39%,
  hsl(28deg 29% 46%) 43%,
  hsl(29deg 30% 49%) 48%,
  hsl(30deg 35% 51%) 52%,
  hsl(31deg 44% 52%) 57%,
  hsl(32deg 53% 53%) 61%,
  hsl(33deg 61% 54%) 66%,
  hsl(34deg 70% 55%) 71%,
  hsl(35deg 79% 55%) 77%,
  hsl(36deg 87% 55%) 84%,
  hsl(37deg 96% 55%) 100%
)`,
  [ValorantAgent.Cypher]: `linear-gradient(
  50deg,
  hsl(345deg 6% 12%) 0%,
  hsl(306deg 3% 16%) 16%,
  hsl(278deg 4% 20%) 23%,
  hsl(266deg 4% 23%) 29%,
  hsl(259deg 4% 26%) 34%,
  hsl(255deg 4% 28%) 39%,
  hsl(252deg 4% 30%) 43%,
  hsl(249deg 4% 33%) 48%,
  hsl(248deg 3% 37%) 52%,
  hsl(247deg 2% 43%) 57%,
  hsl(246deg 2% 48%) 61%,
  hsl(246deg 1% 53%) 66%,
  hsl(244deg 1% 58%) 71%,
  hsl(243deg 1% 62%) 77%,
  hsl(242deg 1% 65%) 84%,
  hsl(240deg 1% 69%) 100%
)`,
  [ValorantAgent.Fade]: `linear-gradient(
  50deg,
  hsl(236deg 19% 15%) 0%,
  hsl(220deg 23% 25%) 16%,
  hsl(218deg 24% 32%) 23%,
  hsl(217deg 24% 38%) 29%,
  hsl(216deg 24% 43%) 34%,
  hsl(216deg 24% 47%) 39%,
  hsl(216deg 26% 51%) 43%,
  hsl(216deg 30% 55%) 48%,
  hsl(216deg 30% 55%) 52%,
  hsl(216deg 27% 52%) 57%,
  hsl(216deg 25% 49%) 61%,
  hsl(217deg 26% 46%) 66%,
  hsl(217deg 26% 42%) 71%,
  hsl(218deg 27% 39%) 77%,
  hsl(219deg 28% 34%) 84%,
  hsl(221deg 30% 29%) 100%
)`,
  [ValorantAgent.Jett]: `linear-gradient(
  50deg,
  hsl(233deg 26% 13%) 0%,
  hsl(226deg 26% 17%) 16%,
  hsl(223deg 26% 20%) 23%,
  hsl(222deg 26% 23%) 29%,
  hsl(220deg 26% 25%) 34%,
  hsl(220deg 26% 27%) 39%,
  hsl(219deg 26% 29%) 43%,
  hsl(219deg 26% 31%) 48%,
  hsl(222deg 19% 35%) 52%,
  hsl(229deg 11% 40%) 57%,
  hsl(240deg 7% 45%) 61%,
  hsl(257deg 5% 48%) 66%,
  hsl(280deg 4% 51%) 71%,
  hsl(312deg 4% 54%) 77%,
  hsl(333deg 5% 58%) 84%,
  hsl(346deg 7% 61%) 100%
)`,
  [ValorantAgent.KAYO]: `linear-gradient(
  50deg,
  hsl(280deg 6% 10%) 0%,
  hsl(238deg 4% 17%) 16%,
  hsl(226deg 5% 21%) 23%,
  hsl(222deg 5% 25%) 29%,
  hsl(219deg 5% 28%) 34%,
  hsl(218deg 5% 31%) 39%,
  hsl(217deg 5% 34%) 43%,
  hsl(216deg 5% 36%) 48%,
  hsl(228deg 10% 39%) 52%,
  hsl(234deg 17% 43%) 57%,
  hsl(237deg 22% 46%) 61%,
  hsl(238deg 26% 49%) 66%,
  hsl(238deg 31% 52%) 71%,
  hsl(239deg 37% 54%) 77%,
  hsl(239deg 44% 57%) 84%,
  hsl(239deg 51% 59%) 100%
)`,
  [ValorantAgent.Killjoy]: `linear-gradient(
  45deg,
  hsl(305, 41%, 51%) 0%,
  hsl(137, 24%, 41%) 50%,
  hsl(50, 88%, 57%) 100%
)`,
  [ValorantAgent.Neon]: `linear-gradient(
  50deg,
  hsl(236deg 14% 20%) 0%,
  hsl(230deg 24% 26%) 16%,
  hsl(228deg 28% 31%) 23%,
  hsl(228deg 30% 36%) 29%,
  hsl(227deg 32% 40%) 34%,
  hsl(227deg 33% 43%) 39%,
  hsl(227deg 33% 47%) 43%,
  hsl(227deg 34% 50%) 48%,
  hsl(227deg 31% 53%) 52%,
  hsl(226deg 22% 55%) 57%,
  hsl(223deg 13% 57%) 61%,
  hsl(193deg 3% 59%) 66%,
  hsl(70deg 8% 60%) 71%,
  hsl(61deg 17% 61%) 77%,
  hsl(58deg 26% 61%) 84%,
  hsl(57deg 36% 62%) 100%
)`,
  [ValorantAgent.Omen]: `linear-gradient(
  50deg,
  hsl(228deg 10% 10%) 0%,
  hsl(212deg 12% 15%) 16%,
  hsl(209deg 13% 20%) 23%,
  hsl(208deg 13% 23%) 29%,
  hsl(207deg 13% 26%) 34%,
  hsl(207deg 13% 28%) 39%,
  hsl(206deg 13% 31%) 43%,
  hsl(206deg 13% 33%) 48%,
  hsl(209deg 14% 34%) 52%,
  hsl(214deg 14% 33%) 57%,
  hsl(219deg 15% 32%) 61%,
  hsl(225deg 16% 31%) 66%,
  hsl(231deg 17% 30%) 71%,
  hsl(237deg 17% 30%) 77%,
  hsl(243deg 20% 28%) 84%,
  hsl(249deg 24% 27%) 100%
)`,
  [ValorantAgent.Phoenix]: `linear-gradient(
  50deg,
  hsl(228deg 10% 10%) 0%,
  hsl(241deg 2% 26%) 16%,
  hsl(247deg 2% 36%) 23%,
  hsl(250deg 1% 43%) 29%,
  hsl(252deg 1% 49%) 34%,
  hsl(253deg 2% 55%) 39%,
  hsl(254deg 2% 60%) 43%,
  hsl(255deg 2% 65%) 48%,
  hsl(352deg 4% 67%) 52%,
  hsl(13deg 15% 67%) 57%,
  hsl(17deg 26% 66%) 61%,
  hsl(19deg 37% 66%) 66%,
  hsl(21deg 47% 65%) 71%,
  hsl(22deg 57% 63%) 77%,
  hsl(24deg 66% 61%) 84%,
  hsl(26deg 75% 59%) 100%
)`,
  [ValorantAgent.Raze]: `linear-gradient(
  50deg,
  hsl(194deg 17% 15%) 0%,
  hsl(31deg 4% 21%) 16%,
  hsl(24deg 11% 25%) 23%,
  hsl(23deg 15% 28%) 29%,
  hsl(23deg 18% 31%) 34%,
  hsl(23deg 20% 34%) 39%,
  hsl(23deg 21% 37%) 43%,
  hsl(23deg 22% 39%) 48%,
  hsl(21deg 25% 41%) 52%,
  hsl(18deg 31% 42%) 57%,
  hsl(16deg 36% 43%) 61%,
  hsl(14deg 41% 44%) 66%,
  hsl(14deg 46% 45%) 71%,
  hsl(13deg 50% 46%) 77%,
  hsl(13deg 55% 46%) 84%,
  hsl(13deg 60% 46%) 100%
)`,
  [ValorantAgent.Reyna]: `linear-gradient(
  50deg,
  hsl(280deg 13% 13%) 0%,
  hsl(300deg 25% 17%) 16%,
  hsl(303deg 31% 21%) 23%,
  hsl(304deg 34% 24%) 29%,
  hsl(304deg 36% 27%) 34%,
  hsl(305deg 37% 29%) 39%,
  hsl(305deg 38% 32%) 43%,
  hsl(305deg 39% 34%) 48%,
  hsl(304deg 39% 37%) 52%,
  hsl(301deg 38% 42%) 57%,
  hsl(300deg 38% 46%) 61%,
  hsl(299deg 38% 50%) 66%,
  hsl(298deg 44% 54%) 71%,
  hsl(297deg 51% 57%) 77%,
  hsl(297deg 59% 61%) 84%,
  hsl(297deg 68% 64%) 100%
)`,
  [ValorantAgent.Sage]: `linear-gradient(
  50deg,
  hsl(284deg 44% 19%) 0%,
  hsl(280deg 28% 23%) 16%,
  hsl(276deg 19% 25%) 23%,
  hsl(271deg 14% 28%) 29%,
  hsl(264deg 10% 30%) 34%,
  hsl(256deg 8% 32%) 39%,
  hsl(244deg 6% 34%) 43%,
  hsl(228deg 5% 36%) 48%,
  hsl(231deg 3% 41%) 52%,
  hsl(255deg 3% 50%) 57%,
  hsl(272deg 3% 57%) 61%,
  hsl(283deg 4% 63%) 66%,
  hsl(291deg 5% 68%) 71%,
  hsl(298deg 6% 74%) 77%,
  hsl(302deg 8% 78%) 84%,
  hsl(306deg 12% 83%) 100%
)`,
  [ValorantAgent.Skye]: `linear-gradient(
  50deg,
  hsl(16deg 18% 12%) 0%,
  hsl(27deg 14% 15%) 16%,
  hsl(35deg 13% 17%) 23%,
  hsl(40deg 11% 19%) 29%,
  hsl(45deg 11% 20%) 34%,
  hsl(48deg 10% 22%) 39%,
  hsl(51deg 10% 23%) 43%,
  hsl(54deg 9% 25%) 48%,
  hsl(75deg 10% 27%) 52%,
  hsl(96deg 12% 31%) 57%,
  hsl(107deg 13% 34%) 61%,
  hsl(114deg 14% 37%) 66%,
  hsl(118deg 15% 40%) 71%,
  hsl(122deg 16% 43%) 77%,
  hsl(124deg 17% 45%) 84%,
  hsl(126deg 18% 47%) 100%
)`,
  [ValorantAgent.Sova]: `linear-gradient(
  50deg,
  hsl(260deg 12% 10%) 0%,
  hsl(262deg 9% 12%) 16%,
  hsl(263deg 8% 15%) 23%,
  hsl(264deg 7% 16%) 29%,
  hsl(264deg 7% 18%) 34%,
  hsl(265deg 6% 20%) 39%,
  hsl(265deg 6% 21%) 43%,
  hsl(266deg 6% 22%) 48%,
  hsl(249deg 10% 24%) 52%,
  hsl(237deg 17% 25%) 57%,
  hsl(233deg 24% 26%) 61%,
  hsl(231deg 30% 27%) 66%,
  hsl(229deg 37% 28%) 71%,
  hsl(227deg 43% 28%) 77%,
  hsl(226deg 48% 29%) 84%,
  hsl(224deg 54% 29%) 100%
)`,
  [ValorantAgent.Viper]: `linear-gradient(
  50deg,
  hsl(276deg 9% 11%) 0%,
  hsl(250deg 11% 18%) 16%,
  hsl(245deg 11% 23%) 23%,
  hsl(243deg 12% 27%) 29%,
  hsl(242deg 12% 31%) 34%,
  hsl(241deg 12% 34%) 39%,
  hsl(241deg 12% 37%) 43%,
  hsl(240deg 12% 40%) 48%,
  hsl(215deg 11% 41%) 52%,
  hsl(159deg 12% 42%) 57%,
  hsl(136deg 17% 45%) 61%,
  hsl(125deg 21% 47%) 66%,
  hsl(118deg 26% 50%) 71%,
  hsl(113deg 33% 51%) 77%,
  hsl(110deg 40% 51%) 84%,
  hsl(107deg 48% 52%) 100%
)`,
  [ValorantAgent.Yoru]: `linear-gradient(
  50deg,
  hsl(250deg 8% 15%) 0%,
  hsl(235deg 22% 21%) 16%,
  hsl(233deg 29% 25%) 23%,
  hsl(232deg 33% 28%) 29%,
  hsl(232deg 35% 31%) 34%,
  hsl(232deg 37% 34%) 39%,
  hsl(231deg 38% 36%) 43%,
  hsl(231deg 39% 39%) 48%,
  hsl(232deg 32% 42%) 52%,
  hsl(235deg 22% 46%) 57%,
  hsl(238deg 16% 50%) 61%,
  hsl(242deg 12% 53%) 66%,
  hsl(248deg 9% 55%) 71%,
  hsl(259deg 6% 57%) 77%,
  hsl(294deg 3% 60%) 84%,
  hsl(0deg 4% 63%) 100%
)`,
  [ValorantAgent.Harbor]: `linear-gradient(
  50deg,
  hsl(164deg 34% 15%) 0%,
  hsl(187deg 20% 21%) 16%,
  hsl(201deg 18% 26%) 23%,
  hsl(209deg 17% 31%) 29%,
  hsl(214deg 17% 34%) 34%,
  hsl(218deg 17% 38%) 39%,
  hsl(221deg 16% 41%) 43%,
  hsl(223deg 16% 44%) 48%,
  hsl(219deg 19% 48%) 52%,
  hsl(214deg 26% 53%) 57%,
  hsl(212deg 34% 57%) 61%,
  hsl(210deg 44% 61%) 66%,
  hsl(209deg 55% 65%) 71%,
  hsl(208deg 68% 69%) 77%,
  hsl(207deg 82% 72%) 84%,
  hsl(207deg 100% 75%) 100%
)`,
};
