import * as React from 'react';
import { SVGProps, memo } from 'react';
import { colorPalette } from '../../style';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 122.88 122.88"
    width="1em"
    height="1em"
    {...props}
    fill={colorPalette.white}
  >
    <path d="M61.44 0 37.02 25.4h15.72v27.34H25.4V37.02L0 61.44l25.4 24.42V70.15h27.34v27.33H37.02l24.42 25.4 24.42-25.4H70.15V70.15h27.33v15.71l25.4-24.42-25.4-24.42v15.72H70.15V25.4h15.71L61.44 0z" />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
