import { z } from 'zod';

export const ZTrackUninstallArgs = z.object({
  overwolfUserResult: z.object({
    machineId: z.string().optional(),
    userId: z.string(),
    username: z.string().optional().nullable(),
    parameters: z.any().optional(),
    installParams: z.any().optional(),
  }),
  userId: z.string().optional(),
  userName: z.string().optional(),
});

export const ZTrackUninstallRequest = z.object({
  body: ZTrackUninstallArgs,
});

export type ITrackUninstallArgs = z.infer<typeof ZTrackUninstallArgs>;
