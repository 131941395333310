import {
  PlayerFullName,
  ValorantAgent,
  Team,
  IRoundReport,
  IKillFeed,
  IScore,
  IScoreboard,
  IMatchInfo,
  IRosterPlayer,
  IValorantScene,
  GameMode,
  GuardianBehaviourType,
  ZPlayerNameSchema,
} from '@guardian/common';
import { z } from 'zod';

export enum GuardianEvents {
  playerInfoChanged = 'playerInfoChanged',
  kill = 'kill',
  assist = 'assist',
  headshot = 'headshot',
  death = 'death',
  roundReport = 'roundReport',
  roundEnd = 'roundEnd',
  roundStart = 'roundStart',
  roundBuyPhase = 'roundBuyPhase',
  matchFound = 'matchFound',
  matchStart = 'matchStart',
  matchEnd = 'matchEnd',
  killFeed = 'killFeed',
  scoreChanged = 'scoreChanged',
  scoreboardUpdate = 'scoreboardUpdate',
  mapChange = 'mapChange',
  rosterUpdate = 'rosterUpdate',
  spikeDefused = 'spikeDefused',
  spikePlanted = 'spikePlanted',
  spikeDetonated = 'spikeDetonated',
  gameModeChanged = 'gameModeChanged',
  sceneChanged = 'sceneChanged',
  gameEventStatusChanged = 'gameEventStatusChanged',
  shopChanged = 'shopChanged',
}

export type SpikeEvents = GuardianEvents.spikePlanted | GuardianEvents.spikeDefused | GuardianEvents.spikeDetonated;

export const SpikeEventToGuardianBehaviourType: Record<
  SpikeEvents,
  GuardianBehaviourType.spikeDetonated | GuardianBehaviourType.spikePlanted | GuardianBehaviourType.spikeDefused
> = {
  [GuardianEvents.spikePlanted]: GuardianBehaviourType.spikePlanted,
  [GuardianEvents.spikeDefused]: GuardianBehaviourType.spikeDefused,
  [GuardianEvents.spikeDetonated]: GuardianBehaviourType.spikeDetonated,
};

export type GuardianEventBase = {
  timestamp: number;
  id: string;
};

export const ZPlayerInfo = z.object({
  playerName: ZPlayerNameSchema.optional(),
  playerId: z.string().optional(),
  agent: z.nativeEnum(ValorantAgent).nullable().optional(),
  team: z.nativeEnum(Team).optional(),
});

export type IPlayerInfo = z.infer<typeof ZPlayerInfo>;

export type IPlayerInfoChangeGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.playerInfoChanged;
  data: IPlayerInfo;
};

export type IKillGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.kill;
  data: number;
};

export type IAssistGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.assist;
  data: number;
};

export type IHeadshotGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.headshot;
  data: number;
};

export type IDeathGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.death;
  data: number;
};

export type RoundReportGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.roundReport;
  data: IRoundReport;
};

export type RoundEndGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.roundEnd;
  data?: null;
};

export type RoundStartGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.roundStart;
  data?: null;
};

export type RoundBuyPhaseGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.roundBuyPhase;
  data?: null;
};

export type MatchFoundGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.matchFound;
  data?: null;
};

export type MatchStartGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.matchStart;
  data?: null;
};

export type MatchEndGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.matchEnd;
  data?: null;
};

export type KillFeedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.killFeed;
  data: IKillFeed;
};

export type ScoreGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.scoreChanged;
  data: IScore;
};

export type IScoreboardEventData = Omit<IScoreboard, 'is_local'> & { isLocal: boolean };

export type ScoreboardUpdateGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.scoreboardUpdate;
  data: IScoreboardEventData;
};

export type MapChangedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.mapChange;
  data: IMatchInfo['map'];
};

export type RosterUpdateGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.rosterUpdate;
  data: Omit<IRosterPlayer, 'local'> & { isLocal: boolean };
};

export type SpikePlantedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.spikePlanted;
  data?: null;
};

export type SpikeDefusedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.spikeDefused;
  data?: null;
};

export type SpikeDetonatedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.spikeDetonated;
  data?: null;
};

export type GameModeChangedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.gameModeChanged;
  data: GameMode;
};

export type SceneChangedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.sceneChanged;
  data: IValorantScene;
};

export type GameEventStatusChangedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.gameEventStatusChanged;
  data: GameEventStatusReport;
};

export type IShopChangedGuardianEvent = GuardianEventBase & {
  event: GuardianEvents.shopChanged;
  data: {
    isShopOpen: boolean;
  };
};

export type GuardianEvent =
  | IPlayerInfoChangeGuardianEvent
  | IKillGuardianEvent
  | IAssistGuardianEvent
  | IHeadshotGuardianEvent
  | IDeathGuardianEvent
  | RoundEndGuardianEvent
  | RoundStartGuardianEvent
  | MatchFoundGuardianEvent
  | MatchEndGuardianEvent
  | KillFeedGuardianEvent
  | ScoreGuardianEvent
  | ScoreboardUpdateGuardianEvent
  | MapChangedGuardianEvent
  | RosterUpdateGuardianEvent
  | MatchStartGuardianEvent
  | SpikePlantedGuardianEvent
  | SpikeDefusedGuardianEvent
  | SpikeDetonatedGuardianEvent
  | RoundBuyPhaseGuardianEvent
  | RoundReportGuardianEvent
  | GameModeChangedGuardianEvent
  | SceneChangedGuardianEvent
  | GameEventStatusChangedGuardianEvent
  | IShopChangedGuardianEvent;
