import {
  GameEventStatus,
  GameMode,
  MatchOutcomes,
  Team,
  ValorantAgent,
  ValorantMap,
  ZGuardianBehaviour,
  ZMatchPlayer,
  ZMatchRound,
  ZPlayerNameSchema,
} from '@guardian/common';
import { z } from 'zod';
import { ZGuidelineInfo } from './guideline';
import { ZEconomyMap, ZMatchState, ZMatchStatePlayer } from './match-state';
import { ZGameSessionRecommendation } from '../services/game-session';
import { GuardianEvent } from './guardian-events';

export interface IGuardianChannel {
  type: GuardianChannelType;
  subscribe(callback: (message: IGuardianChannelMessage) => void): void;
}

export enum GuardianChannelType {
  recommendations = 'recommendations',
  hotkeys = 'hotkeys',
  match = 'match',
  overwolf = 'overwolf',
  player = 'player',
  events = 'events',
}

// Match Channel
export enum GuardianMatchChannelMessageTypes {
  matchFound = 'matchFound',
  matchStart = 'matchStart',
  matchEnd = 'matchEnd',
  matchSaved = 'matchSaved',
  roundStart = 'roundStart',
  roundEnd = 'roundEnd',
  agentChange = 'agentChange',
  mapChange = 'mapChange',
  shopToggle = 'shopToggle',
  roundEconomyReady = 'roundEconomyReady',
}

export const ZRoundEconomyReadyGuardianChannelMessageArgs = z.object({
  roundNumber: z.number(),
  allyEconomy: ZEconomyMap,
  enemyEconomy: ZEconomyMap,
});

export type IRoundEconomyReadyGuardianChannelMessageArgs = z.infer<typeof ZRoundEconomyReadyGuardianChannelMessageArgs>;

export const ZRoundEconomyReadyGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.roundEconomyReady),
  data: ZRoundEconomyReadyGuardianChannelMessageArgs,
});

export const ZMatchStartGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.matchStart),
  data: z.object({
    gameMode: z.nativeEnum(GameMode),
  }),
});

export const ZMatchEndChannelMessageArgs = ZMatchState.omit({
  tactical: true,
  previousMyTeamStatus: true,
  previousEnemyTeamStatus: true,
  myTeamStatus: true,
  enemyTeamStatus: true,
}).extend({
  allBehaviours: ZGuardianBehaviour.array(),
});

export type IMatchEndChannelMessageArgs = z.infer<typeof ZMatchEndChannelMessageArgs>;

export const ZMatchEndGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.matchEnd),
  data: ZMatchEndChannelMessageArgs,
});

export const ZMatchSavedGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.matchSaved),
  data: z.object({
    matchId: z.string(),
    gameMode: z.nativeEnum(GameMode),
  }),
});

export const ZRoundStartGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.roundStart),
  data: z.object({
    round: z.number(),
  }),
});

export const ZRoundEndGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.roundEnd),
  data: z.object({
    round: ZMatchRound,
  }),
});

export const ZAgentChangeGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.agentChange),
  data: z.object({
    agent: z.nativeEnum(ValorantAgent),
  }),
});

export const ZMapChangeGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.mapChange),
  data: z.object({
    map: z.nativeEnum(ValorantMap),
  }),
});

export const ZShopToggleGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.shopToggle),
  data: z.object({
    open: z.boolean(),
  }),
});

export const ZMatchFoundGuardianChannelMessage = z.object({
  type: z.literal(GuardianMatchChannelMessageTypes.matchFound),
  data: z.null().optional(),
});

export const ZMatchGuardianChannelMessage = z.union([
  ZMatchStartGuardianChannelMessage,
  ZMatchEndGuardianChannelMessage,
  ZMatchSavedGuardianChannelMessage,
  ZRoundStartGuardianChannelMessage,
  ZRoundEndGuardianChannelMessage,
  ZAgentChangeGuardianChannelMessage,
  ZMapChangeGuardianChannelMessage,
  ZShopToggleGuardianChannelMessage,
  ZMatchFoundGuardianChannelMessage,
  ZRoundEconomyReadyGuardianChannelMessage,
]);

export type IMatchStartGuardianChannelMessage = z.infer<typeof ZMatchStartGuardianChannelMessage>;
export type IMatchEndGuardianChannelMessage = z.infer<typeof ZMatchEndGuardianChannelMessage>;
export type IMatchSavedGuardianChannelMessage = z.infer<typeof ZMatchSavedGuardianChannelMessage>;
export type IRoundStartGuardianChannelMessage = z.infer<typeof ZRoundStartGuardianChannelMessage>;
export type IRoundEndGuardianChannelMessage = z.infer<typeof ZRoundEndGuardianChannelMessage>;
export type IAgentChangeGuardianChannelMessage = z.infer<typeof ZAgentChangeGuardianChannelMessage>;
export type IMapChangeGuardianChannelMessage = z.infer<typeof ZMapChangeGuardianChannelMessage>;
export type IShopToggleGuardianChannelMessage = z.infer<typeof ZShopToggleGuardianChannelMessage>;

export type IGuardianMatchChannelMessage = z.infer<typeof ZMatchGuardianChannelMessage>;

// Hotkeys channel
export enum GuardianHotkeysChannelMessageTypes {
  triggered = 'triggered',
}

export const ZTriggeredHotkeysGuardianChannelMessage = z.object({
  type: z.literal(GuardianHotkeysChannelMessageTypes.triggered),
  data: z.object({
    hotkey: z.string(),
  }),
});

export type ITriggeredHotkeysGuardianChannelMessage = z.infer<typeof ZTriggeredHotkeysGuardianChannelMessage>;

export const ZHotkeysGuardianChannelMessage = ZTriggeredHotkeysGuardianChannelMessage;

export type IHotkeysGuardianChannelMessage = ITriggeredHotkeysGuardianChannelMessage;

// Recommendation channel

export enum GuardianRecommendationChannelMessageTypes {
  newRecommendation = 'newRecommendation',
  dismissRecommendation = 'dismissRecommendation',
  newGuideline = 'newGuideline',
}

export const ZNewRecommendationGuardianChannelMessage = z.object({
  type: z.literal(GuardianRecommendationChannelMessageTypes.newRecommendation),
  data: z.object({
    recommendation: ZGameSessionRecommendation,
  }),
});

export type INewRecommendationGuardianChannelMessage = z.infer<typeof ZNewRecommendationGuardianChannelMessage>;

export const ZDismissRecommendationGuardianChannelMessage = z.object({
  type: z.literal(GuardianRecommendationChannelMessageTypes.dismissRecommendation),
});

export type IDismissRecommendationGuardianChannelMessage = z.infer<typeof ZDismissRecommendationGuardianChannelMessage>;

export const ZNewGuidelineGuardianChannelMessage = z.object({
  type: z.literal(GuardianRecommendationChannelMessageTypes.newGuideline),
  data: z.object({
    guideline: ZGuidelineInfo,
  }),
});

export type INewGuidelineGuardianChannelMessage = z.infer<typeof ZNewGuidelineGuardianChannelMessage>;

export const ZRecommendationGuardianChannelMessage = z.union([
  ZNewRecommendationGuardianChannelMessage,
  ZDismissRecommendationGuardianChannelMessage,
  ZNewGuidelineGuardianChannelMessage,
]);

export type IRecommendationGuardianChannelMessage = z.infer<typeof ZRecommendationGuardianChannelMessage>;

export enum GuardianOverwolfChannelMessageTypes {
  gameEventStatusChanged = 'gameEventStatusChanged',
}

export const ZGameEventStatusChangedGuardianChannelMessage = z.object({
  type: z.literal(GuardianOverwolfChannelMessageTypes.gameEventStatusChanged),
  data: z.object({
    status: z.nativeEnum(GameEventStatus),
  }),
});

export const ZGuardianOverwolfChannelMessage = ZGameEventStatusChangedGuardianChannelMessage;

export type IGuardianOverwolfChannelMessage = z.infer<typeof ZGuardianOverwolfChannelMessage>;

export enum GuardianPlayerChannelMessageTypes {
  playerNameChanged = 'playerNameChanged',
}

export const ZPlayerNameChangedGuardianChannelMessage = z.object({
  type: z.literal(GuardianPlayerChannelMessageTypes.playerNameChanged),
  data: z.object({
    playerName: ZPlayerNameSchema,
  }),
});

export type IPlayerNameChangedGuardianChannelMessage = z.infer<typeof ZPlayerNameChangedGuardianChannelMessage>;

export const ZGuardianPlayerChannelMessage = ZPlayerNameChangedGuardianChannelMessage;

export type IGuardianPlayerChannelMessage = z.infer<typeof ZGuardianPlayerChannelMessage>;

// General message type for guardian channel
export const ZGuardianChannelMessage = z.union([
  ZRecommendationGuardianChannelMessage,
  ZHotkeysGuardianChannelMessage,
  ZMatchGuardianChannelMessage,
  ZGuardianOverwolfChannelMessage,
  ZGuardianPlayerChannelMessage,
]);

export type IGuardianChannelMessage = z.infer<typeof ZGuardianChannelMessage> | GuardianEvent;

// Create a schema map for parsing
export const GuardianChannelMessageSchemaMap: Record<GuardianChannelType, z.ZodSchema> = {
  [GuardianChannelType.recommendations]: ZRecommendationGuardianChannelMessage,
  [GuardianChannelType.hotkeys]: ZHotkeysGuardianChannelMessage,
  [GuardianChannelType.match]: ZMatchGuardianChannelMessage,
  [GuardianChannelType.overwolf]: ZGuardianOverwolfChannelMessage,
  [GuardianChannelType.player]: ZGuardianPlayerChannelMessage,
  [GuardianChannelType.events]: z.any(),
};

// Create a map between the type of the enum and the type of the channel
export type GuardianChannelMessageTypeMap = {
  [GuardianChannelType.recommendations]: IRecommendationGuardianChannelMessage;
  [GuardianChannelType.hotkeys]: IHotkeysGuardianChannelMessage;
  [GuardianChannelType.match]: IGuardianMatchChannelMessage;
  [GuardianChannelType.overwolf]: IGuardianOverwolfChannelMessage;
  [GuardianChannelType.player]: IGuardianPlayerChannelMessage;
  [GuardianChannelType.events]: GuardianEvent;
};
