import {
  IAdvantageDynamicsUserWidgetData,
  IClosedTradeToKillsUserWidgetData,
  IFirstDeathRoundLostRatioWidgetData,
  IFirstEngagementUserWidgetData,
  IFirstKillDeathRatioUserWidgetData,
  IFirstKillRoundWonRatioWidgetData,
  IAverageGuardianScoreWidgetData,
  IScoreToRoundOutcomeUserWidgetData,
  ITopAgentsUserWidget,
  ITopAgentsUserWidgetData,
  ITopBehavioursUserWidgetData,
  ITradedToDeathUserWidgetData,
  WidgetType,
  XMatchesMin,
  ILatestAgentsUserWidgetData,
  IGuardianScoreOverTimeWidgetData,
  IPerformanceUserWidgetData,
} from '@guardian/common';
import React from 'react';

export const WidgetTypeToDefault: Record<WidgetType, { title: string; description: string }> = {
  [WidgetType.performanceWeaknesses]: {
    title: 'Performance Weaknesses',
    description: `This widget shows your top 5 underperforming behaviours based on all the other players using Guardian. It shows for each behaviour what percent (%) of players have worst performance than you.`,
  },
  [WidgetType.performanceStrengths]: {
    title: 'Performance Strengths',
    description: `This widget shows your top 5 performing behaviours based on all the other players using Guardian. It shows for each behaviour what percent (%) of players have better performance than you.`,
  },
  [WidgetType.contribute]: {
    title: 'Contribute',
    description: `Contribute to the Guardian project and help us improve the app.`,
  },
  [WidgetType.matchHistoryDescription]: {
    title: 'Match History Description',
    description: `This widget shows you a description of your match history.`,
  },
  [WidgetType.guardianScoreOverTime]: {
    title: 'Guardian Score Over Time',
    description: `This widget shows you how your Guardian Score has changed over time. It helps you identify trends and patterns in your performance.`,
  },
  [WidgetType.notTradedAfterFirstDeathsCausesRoundLoss]: {
    title: 'Not Traded After First Deaths Causes Round Loss',
    description: `This widget shows you how many rounds you have lost because you have not been traded after your first death.`,
  },
  [WidgetType.firstEngagement]: {
    title: 'First Engagements',
    description:
      'Track your early-round impact with first kills, deaths, and assists. Quickly identify opening play trends from the match.',
  },
  [WidgetType.tradedToDeathRatio]: {
    title: 'Traded / Deaths',
    description: `Assess how frequently teammates trade you when you're eliminated. This ratio helps you understand if your positioning aids in securing return kills.`,
  },
  [WidgetType.closedTradeToKillsRatio]: {
    title: 'Closed Trades / Kills',
    description: `Track your ability to close trades and secure kills. This ratio helps you understand if your positioning aids in securing return kills.`,
  },
  [WidgetType.scoreToRoundOutcome]: {
    title: 'Score / Round Outcome',
    description: `See how your score changes based on the outcome of the round. This helps you understand how your performance impacts the outcome of the round.`,
  },
  [WidgetType.firstKillDeathRatio]: {
    title: 'First Kill / Death',
    description: `Track your ability to secure first kills and avoid first deaths. This ratio helps you understand your early-round impact.`,
  },
  [WidgetType.firstDeathRoundLostRatio]: {
    title: 'First Death Outcome',
    description: `This shows you "Round Losses after first deaths / First Deaths". This helps you understand how your early-round impact impacts the outcome of the round.`,
  },
  [WidgetType.firstKillRoundWonRatio]: {
    title: 'First Kill Outcome',
    description: `This shows you "Round Wins after first kills / First Kills". This helps you understand how your early-round impact impacts the outcome of the round.`,
  },
  [WidgetType.advantageDynamics]: {
    title: `Advantage Dynamics`,
    description: `This widget shows you how you affect team advantage changes in the game and how your performance impacts the outcome of the round.`,
  },
  [WidgetType.averageGuardianScore]: {
    title: 'G. Score',
    description: `This widget shows you your average Guardian Score across all games.`,
  },
  [WidgetType.topBehaviours]: {
    title: `Top Behaviours`,
    description: `This widget shows behaviours you have commited and counts how manyy rounds you have lost or won because of them.`,
  },
  [WidgetType.topAgents]: {
    title: 'Top Agents',
    description: `This widget shows your best agents based on bayesian average. If you picked an agent for 1 match and performed well compared to your other agents, you may see him here.`,
  },
  [WidgetType.firstEngagementRounds]: {
    title: 'First Engagements',
    description:
      'Track your early-round impact with first kills, deaths, and assists. Quickly identify opening play trends from the match.',
  },
  [WidgetType.latestAgents]: {
    title: 'Latest Agents',
    description: `This widget shows your latest agents and how you performed with them.`,
  },
};

export type UserWidgetPropsBase = {
  loading?: boolean;
  likeLoading?: boolean;
  title?: string;
  description?: string;
  like?: boolean;
};

export type FirstEngagementWidgetProps = React.ComponentProps<'div'> & IFirstEngagementUserWidgetData;

export type TradedToDeathsWidgetProps = React.ComponentProps<'div'> & ITradedToDeathUserWidgetData;

export type ClosedTradesToKillsWidgetProps = React.ComponentProps<'div'> & IClosedTradeToKillsUserWidgetData;

export type ScoreToRoundOutcomeWidgetProps = React.ComponentProps<'div'> &
  IScoreToRoundOutcomeUserWidgetData & { onPointClicked?: (round: number) => void };

export type FirstKillDeathRatioWidgetProps = React.ComponentProps<'div'> & IFirstKillDeathRatioUserWidgetData;

export type FirstDeathRoundLostRatioWidgetProps = React.ComponentProps<'div'> & IFirstDeathRoundLostRatioWidgetData;

export type FirstKillRoundWonRatioWidgetProps = React.ComponentProps<'div'> & IFirstKillRoundWonRatioWidgetData;

export type AdvantageDynamicsWidgetProps = React.ComponentProps<'div'> & IAdvantageDynamicsUserWidgetData;

export type AverageGuardianScoreWidgetProps = React.ComponentProps<'div'> & IAverageGuardianScoreWidgetData;

export type TopBehavioursWidgetProps = React.ComponentProps<'div'> & ITopBehavioursUserWidgetData;

export type TopAgentsWidgetProps = React.ComponentProps<'div'> & ITopAgentsUserWidgetData;

export type LatestAgentsWidgetProps = React.ComponentProps<'div'> & ILatestAgentsUserWidgetData;

export type ContributeWidgetProps = React.ComponentProps<'div'> & {
  onContributeClicked: () => void;
};

export type MatchHistoryDescriptionWidgetProps = React.ComponentProps<'div'>;

export type ScoreOverTimeWidgetProps = React.ComponentProps<'div'> & IGuardianScoreOverTimeWidgetData;

export type PerformanceWidgetProps = React.ComponentProps<'div'> &
  IPerformanceUserWidgetData & {
    onClickNextArrow: (direction: 'left' | 'right') => void;
    onClickNavigation: () => void;
  };

export type UserWidgetPropsInternal = UserWidgetPropsBase & {
  onLikeClicked: () => void;
  component: React.ReactNode | null;
  widgetType: WidgetType;
} & React.ComponentProps<'div'>;
