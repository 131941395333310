import { z } from 'zod';
import { ZBugReport } from '../entities/bug-report';

export const ZReportABugArgs = ZBugReport.pick({
  description: true,
  area: true,
  email: true,
  appVersion: true,
  overwolfVersion: true,
});

export type IReportABugArgs = z.infer<typeof ZReportABugArgs>;

export const ZReportABugRequest = z.object({
  body: ZReportABugArgs,
});
