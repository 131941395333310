import { getAssetUrl } from '../../utils';
import { FileItemKey } from '@guardian/common';

export enum LoadingGifType {
  clawAndOrder = 'clawAndOrder',
  bazookaBadger = 'bazookaBadger',
  whackASquirrel = 'whackASquirrel',
  omenCatDance = 'omenCatDance',
  perfectlyRoasted = 'perfectlyRoasted',
  neverForgetLegDay = 'neverForgetLegDay',
  sandsOfTime = 'sandsOfTime',
  bunnyHop = 'bunnyHop',
  tactiBear = 'tactiBear',
  onTarget = 'onTarget',
}

export const LoadingGifTypeToUrl: Record<LoadingGifType, string> = {
  [LoadingGifType.clawAndOrder]: getAssetUrl(FileItemKey.gif_clawAndOrder),
  [LoadingGifType.bazookaBadger]: getAssetUrl(FileItemKey.gif_bazookaBadger),
  [LoadingGifType.whackASquirrel]: getAssetUrl(FileItemKey.gif_whackASquirrel),
  [LoadingGifType.omenCatDance]: getAssetUrl(FileItemKey.gif_omenCatDance),
  [LoadingGifType.perfectlyRoasted]: getAssetUrl(FileItemKey.gif_perfectlyRoasted),
  [LoadingGifType.neverForgetLegDay]: getAssetUrl(FileItemKey.gif_neverForgetLegDay),
  [LoadingGifType.sandsOfTime]: getAssetUrl(FileItemKey.gif_sandsOfTime),
  [LoadingGifType.bunnyHop]: getAssetUrl(FileItemKey.gif_bunnyHop),
  [LoadingGifType.tactiBear]: getAssetUrl(FileItemKey.gif_tactiBear),
  [LoadingGifType.onTarget]: getAssetUrl(FileItemKey.gif_onTarget),
};

export const AllGifUrls = Object.values(LoadingGifTypeToUrl);
