import { z } from 'zod';

export const ZAddKnownIssueArgs = z.object({
  description: z.string(),
});

export type IAddKnownIssueArgs = z.infer<typeof ZAddKnownIssueArgs>;

export const ZAddKnownIssuesRequest = z.object({
  body: ZAddKnownIssueArgs,
});
