import { z } from 'zod';
import { ZAgentInsights } from '../../insights';

export const ZUserShowcase = z.object({
    userId: z.string(),
    userName: z.string().optional(),
    isGuest: z.boolean(),
    topAgents: ZAgentInsights.array()
})

export type IUserShowcase = z.infer<typeof ZUserShowcase>