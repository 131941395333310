import { z } from 'zod';
import { SystemMessageReply } from '../../../enums';

export const ZReplySystemMessageArgs = z.object({
  systemMessageId: z.string(),
  reply: z.nativeEnum(SystemMessageReply),
});

export type IReplySystemMessageArgs = z.infer<typeof ZReplySystemMessageArgs>;

export const ZReplySystemMessageRequest = z.object({
  body: ZReplySystemMessageArgs,
});
