import { z } from 'zod';
import { ZEntity } from '../_base';
import { SystemMessageReply } from '../../enums';

export const ZSystemMessage = ZEntity.extend({
  message: z.string(),
  neutralActionText: z.string().optional().nullable(),
  approveActionText: z.string().optional().nullable(),
  rejectActionText: z.string().optional().nullable(),
  expireDate: z.coerce.date(),
});

export type ISystemMessage = z.infer<typeof ZSystemMessage>;

export const ZUserSystemMessage = z.object({
  systemMessageId: z.string(),
  userId: z.string(),
  replyDate: z.coerce.date().optional().nullable(),
  reply: z.nativeEnum(SystemMessageReply).optional().nullable(),
});

export type IUserSystemMessage = z.infer<typeof ZUserSystemMessage>;
