import { z } from 'zod';
import { Ranks } from '../../../types';
import { ZEntity } from '../../_base';
import { UserGroup, UserOnboardingStatus } from '../../../enums';

export const ZUserBase = ZEntity.extend({
  userName: z.string(),
  email: z.string().email().optional(),
  isGuest: z.boolean(),
  isOnline: z.boolean(),
  refferals: z.number(),
  refferedByUserId: z.string().optional(),
  lastOnlineDate: z.date().optional(),
  firstRank: z.nativeEnum(Ranks).optional().nullable(),
  currentRank: z.nativeEnum(Ranks).optional().nullable(),
  maxRank: z.nativeEnum(Ranks).optional().nullable(),
  group: z.nativeEnum(UserGroup),
  onboardingStatus: z.nativeEnum(UserOnboardingStatus),
  riotPuuid: z.string().optional().nullable(),
  riotActiveShard: z.string().optional().nullable(),
});

export type IUserBase = z.infer<typeof ZUserBase>;

export const ZUser = ZUserBase.extend({
  isActive: z.boolean(),
});

export type IUser = z.infer<typeof ZUser>;
