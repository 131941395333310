import { z } from 'zod';

export const ZRemoveUserDashboardItemArgs = z.object({
  dashboardType: z.string(),
  widgetType: z.string(),
});

export type IRemoveUserDashboardItemArgs = z.infer<typeof ZRemoveUserDashboardItemArgs>;

export const ZRemoveUserDashboardItemRequest = z.object({
  body: ZRemoveUserDashboardItemArgs,
});
