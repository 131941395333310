import { Rule } from '../services/guidelines/rules/rule';
import { CharacterMood, PatternType } from '@guardian/common';
import { z } from 'zod';

export enum GuidelineLevel {
  Level1,
  Level2,
  Level3,
  Level4,
  Level5,
  Level6,
  Level7,
  LevelHype,
}

export const ZGuidelineInfo = z.object({
  title: z.string(),
  content: z.string(),
  level: z.nativeEnum(GuidelineLevel),
  guidelineId: z.string(),
  zIndex: z.number(),
  ruleName: z.string(),
  rule: z.any(),
  isPositive: z.boolean().optional(),
  tag: z.string().optional(),
  patternType: z.nativeEnum(PatternType).optional(),
  characterMood: z.nativeEnum(CharacterMood).optional(),
});

export type GuidelineInfo = z.infer<typeof ZGuidelineInfo>;

export type GuidelineGroup = GuidelineInfo[];
