import React from 'react';
import * as styles from './PrivacyPolicy.css';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> August 1st, 2024
      </p>
      <h2 className={styles.sectionTitle}>1. Introduction</h2>
      <p>
        Welcome to Guardian! We are committed to protecting your privacy. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you use our application, Guardian, which provides insights on player
        behavior in the game of VALORANT. By using Guardian, you agree to the collection and use of information in accordance with
        this Privacy Policy.
      </p>
      <h2 className={styles.sectionTitle}>2. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <h3 className={styles.subsectionTitle}>a. Personal Information:</h3>
      <ul className={styles.list}>
        <li>
          <strong>Riot Sign-On (RSO) Information:</strong> When you sign up for Guardian, we use Riot Games' authentication system
          (RSO) to verify your identity. This process provides us with your Riot ID and related account information necessary for
          accessing your gameplay data.
        </li>
      </ul>
      <h3 className={styles.subsectionTitle}>b. Gameplay Data:</h3>
      <ul className={styles.list}>
        <li>
          We collect data related to your VALORANT gameplay, including but not limited to trades, kills, deaths, assists, the
          number of players alive on each team when events occur, and objective plant times.
        </li>
      </ul>
      <h2 className={styles.sectionTitle}>3. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul className={styles.list}>
        <li>
          <strong>To Provide and Improve Our Services:</strong> We use your gameplay data to generate insights, trends, and tips
          to help you improve your performance in VALORANT.
        </li>
        <li>
          <strong>To Communicate with You:</strong> We may send you updates, notifications, and other information related to
          Guardian.
        </li>
        <li>
          <strong>To Ensure Security:</strong> We use your information to verify your identity and ensure the security of your
          account.
        </li>
      </ul>
      <h2 className={styles.sectionTitle}>4. Opt-In Policy</h2>
      <p>
        Guardian requires explicit consent from users before collecting and displaying their gameplay data. By signing up for
        Guardian and using the RSO system, you agree to allow us to collect and display your VALORANT gameplay data.
      </p>
      <h2 className={styles.sectionTitle}>5. Disclosure of Your Information</h2>
      <p>We do not share your personal information with third parties except in the following circumstances:</p>
      <ul className={styles.list}>
        <li>
          <strong>With Your Consent:</strong> We may share your information with third parties if you give us explicit consent to
          do so.
        </li>
        <li>
          <strong>Compliance with Laws:</strong> We may disclose your information when required by law or in response to a legal
          process.
        </li>
        <li>
          <strong>To Protect Our Rights:</strong> We may disclose your information to protect the rights, property, or safety of
          Guardian, our users, or others.
        </li>
      </ul>
      <h2 className={styles.sectionTitle}>6. Data Security</h2>
      <p>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have
        taken reasonable steps to secure the personal information you provide, please be aware that despite our efforts, no
        security measures are perfect or impenetrable.
      </p>
      <h2 className={styles.sectionTitle}>7. Your Data Rights</h2>
      <p>You have the right to:</p>
      <ul className={styles.list}>
        <li>
          <strong>Access Your Data:</strong> You can request access to the personal information we hold about you.
        </li>
        <li>
          <strong>Update Your Data:</strong> You can request that we update or correct any information that is inaccurate or
          incomplete.
        </li>
        <li>
          <strong>Delete Your Data:</strong> You can request that we delete your personal information.
        </li>
      </ul>
      <h2 className={styles.sectionTitle}>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
        on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>
      <h2 className={styles.sectionTitle}>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <strong>Email:</strong> guardianvfps@gmail.com
      <p>By using Guardian, you agree to this Privacy Policy. Thank you for trusting us with your gameplay data.</p>
    </div>
  );
};
