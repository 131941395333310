export enum FileItemKey {
  agent_astra_face = 'agent_astra_face',
  agent_breach_face = 'agent_breach_face',
  agent_brimstone_face = 'agent_brimstone_face',
  agent_chamber_face = 'agent_chamber_face',
  agent_cypher_face = 'agent_cypher_face',
  agent_deadlock_face = 'agent_deadlock_face',
  agent_fade_face = 'agent_fade_face',
  agent_gekko_face = 'agent_gekko_face',
  agent_harbor_face = 'agent_harbor_face',
  agent_iso_face = 'agent_iso_face',
  agent_jett_face = 'agent_jett_face',
  agent_kayo_face = 'agent_kayo_face',
  agent_killjoy_face = 'agent_killjoy_face',
  agent_neon_face = 'agent_neon_face',
  agent_omen_face = 'agent_omen_face',
  agent_pheonix_face = 'agent_pheonix_face',
  agent_raze_face = 'agent_raze_face',
  agent_reyna_face = 'agent_reyna_face',
  agent_sage_face = 'agent_sage_face',
  agent_skye_face = 'agent_skye_face',
  agent_sova_face = 'agent_sova_face',
  agent_viper_face = 'agent_viper_face',
  agent_yoru_face = 'agent_yoru_face',
  agent_clove_face = 'agent_clove_face',
  agent_vyse_face = 'agent_vysen_face',

  agent_astra_full = 'agent_astra_full',
  agent_breach_full = 'agent_breach_full',
  agent_brimstone_full = 'agent_brimstone_full',
  agent_chamber_full = 'agent_chamber_full',
  agent_cypher_full = 'agent_cypher_full',
  agent_deadlock_full = 'agent_deadlock_full',
  agent_fade_full = 'agent_fade_full',
  agent_gekko_full = 'agent_gekko_full',
  agent_harbor_full = 'agent_harbor_full',
  agent_iso_full = 'agent_iso_full',
  agent_jett_full = 'agent_jett_full',
  agent_kayo_full = 'agent_kayo_full',
  agent_killjoy_full = 'agent_killjoy_full',
  agent_neon_full = 'agent_neon_full',
  agent_omen_full = 'agent_omen_full',
  agent_pheonix_full = 'agent_pheonix_full',
  agent_raze_full = 'agent_raze_full',
  agent_reyna_full = 'agent_reyna_full',
  agent_sage_full = 'agent_sage_full',
  agent_skye_full = 'agent_skye_full',
  agent_sova_full = 'agent_sova_full',
  agent_viper_full = 'agent_viper_full',
  agent_yoru_full = 'agent_yoru_full',
  agent_clove_full = 'agent_clove_full',
  agent_vyse_full = 'agent_vysen_full',

  ult_astra = 'ult_astra',
  ult_breach = 'ult_breach',
  ult_brimstone = 'ult_brimstone',
  ult_chamber = 'ult_chamber',
  ult_cypher = 'ult_cypher',
  ult_deadlock = 'ult_deadlock',
  ult_fade = 'ult_fade',
  ult_gekko = 'ult_gekko',
  ult_harbor = 'ult_harbor',
  ult_iso = 'ult_iso',
  ult_jett = 'ult_jett',
  ult_kayo = 'ult_kayo',
  ult_killjoy = 'ult_killjoy',
  ult_neon = 'ult_neon',
  ult_omen = 'ult_omen',
  ult_pheonix = 'ult_pheonix',
  ult_raze = 'ult_raze',
  ult_reyna = 'ult_reyna',
  ult_sage = 'ult_sage',
  ult_skye = 'ult_skye',
  ult_sova = 'ult_sova',
  ult_viper = 'ult_viper',
  ult_yoru = 'ult_yoru',
  ult_clove = 'ult_clove',
  ult_vyse = 'ult_vyse',

  map_ascent = 'map_ascent',
  map_bind = 'map_bind',
  map_breeze = 'map_breeze',
  map_fracture = 'map_fracture',
  map_haven = 'map_haven',
  map_icebox = 'map_icebox',
  map_lotus = 'map_lotus',
  map_pearl = 'map_pearl',
  map_split = 'map_split',
  map_sunset = 'map_sunset',
  map_abyss = 'map_abyss',

  rank_iron1 = 'rank_iron1',
  rank_iron2 = 'rank_iron2',
  rank_iron3 = 'rank_iron3',
  rank_bronze1 = 'rank_bronze1',
  rank_bronze2 = 'rank_bronze2',
  rank_bronze3 = 'rank_bronze3',
  rank_silver1 = 'rank_silver1',
  rank_silver2 = 'rank_silver2',
  rank_silver3 = 'rank_silver3',
  rank_gold1 = 'rank_gold1',
  rank_gold2 = 'rank_gold2',
  rank_gold3 = 'rank_gold3',
  rank_platinum1 = 'rank_platinum1',
  rank_platinum2 = 'rank_platinum2',
  rank_platinum3 = 'rank_platinum3',
  rank_diamond1 = 'rank_diamond1',
  rank_diamond2 = 'rank_diamond2',
  rank_diamond3 = 'rank_diamond3',
  rank_asc1 = 'rank_asc1',
  rank_asc2 = 'rank_asc2',
  rank_asc3 = 'rank_asc3',
  rank_immortal1 = 'rank_immortal1',
  rank_immortal2 = 'rank_immortal2',
  rank_immortal3 = 'rank_immortal3',
  rank_radiant = 'rank_radiant',
  rank_unranked = 'rank_unranked',

  tier_guardian = 'tier_guardian',
  tier_keeper = 'tier_keeper',
  tier_scout = 'tier_scout',
  tier_watcher = 'tier_watcher',

  gif_clawAndOrder = 'gif_clawAndOrder',
  gif_bazookaBadger = 'gif_bazookaBadger',
  gif_whackASquirrel = 'gif_whackASquirrel',
  gif_omenCatDance = 'gif_omenCatDance',
  gif_perfectlyRoasted = 'gif_perfectlyRoasted',
  gif_neverForgetLegDay = 'gif_neverForgetLegDay',
  gif_sandsOfTime = 'gif_sandsOfTime',
  gif_bunnyHop = 'gif_bunnyHop',
  gif_tactiBear = 'gif_tactiBear',
  gif_onTarget = 'gif_onTarget',

  other_guardians = 'other_guardians',
  other_generic_background = 'other_generic_background',

  role_controller = 'role_controller',
  role_dualist = 'role_dualist',
  role_initiator = 'role_initiator',
  role_sentinel = 'role_sentinel',
}
