export enum CacheKeys {
  default = 'default',
  getUser = 'getUser',
  me = 'me',
  matchHistory = 'matchHistory',
  userSettings = 'userSettings',
  top3Agents = 'top3Agents',
  getUserMatchCount = 'getUserMatchCount',
  userWidget = 'userWidget',
}
