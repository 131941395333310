import { z } from 'zod';
import { ValorantMap } from '../../../types';

export const ZGetCommunityInsightsArgs = z.object({
  map: z.nativeEnum(ValorantMap),
});

export const ZGetCommunityInsightsRequest = z.object({
  params: ZGetCommunityInsightsArgs,
});
