import { z } from 'zod';

export enum GuardianBehaviourType {
  failedSwiftTrade = 'failedSwiftTrade',
  failedTrade = 'failedTrade',

  closedSwiftTrade = 'closedSwiftTrade',
  closedTrade = 'closedTrade',

  swiftTraded = 'swiftTraded',
  traded = 'traded',

  firstKill = 'firstKill',
  firstDeath = 'firstDeath',
  firstAssist = 'firstAssist',

  diedNoTrade = 'diedNoTrade',
  killedNoTrade = 'killedNoTrade',

  // Advantage dynamics - Negatives
  lostAdvantage = 'lostAdvantage',
  gaveAdvantage = 'gaveAdvantage',

  // Advantage dynamics - Positives
  gainedAdvantage = 'gainedAdvantage',
  evenedAdvantage = 'evenedAdvantage',

  killed = 'killed',
  died = 'died',
  assist = 'assist',

  // Clutch
  clutch1v2 = 'clutch_(1v2)',
  clutch1v3 = 'clutch_(1v3)',
  clutch1v4 = 'clutch_(1v4)',
  clutch1v5 = 'clutch_(1v5)',

  spikePlanted = 'spikePlanted',
  spikeDefused = 'spikeDefused',
  spikeDetonated = 'spikeDetonated',

  lostClutch2v1 = 'lostClutch_(2v1)',
  lostClutch3v1 = 'lostClutch_(3v1)',
  lostClutch4v1 = 'lostClutch_(4v1)',
  lostClutch5v1 = 'lostClutch_(5v1)',

  // Round win
  // Round Loss
  // Assisted in a kill that wasnt traded
  // Assisted in a trade
}

export const ZGuardianBehaviourType = z.nativeEnum(GuardianBehaviourType);

export enum BehaviourNature {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
  teamDependant = 'teamDependant',
}
