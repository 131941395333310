import * as React from 'react';
import { SVGProps, memo } from 'react';
import { colorPalette } from '../../style';
const SvgComponent = ({ isFull, ...props }: SVGProps<SVGSVGElement> & { isFull?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M4.25 12.813 6.765 18.1c.382.574 1.03.913 1.72.9h6.03a2.021 2.021 0 0 0 1.72-.9l2.515-5.29a1.583 1.583 0 0 0 0-1.718l-2.501-5.2A2.022 2.022 0 0 0 14.538 5H8.46a2.022 2.022 0 0 0-1.711.892L4.254 11.1a1.583 1.583 0 0 0-.004 1.713Z"
      clipRule="evenodd"
      fill={isFull ? colorPalette.red1 : colorPalette.grey5}
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
