import { z } from 'zod';

export enum RiotQueueId {
  competitive = 'competitive',
  swiftPlay = 'swiftPlay',
  unrated = 'unrated',
  deathmatch = 'deathmatch',
  teamDeathmatch = 'teamDeathmatch',
  spikeRush = 'spikeRush',
}

export const ZRiotQueueId = z.nativeEnum(RiotQueueId).transform(val => val as RiotQueueId);
