import { SVGProps, memo } from 'react';

const SvgComponent = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path stroke={fill ?? '#F5F5F5'} strokeLinecap="round" strokeWidth={1.5} d="M12 17v-6" />
    <circle cx={1} cy={1} r={1} fill={fill ?? '#F5F5F5'} transform="matrix(1 0 0 -1 11 9)" />
    <path
      stroke={fill ?? '#F5F5F5'}
      strokeWidth={1.5}
      d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
