import { z } from 'zod';
import { PatternType } from '../../../enums';
import { ZEntity } from '../../_base';

export const ZUserPattern = ZEntity.extend({
  userId: z.string(),
  type: z.nativeEnum(PatternType),
  createdAt: z.coerce.date(),
  isMatched: z.boolean(),
});

export type IUserPattern = z.infer<typeof ZUserPattern>;
