import { z } from 'zod';
import { TimeFrame } from './app';

export enum WidgetType {
  firstEngagement = 'firstEngagement',
  firstEngagementRounds = 'firstEngagementRounds',
  tradedToDeathRatio = 'tradedToDeathRatio',
  closedTradeToKillsRatio = 'closedTradeToKillsRatio',
  scoreToRoundOutcome = 'scoreToRoundOutcome',
  firstKillDeathRatio = 'firstKillDeathRatio', // Working, not currently used in app
  firstDeathRoundLostRatio = 'firstDeathRoundLostRatio', // Working, not currently used in app
  firstKillRoundWonRatio = 'firstKillRoundWonRatio', // Working, not currently used in app
  advantageDynamics = 'advantageDynamics',
  averageGuardianScore = 'averageGuardianScore', // Working, not currently used in app
  topBehaviours = 'topBehaviours',
  notTradedAfterFirstDeathsCausesRoundLoss = 'notTradedAfterFirstDeathsCausesRoundLoss', // Currently not in "AppUserWidget"
  topAgents = 'topAgents',
  guardianScoreOverTime = 'guardianScoreOverTime',
  latestAgents = 'latestAgents',
  performanceStrengths = 'performanceStrengths',
  performanceWeaknesses = 'performanceWeaknesses',

  // Static Widgets
  contribute = 'contribute', // In App
  matchHistoryDescription = 'matchHistoryDescription', // In App
}

export const StaticWidgetTypes = [WidgetType.contribute, WidgetType.matchHistoryDescription];
export const StoreWidgets = Object.values(WidgetType).filter(widget => !StaticWidgetTypes.includes(widget));
export const DefaultWidgets = [
  WidgetType.firstEngagement,
  WidgetType.tradedToDeathRatio,
  WidgetType.closedTradeToKillsRatio,
  WidgetType.scoreToRoundOutcome,
  WidgetType.advantageDynamics,
  WidgetType.topBehaviours,
  WidgetType.topAgents,
  WidgetType.averageGuardianScore,
];

export const TimeFrameToBlacklist: Record<TimeFrame, Array<WidgetType>> = {
  [TimeFrame.match]: [
    WidgetType.topAgents,
    WidgetType.firstKillRoundWonRatio,
    WidgetType.firstDeathRoundLostRatio,
    WidgetType.firstKillDeathRatio,
    WidgetType.firstEngagementRounds,
    WidgetType.latestAgents,
  ],
  [TimeFrame.pastXMatches]: [
    WidgetType.firstKillRoundWonRatio,
    WidgetType.firstDeathRoundLostRatio,
    WidgetType.firstKillDeathRatio,
    WidgetType.firstEngagementRounds,
  ],
  [TimeFrame.day]: [
    WidgetType.firstKillRoundWonRatio,
    WidgetType.firstDeathRoundLostRatio,
    WidgetType.firstKillDeathRatio,
    WidgetType.firstEngagementRounds,
  ],
  [TimeFrame.week]: [
    WidgetType.firstKillRoundWonRatio,
    WidgetType.firstDeathRoundLostRatio,
    WidgetType.firstKillDeathRatio,
    WidgetType.firstEngagementRounds,
  ],
  [TimeFrame.XMatchesAgo]: [
    WidgetType.firstKillRoundWonRatio,
    WidgetType.firstDeathRoundLostRatio,
    WidgetType.firstKillDeathRatio,
    WidgetType.firstEngagementRounds,
  ],
};

export enum Trend {
  incline = 'incline',
  decline = 'decline',
  none = 'none',
}

export const ZTrend = z.nativeEnum(Trend);
