import { z } from 'zod';

export const ZUpdatePlayerNameArgs = z.object({
  gameName: z.string(),
  tagLine: z.string(),
});

export type IUpdatePlayerNameArgs = z.infer<typeof ZUpdatePlayerNameArgs>;

export const ZUpdatePlayerNameRequest = z.object({
  body: ZUpdatePlayerNameArgs,
});
