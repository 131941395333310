import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { backgroundImageCss, loginTextCss, riotLoginCss } from './RiotLogin.css';
import { z } from 'zod';
import LoginImageUrl from './assets/login.jpg?url';

export enum AuthAction {
  Login = 'login',
  Logout = 'logout',
}

const ZAuthAction = z.nativeEnum(AuthAction);

export const RiotLogin = () => {
  const { authAction } = useParams();
  const navigate = useNavigate();

  const actionParsed = ZAuthAction.safeParse(authAction);

  React.useEffect(() => {
    const actionParsed = ZAuthAction.safeParse(authAction);

    if (!actionParsed.success) {
      console.log('Invalid auth action');
      navigate('/');
    }
  }, [authAction]);

  if (!actionParsed.success) {
    console.log('Invalid auth action');
    return null;
  }

  let loginText = '';

  switch (actionParsed.data) {
    case AuthAction.Login:
      loginText = 'Welcome to Guardian! You have successfully linked your Riot account.';
      break;
    case AuthAction.Logout:
      loginText = 'You have successfully logged out of Guardian.';
      break;
  }

  return (
    <div className={riotLoginCss}>
      <img src={LoginImageUrl} className={backgroundImageCss} />
      <div className={loginTextCss}>
        {actionParsed.data === AuthAction.Login && (
          <>
            <span
              style={{
                fontSize: '2em',
                marginBottom: '20px',
                fontWeight: 'bold',
              }}
            >
              Welcome to Guardian!
            </span>
            <span
              style={{
                fontSize: '1.5em',
                marginBottom: '20px',
              }}
            >
              You have successfully linked your Riot account.
            </span>
            <span>You will be redirected to the app shortly.</span>
          </>
        )}
        {actionParsed.data === AuthAction.Logout && (
          <>
            <span
              style={{
                fontSize: '2em',
                marginBottom: '20px',
                fontWeight: 'bold',
              }}
            >
              You have successfully logged out of Guardian.
            </span>
            <span>Thanks for trying us out</span>
            <span>We hope to see you come back!</span>
          </>
        )}
      </div>
    </div>
  );
};
