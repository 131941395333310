import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g data-name="Group 30">
      <path d="M15.999.005a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm0 28a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" data-name="Path 364" />
      <path d="M4.347 24.828 24.831 4.343l2.828 2.829L7.175 27.656z" data-name="Rectangle 41" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
