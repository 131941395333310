import { z } from 'zod';
import { WidgetType } from '../../../enums/widget';

export const ZWidget = z.object({
  type: z.nativeEnum(WidgetType),
  likes: z.number(),
  name: z.string(),
  description: z.string(),
  price: z.number(),
  gridRowSpan: z.number(),
  gridColumnSpan: z.number(),
});

export type IWidget = z.infer<typeof ZWidget>;
