import { z } from 'zod';
import { ResolutionSizeType } from '../../../enums';

export const ZUserSettings = z.object({
  userId: z.string(),
  resolutionSizeType: z.nativeEnum(ResolutionSizeType),
  showInGameOverlay: z.boolean(),
  showInGameUltStatus: z.boolean(),
});

export type IUserSettings = z.infer<typeof ZUserSettings>;
