import { z } from 'zod';
import { ZMatch } from '../entities';

export const ZStartMatchArgs = ZMatch.pick({
  id: true,
  agent: true,
  map: true,
  startingTeam: true,
  playerRank: true,
  gameMode: true
});

export type IStartMatchArgs = z.infer<typeof ZStartMatchArgs>;

export const ZStartMatchRequest = z.object({
  body: ZStartMatchArgs,
});
