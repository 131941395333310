import { z } from 'zod';
import { ZRiotQueueId } from '../../../enums';

export const ZRiotMatchInfo = z.object({
  matchId: z.string(),
  gameStartTimeMillis: z.number(),
  queueId: ZRiotQueueId,
});

export type RiotMatchInfo = z.infer<typeof ZRiotMatchInfo>;

export const ZGetRiotMatchInfoList = z.object({
  puuid: z.string(),
  history: z.array(ZRiotMatchInfo),
});

export type GetRiotMatchInfoList = z.infer<typeof ZGetRiotMatchInfoList>;
