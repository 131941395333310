export enum GameSessionCheckpointTypes {
  matchStart = 'matchStart',
  victory = 'victory',
  defeat = 'defeat',
  draw = 'draw',
  gameOpen = 'gameOpen',
  gameClose = 'gameClose',
  gameRunning = 'gameRunning',
  enterLobby = 'enterLobby',
  warmupStart = 'warmupStart',
  warmupEnd = 'warmupEnd',
}

export enum GameSessionRecommendationTypes {
  startMatch = 'startMatch',
  takeBreak = 'takeBreak',
  startWarmup = 'startWarmup',
  losingStreak = 'losingStreak',
  endSession = 'endSession',
  reviewMatch = 'reviewMatch',
}
