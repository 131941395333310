import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fill={props.fill || '#000'}
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16Zm3.707-9.293a1 1 0 0 0-1.414-1.414L6.998 8.586l-1.29-1.293a1 1 0 1 0-1.416 1.414l1.997 2a1 1 0 0 0 1.415 0l4.003-4Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
