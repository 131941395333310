export enum VideoAdContainerEvent {
  loaded = 'loaded',
  displayAd = 'displayAd',
  play = 'play',
  impression = 'impression',
  complete = 'complete',
  error = 'error',
}

export enum OverwolfAdEventType {
  videoPlayerLoaded = 'videoPlayerLoaded',
  displayAdLoaded = 'displayAdLoaded',
  adPlayed = 'adPlayed',
  videoImpression = 'videoImpression',
  videoComplete = 'videoComplete',
  videoError = 'videoError',
}
