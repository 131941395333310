export enum BugArea {
    loginAndSignup = 'loginAndSignup',
    inGame = 'inGame',
    desktopApp = 'desktopApp',
    behaviours = 'behaviours',
}

export enum NumericBugAreas { 
    loginAndSignup = 0,
    inGame = 1,
    desktopApp = 2,
    behaviours = 3,
}

export const NumericToBugArea = {
    [NumericBugAreas.loginAndSignup]: BugArea.loginAndSignup,
    [NumericBugAreas.inGame]: BugArea.inGame,
    [NumericBugAreas.desktopApp]: BugArea.desktopApp,
    [NumericBugAreas.behaviours]: BugArea.behaviours,
}