import { z } from 'zod';
import { DashboardType, WidgetType } from '../../../enums';

export const ZUserDashboard = z.object({
  userId: z.string(),
  dashboardType: z.nativeEnum(DashboardType),
  name: z.string(),
});

export type IUserDashboard = z.infer<typeof ZUserDashboard>;

export const ZUserDashboardItem = z.object({
  id: z.string(),
  userId: z.string(),
  dashboardType: z.nativeEnum(DashboardType),
  widgetType: z.nativeEnum(WidgetType),
  gridColumn: z.number(),
  gridRow: z.number(),
  colSpan: z.number(),
  rowSpan: z.number(),
});

export type IUserDashboardItem = z.infer<typeof ZUserDashboardItem>;
