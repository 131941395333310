import React from 'react';
import * as styles from './TermsOfService.css';

export const TermsOfService: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> August 1, 2024
      </p>{' '}
      <h2 className={styles.sectionTitle}>1. Introduction</h2>
      <p>
        Welcome to Guardian! By using our application, you agree to these Terms of Service ("Terms"). Please read them carefully.
        If you do not agree to these Terms, do not use our application.
      </p>
      <h2 className={styles.sectionTitle}>2. Use of Our Application</h2>
      <h3 className={styles.subsectionTitle}>a. Eligibility:</h3>
      <p>You must be at least 13 years old to use Guardian.</p>
      <h3 className={styles.subsectionTitle}>b. Account:</h3>
      <p>
        You must provide accurate and complete information when creating an account. You are responsible for all activities that
        occur under your account.
      </p>
      <h3 className={styles.subsectionTitle}>c. License:</h3>
      <p>
        We grant you a limited, non-exclusive, non-transferable, and revocable license to use Guardian for personal,
        non-commercial purposes.
      </p>
      <h2 className={styles.sectionTitle}>3. User Conduct</h2>
      <p>You agree not to use Guardian to:</p>
      <ul className={styles.list}>
        <li>Violate any laws or regulations.</li>
        <li>Infringe on the rights of others.</li>
        <li>Distribute spam or harmful software.</li>
        <li>Engage in any activity that could harm or disrupt Guardian's services.</li>
      </ul>
      <h2 className={styles.sectionTitle}>4. Content</h2>
      <h3 className={styles.subsectionTitle}>a. Your Content:</h3>
      <p>
        You retain ownership of any content you post or submit to Guardian. However, by posting or submitting content, you grant
        us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content.
      </p>
      <h3 className={styles.subsectionTitle}>b. Our Content:</h3>
      <p>
        We retain ownership of all intellectual property rights in Guardian and its content. You may not use our content without
        our permission.
      </p>
      <h2 className={styles.sectionTitle}>5. Termination</h2>
      <p>We may suspend or terminate your access to Guardian at any time for any reason, including if you violate these Terms.</p>
      <h2 className={styles.sectionTitle}>6. Disclaimer of Warranties</h2>
      <p>
        Guardian is provided "as is" without warranties of any kind. We do not guarantee that Guardian will be available,
        error-free, or secure.
      </p>
      <h2 className={styles.sectionTitle}>7. Limitation of Liability</h2>
      <p>To the maximum extent permitted by law, we are not liable for any damages arising from your use of Guardian.</p>
      <h2 className={styles.sectionTitle}>8. Changes to These Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You
        are advised to review these Terms periodically for any changes.
      </p>
      <h2 className={styles.sectionTitle}>9. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <strong>Email:</strong> guardianvfps@gmail.com
      <p>By using Guardian, you agree to these Terms of Service.</p>
    </div>
  );
};
