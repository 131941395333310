import * as React from "react"
import { SVGProps, memo } from "react"

type CloseProps = SVGProps<SVGSVGElement> & {
    fillColor?: string
}

const SvgComponent = ({fillColor, ...props}: CloseProps) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke={fillColor || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="m7 7 10 10M7 17 17 7"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo