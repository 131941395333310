import { z } from 'zod';
import { ZFilterBase, ZFilterResponseBase } from '../../_base';
import { MatchOutcomes, ValorantAgent, ValorantMap } from '../../../types';
import { ZMatch } from '../entities';

export const ZGetMatchHistoryArgs = ZFilterBase.extend({
    map: z.nativeEnum(ValorantMap).optional(),
    agent: z.nativeEnum(ValorantAgent).optional(),
    outcome: z.nativeEnum(MatchOutcomes).optional(),
    isPositiveScore: z.boolean().optional(),
})

export type IGetMatchHistoryArgs = z.infer<typeof ZGetMatchHistoryArgs>;

export const ZGetMatchHistoryRequest = z.object({
    body: ZGetMatchHistoryArgs
})

export const ZGetMatchHistoryResponse = ZFilterResponseBase.extend({
    matches: ZMatch.array()
})

export type IGetMatchHistoryResponse = z.infer<typeof ZGetMatchHistoryResponse>;