import { z } from 'zod';
import { ZSystemMessage } from '../entities';

export const ZAddSystemMessageArgs = ZSystemMessage.omit({
  id: true,
});

export type IAddSystemMessageArgs = z.infer<typeof ZAddSystemMessageArgs>;

export const ZAddSystemMessageRequest = z.object({
  body: ZAddSystemMessageArgs,
});
