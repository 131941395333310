export enum SocketInvalidationType {
  knownIssues = 'knownIssues',
  patchNotes = 'patchNotes',
  systemMessage = 'systemMessage',
}

export enum BroadcastType {
  mandatoryVersionUpdate = 'mandatoryVersionUpdate',
  invalidateData = 'invalidateData',
}
