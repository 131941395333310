import { downloadButton, footerCss, homePageCss, slogan, socialsContainer, socialsIcon } from './home-page.css';
import { colorPalette } from '@guardian/ui-common';
import DiscordIcon from '../../assets/DiscordIcon';
import XIcon from '../../assets/XIcon';
import DownloadIcon from '../../assets/DownloadIcon.tsx';

export const HomePage = () => {
  const onSocialsClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className={homePageCss}>
      <span className={slogan}>Data-Driven Insights, Game-Changing Results.</span>
      <div className={socialsContainer}>
        <DiscordIcon
          className={socialsIcon}
          fill={colorPalette.red1}
          onClick={() =>
            onSocialsClick('https://discord.gg/h8VUbn25DX?utm_campaign=website&utm_medium=website&utm_source=website')
          }
        />
        <XIcon
          className={socialsIcon}
          fill={colorPalette.red1}
          onClick={() => onSocialsClick('https://twitter.com/guardianvfps')}
        />
      </div>
      <a
        className={downloadButton}
        href={
          'https://download.overwolf.com/install/Download?Name=Guardian&ExtensionId=cmnhjeiadjimienmdbgbnimkamednmnlljegckih&Channel=web_dl_btn&utm_campaign=website&utm_medium=website&utm_source=website'
        }
      >
        <DownloadIcon />
        Download
      </a>
    </div>
  );
};
