import {
  ValorantAgent,
  Ranks,
  GuardianTier,
  ValorantMap,
  FileItemKey,
  AppEnv,
  Weapons,
  CloudFrontProduction,
} from '@guardian/common';

const AnihilationImageUrl = '/assets/ultimates/Anihilation.png';
const BladeStormImageUrl = '/assets/ultimates/BladeStorm.png';
const CosmicDivideImageUrl = '/assets/ultimates/CosmicDivide.png';
const DimensionalDriftImageUrl = '/assets/ultimates/DimensionalDrift.png';
const EmpressImageUrl = '/assets/ultimates/Empress.png';
const FromTheShadows = '/assets/ultimates/FromTheShadows.png';
const HuntersFuryImageUrl = '/assets/ultimates/HuntersFury.png';
const LockdownImageUrl = '/assets/ultimates/Lockdown.png';
const NeuralTheftImageUrl = '/assets/ultimates/NeuralTheft.png';
const NightFallImageUrl = '/assets/ultimates/NightFall.png';
const NullCMDImageUrl = '/assets/ultimates/NullCMD.png';
const OrbitalStrikeImageUrl = '/assets/ultimates/OrbitalStrike.png';
const OverdriveImageUrl = '/assets/ultimates/Overdrive.png';
const ReckoningImageUrl = '/assets/ultimates/Reckoning.png';
const RollingThunderImageUrl = '/assets/ultimates/RollingThunder.png';
const RunItBackImageUrl = '/assets/ultimates/RunItBack.png';
const SeekersImageUrl = '/assets/ultimates/Seekers.png';
const ShowstopperImageUrl = '/assets/ultimates/Showstopper.png';
const ThrashImageUrl = '/assets/ultimates/Thrash.png';
const TourDeForceImageUrl = '/assets/ultimates/TourDeForce.png';
const VipersPitImageUrl = '/assets/ultimates/VipersPit.png';
const DeathContractImageUrl = '/assets/ultimates/DeathContract.png';

export function getAssetUrl(fileItemKey: FileItemKey) {
  const mode = import.meta.env.MODE as AppEnv;

  switch (mode) {
    case AppEnv.local:
      return `http://localhost:7777/api/files/${fileItemKey}`;
    case AppEnv.prod:
      return `https://api.guardianfps.gg/api/files/${fileItemKey}`;
  }

  return `http://localhost:3000/api/files/${fileItemKey}`;
}

export const ValorantMapToImageUrlMap: Record<ValorantMap | 'Unknown', string> = {
  [ValorantMap.ascent]: getAssetUrl(FileItemKey.map_ascent),
  [ValorantMap.bind]: getAssetUrl(FileItemKey.map_bind),
  [ValorantMap.breeze]: getAssetUrl(FileItemKey.map_breeze),
  [ValorantMap.fracture]: getAssetUrl(FileItemKey.map_fracture),
  [ValorantMap.haven]: getAssetUrl(FileItemKey.map_haven),
  [ValorantMap.icebox]: getAssetUrl(FileItemKey.map_icebox),
  [ValorantMap.lotus]: getAssetUrl(FileItemKey.map_lotus),
  [ValorantMap.pearl]: getAssetUrl(FileItemKey.map_pearl),
  [ValorantMap.split]: getAssetUrl(FileItemKey.map_split),
  [ValorantMap.sunset]: getAssetUrl(FileItemKey.map_sunset),
  [ValorantMap.abyss]: getAssetUrl(FileItemKey.map_abyss),
  [ValorantMap.district]: '',
  [ValorantMap.kasbah]: '',
  [ValorantMap.piazza]: '',
  [ValorantMap.range]: '',
  [ValorantMap.drift]: '',
  Unknown: '',
};

export const ValorantAgentToFullImageUrlMap: Record<ValorantAgent | 'Unknown', string> = {
  Unknown: '',
  [ValorantAgent.Astra]: getAssetUrl(FileItemKey.agent_astra_full),
  [ValorantAgent.Breach]: getAssetUrl(FileItemKey.agent_breach_full),
  [ValorantAgent.Brimstone]: getAssetUrl(FileItemKey.agent_brimstone_full),
  [ValorantAgent.Cypher]: getAssetUrl(FileItemKey.agent_cypher_full),
  [ValorantAgent.Jett]: getAssetUrl(FileItemKey.agent_jett_full),
  [ValorantAgent.KAYO]: getAssetUrl(FileItemKey.agent_kayo_full),
  [ValorantAgent.Killjoy]: getAssetUrl(FileItemKey.agent_killjoy_full),
  [ValorantAgent.Omen]: getAssetUrl(FileItemKey.agent_omen_full),
  [ValorantAgent.Phoenix]: getAssetUrl(FileItemKey.agent_pheonix_full),
  [ValorantAgent.Raze]: getAssetUrl(FileItemKey.agent_raze_full),
  [ValorantAgent.Reyna]: getAssetUrl(FileItemKey.agent_reyna_full),
  [ValorantAgent.Sage]: getAssetUrl(FileItemKey.agent_sage_full),
  [ValorantAgent.Skye]: getAssetUrl(FileItemKey.agent_skye_full),
  [ValorantAgent.Sova]: getAssetUrl(FileItemKey.agent_sova_full),
  [ValorantAgent.Viper]: getAssetUrl(FileItemKey.agent_viper_full),
  [ValorantAgent.Yoru]: getAssetUrl(FileItemKey.agent_yoru_full),
  [ValorantAgent.Deadlock]: getAssetUrl(FileItemKey.agent_deadlock_full),
  [ValorantAgent.Neon]: getAssetUrl(FileItemKey.agent_neon_full),
  [ValorantAgent.Fade]: getAssetUrl(FileItemKey.agent_fade_full),
  [ValorantAgent.Harbor]: getAssetUrl(FileItemKey.agent_harbor_full),
  [ValorantAgent.Chamber]: getAssetUrl(FileItemKey.agent_chamber_full),
  [ValorantAgent.Gekko]: getAssetUrl(FileItemKey.agent_gekko_full),
  [ValorantAgent.Iso]: getAssetUrl(FileItemKey.agent_iso_full),
  [ValorantAgent.Clove]: getAssetUrl(FileItemKey.agent_clove_full),
  [ValorantAgent.Vyse]: getAssetUrl(FileItemKey.agent_vyse_full),
};

export const TierToImageUrlMap: Record<GuardianTier, string> = {
  [GuardianTier.guardian]: getAssetUrl(FileItemKey.tier_guardian),
  [GuardianTier.keeper]: getAssetUrl(FileItemKey.tier_keeper),
  [GuardianTier.scout]: getAssetUrl(FileItemKey.tier_scout),
  [GuardianTier.watcher]: getAssetUrl(FileItemKey.tier_watcher),
};

export const AgentToUltImageUrlMap: Record<ValorantAgent | 'Unknown', string> = {
  [ValorantAgent.Astra]: getAssetUrl(FileItemKey.ult_astra),
  [ValorantAgent.Breach]: getAssetUrl(FileItemKey.ult_breach),
  [ValorantAgent.Brimstone]: getAssetUrl(FileItemKey.ult_brimstone),
  [ValorantAgent.Cypher]: getAssetUrl(FileItemKey.ult_cypher),
  [ValorantAgent.Jett]: getAssetUrl(FileItemKey.ult_jett),
  [ValorantAgent.KAYO]: getAssetUrl(FileItemKey.ult_kayo),
  [ValorantAgent.Killjoy]: getAssetUrl(FileItemKey.ult_killjoy),
  [ValorantAgent.Omen]: getAssetUrl(FileItemKey.ult_omen),
  [ValorantAgent.Phoenix]: getAssetUrl(FileItemKey.ult_pheonix),
  [ValorantAgent.Raze]: getAssetUrl(FileItemKey.ult_raze),
  [ValorantAgent.Reyna]: getAssetUrl(FileItemKey.ult_reyna),
  [ValorantAgent.Sage]: getAssetUrl(FileItemKey.ult_sage),
  [ValorantAgent.Skye]: getAssetUrl(FileItemKey.ult_skye),
  [ValorantAgent.Sova]: getAssetUrl(FileItemKey.ult_sova),
  [ValorantAgent.Viper]: getAssetUrl(FileItemKey.ult_viper),
  [ValorantAgent.Yoru]: getAssetUrl(FileItemKey.ult_yoru),
  [ValorantAgent.Gekko]: getAssetUrl(FileItemKey.ult_gekko),
  [ValorantAgent.Chamber]: getAssetUrl(FileItemKey.ult_chamber),
  [ValorantAgent.Deadlock]: getAssetUrl(FileItemKey.ult_deadlock),
  [ValorantAgent.Fade]: getAssetUrl(FileItemKey.ult_fade),
  [ValorantAgent.Harbor]: getAssetUrl(FileItemKey.ult_harbor),
  [ValorantAgent.Neon]: getAssetUrl(FileItemKey.ult_neon),
  [ValorantAgent.Iso]: getAssetUrl(FileItemKey.ult_iso),
  [ValorantAgent.Clove]: getAssetUrl(FileItemKey.ult_clove),
  [ValorantAgent.Vyse]: getAssetUrl(FileItemKey.ult_vyse),
  Unknown: '',
};

export const AgentToImageUrlMap: Record<ValorantAgent, string> = {
  [ValorantAgent.Astra]: getAssetUrl(FileItemKey.agent_astra_face),
  [ValorantAgent.Breach]: getAssetUrl(FileItemKey.agent_breach_face),
  [ValorantAgent.Brimstone]: getAssetUrl(FileItemKey.agent_brimstone_face),
  [ValorantAgent.Cypher]: getAssetUrl(FileItemKey.agent_cypher_face),
  [ValorantAgent.Jett]: getAssetUrl(FileItemKey.agent_jett_face),
  [ValorantAgent.KAYO]: getAssetUrl(FileItemKey.agent_kayo_face),
  [ValorantAgent.Killjoy]: getAssetUrl(FileItemKey.agent_killjoy_face),
  [ValorantAgent.Omen]: getAssetUrl(FileItemKey.agent_omen_face),
  [ValorantAgent.Phoenix]: getAssetUrl(FileItemKey.agent_pheonix_face),
  [ValorantAgent.Raze]: getAssetUrl(FileItemKey.agent_raze_face),
  [ValorantAgent.Reyna]: getAssetUrl(FileItemKey.agent_reyna_face),
  [ValorantAgent.Sage]: getAssetUrl(FileItemKey.agent_sage_face),
  [ValorantAgent.Skye]: getAssetUrl(FileItemKey.agent_skye_face),
  [ValorantAgent.Sova]: getAssetUrl(FileItemKey.agent_sova_face),
  [ValorantAgent.Viper]: getAssetUrl(FileItemKey.agent_viper_face),
  [ValorantAgent.Yoru]: getAssetUrl(FileItemKey.agent_yoru_face),
  [ValorantAgent.Deadlock]: getAssetUrl(FileItemKey.agent_deadlock_face),
  [ValorantAgent.Neon]: getAssetUrl(FileItemKey.agent_neon_face),
  [ValorantAgent.Fade]: getAssetUrl(FileItemKey.agent_fade_face),
  [ValorantAgent.Harbor]: getAssetUrl(FileItemKey.agent_harbor_face),
  [ValorantAgent.Chamber]: getAssetUrl(FileItemKey.agent_chamber_face),
  [ValorantAgent.Gekko]: getAssetUrl(FileItemKey.agent_gekko_face),
  [ValorantAgent.Iso]: getAssetUrl(FileItemKey.agent_iso_face),
  [ValorantAgent.Clove]: getAssetUrl(FileItemKey.agent_clove_face),
  [ValorantAgent.Vyse]: getAssetUrl(FileItemKey.agent_vyse_face),
};

export const RankToImageUrlMap: Record<string, string> = {
  [Ranks.unranked]: getAssetUrl(FileItemKey.rank_unranked),
  [Ranks.iron1]: getAssetUrl(FileItemKey.rank_iron1),
  [Ranks.iron2]: getAssetUrl(FileItemKey.rank_iron2),
  [Ranks.iron3]: getAssetUrl(FileItemKey.rank_iron3),
  [Ranks.bronze1]: getAssetUrl(FileItemKey.rank_bronze1),
  [Ranks.bronze2]: getAssetUrl(FileItemKey.rank_bronze2),
  [Ranks.bronze3]: getAssetUrl(FileItemKey.rank_bronze3),
  [Ranks.silver1]: getAssetUrl(FileItemKey.rank_silver1),
  [Ranks.silver2]: getAssetUrl(FileItemKey.rank_silver2),
  [Ranks.silver3]: getAssetUrl(FileItemKey.rank_silver3),
  [Ranks.gold1]: getAssetUrl(FileItemKey.rank_gold1),
  [Ranks.gold2]: getAssetUrl(FileItemKey.rank_gold2),
  [Ranks.gold3]: getAssetUrl(FileItemKey.rank_gold3),
  [Ranks.platinum1]: getAssetUrl(FileItemKey.rank_platinum1),
  [Ranks.platinum2]: getAssetUrl(FileItemKey.rank_platinum2),
  [Ranks.platinum3]: getAssetUrl(FileItemKey.rank_platinum3),
  [Ranks.diamond1]: getAssetUrl(FileItemKey.rank_diamond1),
  [Ranks.diamond2]: getAssetUrl(FileItemKey.rank_diamond2),
  [Ranks.diamond3]: getAssetUrl(FileItemKey.rank_diamond3),
  [Ranks.ascendant1]: getAssetUrl(FileItemKey.rank_asc1),
  [Ranks.ascendant2]: getAssetUrl(FileItemKey.rank_asc2),
  [Ranks.ascendant3]: getAssetUrl(FileItemKey.rank_asc3),
  [Ranks.immortal1]: getAssetUrl(FileItemKey.rank_immortal1),
  [Ranks.immortal2]: getAssetUrl(FileItemKey.rank_immortal2),
  [Ranks.immortal3]: getAssetUrl(FileItemKey.rank_immortal3),
  [Ranks.radiant]: getAssetUrl(FileItemKey.rank_radiant),
};

export const WeaponToUrl: Record<Weapons, string> = {
  [Weapons.ares]: `${CloudFrontProduction}/images/weapons/ares.png`,
  [Weapons.bucky]: `${CloudFrontProduction}/images/weapons/bucky.png`,
  [Weapons.classic]: `${CloudFrontProduction}/images/weapons/classic.png`,
  [Weapons.frenzy]: `${CloudFrontProduction}/images/weapons/frenzy.png`,
  [Weapons.ghost]: `${CloudFrontProduction}/images/weapons/ghost.png`,
  [Weapons.guardian]: `${CloudFrontProduction}/images/weapons/guardian.png`,
  [Weapons.judge]: `${CloudFrontProduction}/images/weapons/judge.png`,
  [Weapons.marshal]: `${CloudFrontProduction}/images/weapons/marshal.png`,
  [Weapons.odin]: `${CloudFrontProduction}/images/weapons/odin.png`,
  [Weapons.phantom]: `${CloudFrontProduction}/images/weapons/phantom.png`,
  [Weapons.sheriff]: `${CloudFrontProduction}/images/weapons/sheriff.png`,
  [Weapons.stinger]: `${CloudFrontProduction}/images/weapons/stinger.png`,
  [Weapons.spectre]: `${CloudFrontProduction}/images/weapons/spectre.png`,
  [Weapons.vandal]: `${CloudFrontProduction}/images/weapons/vandal.png`,
  [Weapons.shorty]: `${CloudFrontProduction}/images/weapons/shorty.png`,
  [Weapons.fallDamage]: '',
  [Weapons.operator]: `${CloudFrontProduction}/images/weapons/operator.png`,
  [Weapons.bulldog]: `${CloudFrontProduction}/images/weapons/bulldog.png`,
  [Weapons.knife]: `${CloudFrontProduction}/images/weapons/knife.png`,
  [Weapons.outlaw]: `${CloudFrontProduction}/images/weapons/outlaw.png`,
};
