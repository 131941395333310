import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="#f34658"
    baseProfile="tiny"
    viewBox="-63 65 128 128"
    {...props}
  >
    <path d="M22.3 84.4c.9-2 3.4-3.9 6.8-5.4v-7.9l4 6.5c1.4-.4 2.8-.8 4.3-1v-8.1l4.6 7.4c.8-.1 1.7-.2 2.5-.2v-7.2l4.5 7.1c4.1.1 8.1.7 11.9 1.8-1.8-5.6-11.4-10.8-13.1-11.1-16.8-5.1-32.3 9.4-32.6 13.3-5.6-15.8-31.4-14.8-43.5-.9 9.4-2.5 35.9-1.3 37.7 7.3-5.7-1.3-28.4-10.9-35.5 21.7C-10.3 91.3 4.5 88.9 7.3 90.9c-18.4 6.5-12 24.5-12 24.5S-.5 101.2 9.4 99l1.3.1c-7.6 29.4-12.7 52.4-1 88.5h-30.5c.8-18.2-5.8-34-13.7-47.5l-.8-1.1c9.1-3.6 14.8 8.2 14.8 8.2 3.7-17.8-15.1-12.2-15.1-12.2.3-1.1 1-2.1 2.1-3.1v-6.2l3.1 3.9c.7-.4 1.5-.8 2.2-1.2v-4.1l2.3 3c2.3-1 4.3-1.6 4.8-1.6-4.2-6.8-17.8-1.6-18.1 6.8-5.1-9.4-16.3-7-18.8.5 4.7-1.9 11.9-1.2 14.5 1.7-4.2.3-17.8 4.9-16.3 14 .6-.7 2.7-2.2 5.3-3.7v-4.3l1.5 3.5c.9-.5 1.9-1.1 2.8-1.6V139l1.2 2.9c3.1-1.7 5.7-2.9 6-2.4-3.5 4.6-3.8 11.4-.8 16.4.1-2.5 2.5-12.3 4.3-14l.5-.2c7 13.3 12.2 27.7 10.9 46h-24.5v5.4H45v-5.4H24.6c-15.1-31.9-11-57.9-4.8-88.1l1.2.1c4.2 2.3 12.5 15.8 14 20.2 3-10.6-.2-20-9-26.8 1.3-.7 4.2-.7 7.8-.1l1.2-6.6 3.4 7.4h-.1c1.4.3 2.7.6 4.1 1l2.1-8.5 2.3 9.7c1.1.3 2.1.6 3.1.9l2-7.9 2.2 9.2c3.2 1.1 5.8 2.2 6.9 2.9-.7-8-11-27-38.7-16.7z" />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
