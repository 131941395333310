import { z } from 'zod';

export const ZMessageArgs = z.object({
  message: z.string(),
  extraData: z.any().optional(),
});

export type IMessageArgs = z.infer<typeof ZMessageArgs>;

export const ZMessageRequest = z.object({
  body: ZMessageArgs,
});
