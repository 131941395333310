import { z } from 'zod';
import { UserAppState } from '../../../enums';

export const ZUserState = z.object({
  userId: z.string(),
  guardBits: z.number(),
  appState: z.nativeEnum(UserAppState),
});

export type IUserState = z.infer<typeof ZUserState>;
