import { SVGProps, memo } from 'react';
import { colorPalette } from '../../../style';
import { heartIconCss, pathClass } from './heart-icon.css';
import clsx from 'clsx';
const SvgComponent = ({ isFilled, className, ...props }: SVGProps<SVGSVGElement> & { isFilled: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
    className={clsx(className, heartIconCss)}
  >
    <path
      className={pathClass}
      stroke={colorPalette.red1}
      fill={isFilled ? colorPalette.red1 : 'none'}
      d="M2 9.137C2 14 6.02 16.591 8.962 18.911 10 19.729 11 20.5 12 20.5s2-.77 3.038-1.59C17.981 16.592 22 14 22 9.138c0-4.863-5.5-8.312-10-3.636C7.5.825 2 4.274 2 9.137Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
