import { CharacterMood, GuardianTier, MatchAnalysisTitle, MatchPlayerAnalysisTitle } from './enums';
import { AgentAbilities, ShieldTiers, ValorantAgent, Weapons } from './types';

export const XMatchesMin = 5;
export const XMatchesMax = 25;

export const IdentityFileNamePrefix = 'guardian_identity';
export const getIdentityFileName = (userId: string) => `${IdentityFileNamePrefix}_${userId}.json`;

export const defaultGuardianScore = 250;
export const guardianScoreTierDelta = 80;
export const scoutScoreMax = 264;
export const watcherScoreMax = scoutScoreMax + guardianScoreTierDelta;
export const keeperScoreMax = watcherScoreMax + guardianScoreTierDelta;
export const tierScores = {
  [GuardianTier.scout]: {
    top: scoutScoreMax,
  },
  [GuardianTier.watcher]: {
    bottom: scoutScoreMax + 1,
    top: watcherScoreMax,
  },
  [GuardianTier.keeper]: {
    bottom: watcherScoreMax + 1,
    top: keeperScoreMax,
  },
  [GuardianTier.guardian]: {
    bottom: keeperScoreMax + 1,
  },
};

export const EngagementConsts = {
  level1Time: 2000,
  level2Time: 4000,
};

export const defaultPageSize = 15;
export const defaultPage = 0;

export const appVersionHeader = 'X-App-Version';
export const overwolfVersionHeader = 'X-Overwolf-Version';

export type IValorantConsts = {
  controllerAgents: ValorantAgent[];
  initiatorAgents: ValorantAgent[];
  duelistAgents: ValorantAgent[];
  sentinelAgents: ValorantAgent[];
  economy: {
    lossStreakCountToCash: number[];
    survivedLossCash: number;
    weaponCosts: Record<Weapons, number>;
    shieldCosts: Record<ShieldTiers, number>;
  };
};

export const ValorantConsts = {
  controllerAgents: [
    ValorantAgent.Brimstone,
    ValorantAgent.Omen,
    ValorantAgent.Viper,
    ValorantAgent.Harbor,
    ValorantAgent.Clove,
    ValorantAgent.Astra,
  ],
  initiatorAgents: [
    ValorantAgent.Breach,
    ValorantAgent.Sova,
    ValorantAgent.Skye,
    ValorantAgent.KAYO,
    ValorantAgent.Fade,
    ValorantAgent.Gekko,
  ],
  duelistAgents: [
    ValorantAgent.Phoenix,
    ValorantAgent.Jett,
    ValorantAgent.Raze,
    ValorantAgent.Yoru,
    ValorantAgent.Iso,
    ValorantAgent.Neon,
    ValorantAgent.Reyna,
  ],
  sentinelAgents: [
    ValorantAgent.Cypher,
    ValorantAgent.Sage,
    ValorantAgent.Killjoy,
    ValorantAgent.Chamber,
    ValorantAgent.Deadlock,
  ],
  economy: {
    lossStreakCountToCash: [1900, 2400, 2900],
    survivedLossCash: 1000,
    weaponCosts: {
      [Weapons.vandal]: 2900,
      [Weapons.phantom]: 2900,
      [Weapons.stinger]: 1100,
      [Weapons.sheriff]: 800,
      [Weapons.classic]: 0,
      [Weapons.shorty]: 300,
      [Weapons.frenzy]: 450,
      [Weapons.ghost]: 500,
      [Weapons.bucky]: 850,
      [Weapons.judge]: 1850,
      [Weapons.bulldog]: 2050,
      [Weapons.guardian]: 2250,
      [Weapons.spectre]: 1600,
      [Weapons.ares]: 1600,
      [Weapons.odin]: 3200,
      [Weapons.marshal]: 950,
      [Weapons.operator]: 4700,
      [Weapons.outlaw]: 2400,
      [Weapons.fallDamage]: 0,
    },
    shieldCosts: {
      [ShieldTiers.none]: 0,
      [ShieldTiers.unknown]: 0,
      [ShieldTiers.light]: 400,
      [ShieldTiers.heavy]: 1000,
    },
    agentFullAbilityCost: {
      [ValorantAgent.Astra]: 450,
      [ValorantAgent.Breach]: 700,
      [ValorantAgent.Brimstone]: 650,
      [ValorantAgent.Chamber]: 1000,
      [ValorantAgent.Clove]: 600,
      [ValorantAgent.Cypher]: 600,
      [ValorantAgent.Deadlock]: 600,
      [ValorantAgent.Fade]: 700,
      [ValorantAgent.Gekko]: 550,
      [ValorantAgent.Harbor]: 650,
      [ValorantAgent.Iso]: 600,
      [ValorantAgent.Jett]: 550,
      [ValorantAgent.KAYO]: 700,
      [ValorantAgent.Killjoy]: 600,
      [ValorantAgent.Neon]: 650,
      [ValorantAgent.Omen]: 600,
      [ValorantAgent.Phoenix]: 650,
      [ValorantAgent.Raze]: 700,
      [ValorantAgent.Reyna]: 700,
      [ValorantAgent.Sage]: 800,
      [ValorantAgent.Skye]: 850,
      [ValorantAgent.Sova]: 700,
      [ValorantAgent.Viper]: 500,
      [ValorantAgent.Yoru]: 750,
      [ValorantAgent.Vyse]: 500,
    },
    agentAbilityCosts: [
      {
        agent: ValorantAgent.Vyse,
        abilities: [
          {
            ability: AgentAbilities.razorvine,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.shear,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Astra,
        abilities: [
          {
            ability: AgentAbilities.nebula,
            price: 150,
            initialLoadout: 1,
            maxLoadout: 4,
          },
        ],
      },
      {
        agent: ValorantAgent.Breach,
        abilities: [
          {
            ability: AgentAbilities.afterShock,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.flashpoint,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Brimstone,
        abilities: [
          {
            ability: AgentAbilities.incendiary,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.stimBeacon,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.skySmoke,
            price: 100,
            initialLoadout: 1,
            maxLoadout: 3,
          },
        ],
      },
      {
        agent: ValorantAgent.Chamber,
        abilities: [
          {
            ability: AgentAbilities.trademark,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.headHunter,
            price: 100,
            initialLoadout: 0,
            maxLoadout: 8,
          },
        ],
      },
      {
        agent: ValorantAgent.Clove,
        abilities: [
          {
            ability: AgentAbilities.pickMeUp,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.meddle,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.ruse,
            price: 150,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Cypher,
        abilities: [
          {
            ability: AgentAbilities.trapWire,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.cyberCage,
            price: 100,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Deadlock,
        abilities: [
          {
            ability: AgentAbilities.gravnet,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.sonicSensor,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Fade,
        abilities: [
          {
            ability: AgentAbilities.prowler,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.seize,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Gekko,
        abilities: [
          {
            ability: AgentAbilities.moshPit,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.wingman,
            price: 300,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Harbor,
        abilities: [
          {
            ability: AgentAbilities.cascade,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.cove,
            price: 350,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Iso,
        abilities: [
          {
            ability: AgentAbilities.contingency,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.undercut,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Jett,
        abilities: [
          {
            ability: AgentAbilities.cloudBurst,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.updraft,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.KAYO,
        abilities: [
          {
            ability: AgentAbilities.fragment,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.flashdrive,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Killjoy,
        abilities: [
          {
            ability: AgentAbilities.nanoswarm,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.alarmbot,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Neon,
        abilities: [
          {
            ability: AgentAbilities.fastLane,
            price: 300,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.relayBolt,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.highGear,
            price: 150,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Omen,
        abilities: [
          {
            ability: AgentAbilities.shroudedStep,
            price: 100,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.paranoia,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.darkCover,
            price: 150,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Phoenix,
        abilities: [
          {
            ability: AgentAbilities.blaze,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.curveball,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Raze,
        abilities: [
          {
            ability: AgentAbilities.boombot,
            price: 300,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.blastPack,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Reyna,
        abilities: [
          {
            ability: AgentAbilities.leer,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.devour,
            price: 200,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Sage,
        abilities: [
          {
            ability: AgentAbilities.barrierOrb,
            price: 400,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.slowOrb,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Skye,
        abilities: [
          {
            ability: AgentAbilities.regrowth,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.trailBlazer,
            price: 300,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.guidingLight,
            price: 250,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Sova,
        abilities: [
          {
            ability: AgentAbilities.owlDrone,
            price: 400,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.shockBolt,
            price: 150,
            initialLoadout: 0,
            maxLoadout: 2,
          },
        ],
      },
      {
        agent: ValorantAgent.Viper,
        abilities: [
          {
            ability: AgentAbilities.snakeBite,
            price: 300,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.poisonCloud,
            price: 200,
            initialLoadout: 0,
            maxLoadout: 1,
          },
        ],
      },
      {
        agent: ValorantAgent.Yoru,
        abilities: [
          {
            ability: AgentAbilities.fakeout,
            price: 100,
            initialLoadout: 0,
            maxLoadout: 1,
          },
          {
            ability: AgentAbilities.blindside,
            price: 250,
            initialLoadout: 0,
            maxLoadout: 2,
          },
          {
            ability: AgentAbilities.gatecrash,
            price: 150,
            initialLoadout: 1,
            maxLoadout: 2,
          },
        ],
      },
    ],
  },
};

export const CloudFrontDomainName = 'https://d1xwb8utkibiyv.cloudfront.net';

export const CloudFrontProduction = `${CloudFrontDomainName}/production`;

export const CharacterConsts = {
  moodToVideo: {
    [CharacterMood.angry]: 'https://d1xwb8utkibiyv.cloudfront.net/production/videos/angry.webm',
    [CharacterMood.default]: 'https://d1xwb8utkibiyv.cloudfront.net/production/videos/content.webm',
    [CharacterMood.encouraging]: 'https://d1xwb8utkibiyv.cloudfront.net/production/videos/encouraging.webm',
    [CharacterMood.hyped]: 'https://d1xwb8utkibiyv.cloudfront.net/production/videos/hyped.webm',
    [CharacterMood.tired]: 'https://d1xwb8utkibiyv.cloudfront.net/production/videos/tired.webm',
  },
  default: {
    head: {
      angry: `${CloudFrontProduction}/images/character/default/head/angry.webp`,
      default: `${CloudFrontProduction}/images/character/default/head/default.webp`,
      encouraging: `${CloudFrontProduction}/images/character/default/head/encouraging.webp`,
      hyped: `${CloudFrontProduction}/images/character/default/head/hyped.webp`,
      tired: `${CloudFrontProduction}/images/character/default/head/tired.webp`,
    },
    idleAnimations: {
      default: {
        path: `${CloudFrontProduction}/images/character/default/idle/default`,
        frames: 36,
      },
      angry: {
        path: `${CloudFrontProduction}/images/character/default/idle/angry`,
        frames: 36,
      },
      encouraging: {
        path: `${CloudFrontProduction}/images/character/default/idle/encouraging`,
        frames: 36,
      },
      hyped: {
        path: `${CloudFrontProduction}/images/character/default/idle/hyped`,
        frames: 36,
      },
      tired: {
        path: `${CloudFrontProduction}/images/character/default/idle/tired`,
        frames: 36,
      },
    },
  },
};

export const MatchConsts: {
  matchAnalysisTitleToBodyText: Record<MatchAnalysisTitle, string>;
  matchAnalysisTitleToTitleText: Record<MatchAnalysisTitle, string>;
  playerAnalysisTitleToTitleText: Record<MatchPlayerAnalysisTitle, string>;
  playerAnalysisTitleToBodyText: Record<MatchPlayerAnalysisTitle, string>;
} = {
  matchAnalysisTitleToTitleText: {
    [MatchAnalysisTitle.closeMatch]: 'Close Match',
    [MatchAnalysisTitle.roughMatch]: 'Rough Match',
    [MatchAnalysisTitle.stompMatch]: 'Stomp',
    [MatchAnalysisTitle.throwMatch]: 'Throw',
    [MatchAnalysisTitle.comebackMatch]: 'Comeback',
    [MatchAnalysisTitle.unfairMatchSmurf]: 'Unfair Match',
    [MatchAnalysisTitle.unfairMatchTeammateLeft]: 'Unfair Match',
    [MatchAnalysisTitle.nineThreeCurseLoss]: '9-3 Curse',
    [MatchAnalysisTitle.nineThreeCurseWin]: '9-3 Curse',
    [MatchAnalysisTitle.flawlessMatch]: 'Flawless',
    [MatchAnalysisTitle.clutchMatch]: 'Clutch',
    [MatchAnalysisTitle.thrillerMatch]: 'Thriller',
    [MatchAnalysisTitle.quickyMatch]: 'Quickie',
  },
  matchAnalysisTitleToBodyText: {
    [MatchAnalysisTitle.closeMatch]: 'It was anybody’s game, both teams could have won.',
    [MatchAnalysisTitle.roughMatch]: 'This was a tough one, the other team was much better.',
    [MatchAnalysisTitle.stompMatch]: 'The other team had no chance, you smoked them.',
    [MatchAnalysisTitle.throwMatch]: 'The match was yours but the team threw.',
    [MatchAnalysisTitle.comebackMatch]: 'The game seemed lost, but you made a huge comeback.',
    [MatchAnalysisTitle.unfairMatchSmurf]: 'The enemy team had a smurf that took over the game. Unlucky.',
    [MatchAnalysisTitle.unfairMatchTeammateLeft]: 'Someone in your team left the match. Nothing you could do about it.',
    [MatchAnalysisTitle.nineThreeCurseLoss]: 'The 9-3 curse struck again, you lost.',
    [MatchAnalysisTitle.nineThreeCurseWin]: 'The 9-3 curse got you the win.',
    [MatchAnalysisTitle.flawlessMatch]: 'You didn’t lose a single round, flawless victory.',
    [MatchAnalysisTitle.clutchMatch]: 'Your team had some clutch moments that won you the game.',
    [MatchAnalysisTitle.thrillerMatch]: 'The match was intense, it could have gone either way.',
    [MatchAnalysisTitle.quickyMatch]: 'The match was over in no time.',
  },
  playerAnalysisTitleToTitleText: {
    [MatchPlayerAnalysisTitle.carry]: 'Carry',
    [MatchPlayerAnalysisTitle.choker]: 'Choker',
    [MatchPlayerAnalysisTitle.clutch]: 'Clutch',
    [MatchPlayerAnalysisTitle.leaver]: 'leaver',
    [MatchPlayerAnalysisTitle.smurf]: 'Smurf',
    [MatchPlayerAnalysisTitle.thrower]: 'Thrower',
  },
  playerAnalysisTitleToBodyText: {
    [MatchPlayerAnalysisTitle.carry]: 'This players performance was outstanding. He carried the team.',
    [MatchPlayerAnalysisTitle.choker]: 'This player underpreformed. ',
    [MatchPlayerAnalysisTitle.clutch]: 'This player had some clutch moments.',
    [MatchPlayerAnalysisTitle.leaver]: 'This player left the match.',
    [MatchPlayerAnalysisTitle.smurf]: 'I suspect this player was a smurf.',
    [MatchPlayerAnalysisTitle.thrower]: 'This player threw the match. He was so much behind the rest of the team.',
  },
};
