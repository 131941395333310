import { z } from 'zod';
import { TimeFrame } from '../../../enums';

export const ZGetWidgetStoreItemsArgs = z.object({
  page: z.number().optional(),
  pageSize: z.number().optional(),
  isOwned: z.boolean().optional(),
  filterText: z.string().optional(),
  timeFrame: z.nativeEnum(TimeFrame).optional(),
});

export type IGetWidgetStoreItemsArgs = z.infer<typeof ZGetWidgetStoreItemsArgs>;

export const ZGetWidgetStoreItemsRequest = z.object({
  body: ZGetWidgetStoreItemsArgs,
});
