import { z } from 'zod';
import { WidgetType } from '../../../enums';

export const ZBuyWidgetArgs = z.object({
  widgetType: z.nativeEnum(WidgetType),
});

export type IBuyWidgetArgs = z.infer<typeof ZBuyWidgetArgs>;

export const ZBuyWidgetRequest = z.object({
  body: ZBuyWidgetArgs,
});

export const ZBuyWidgetResponse = z.object({
  success: z.boolean(),
});

export type IBuyWidgetResponse = z.infer<typeof ZBuyWidgetResponse>;
