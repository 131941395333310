import { z } from 'zod';
import { GuardianBehaviourType } from '../../../enums';

export const ZBehaviourPercentile = z.object({
  behaviour: z.nativeEnum(GuardianBehaviourType),
  percentile: z.coerce.number(),
});

export type IBehaviourPercentile = z.infer<typeof ZBehaviourPercentile>;

export const ZBehaviourPercentileValue = ZBehaviourPercentile.extend({
  value: z.coerce.number(),
});

export type IBehaviourPercentileValue = z.infer<typeof ZBehaviourPercentileValue>;
