import { z } from 'zod';
import { TimeFrame, WidgetType } from '../../../enums';

export const ZGetWidgetArgsBase = z.object({
  widgetType: z.nativeEnum(WidgetType),
});

export const ZGetWidgetForMatchArgs = ZGetWidgetArgsBase.extend({
  timeFrame: z.literal(TimeFrame.match),
  matchId: z.string(),
});

export type IGetWidgetForMatchArgs = z.infer<typeof ZGetWidgetForMatchArgs>;

export const ZGetWidgetForPastXMatchesArgs = ZGetWidgetArgsBase.extend({
  timeFrame: z.literal(TimeFrame.pastXMatches),
  pastXMatches: z.number().optional(),
});

export type IGetWidgetForPastXMatchesArgs = z.infer<typeof ZGetWidgetForPastXMatchesArgs>;

export const ZGetUserWidgetArgs = z.union([ZGetWidgetForMatchArgs, ZGetWidgetForPastXMatchesArgs]);
export type IGetUserWidgetArgs = z.infer<typeof ZGetUserWidgetArgs>;

export const ZGetUserWidgetRequest = z.object({
  body: ZGetUserWidgetArgs,
});

export type IGetUserWidgetExtra = Omit<IGetWidgetForPastXMatchesArgs, 'widgetType'> | Omit<IGetWidgetForMatchArgs, 'widgetType'>;
