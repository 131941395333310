import { z } from 'zod';
import { ZUserShowcase } from '../entities';

export const ZGetUserShowcaseParams = z.object({
  userId: z.string(),
});

export const ZGetUserShowcaseRequest = z.object({
  params: ZGetUserShowcaseParams,
});

export const ZGetUserShowcaseResponse = z.union([
  z.object({
    success: z.literal(false),
  }),
  z.object({
    success: z.literal(true),
    data: ZUserShowcase,
  }),
]);

export type IGetUserShowcaseResponse = z.infer<typeof ZGetUserShowcaseResponse>;
