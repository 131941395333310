import { z } from 'zod';

export enum UserGroup {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum UserOnboardingStatus {
  oldUser = 'oldUser',
  notStarted = 'notStarted',
  started = 'started',
  completed = 'completed',
  skipped = 'skipped',
}

export enum OnboardingSequenceType {
  main = 'main',
  whatMore = 'whatMore',
  complete = 'complete',
  behaviours = 'behaviours',
}
