import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#f34658"
    stroke="#f34658"
    viewBox="0 0 512 512"
    strokeWidth={1.536}
    aria-hidden="true"
    className="iconify iconify--fxemoji"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#FBC935"
      d="M210.3 65.5c28.8 7.3 51.4 29.9 58.7 58.7.7 2.8 4.3 2.8 5 0 7.3-28.8 29.9-51.4 58.7-58.7 2.8-.7 2.8-4.3 0-5-28.8-7.3-51.4-29.9-58.7-58.7-.7-2.8-4.3-2.8-5 0-7.3 28.8-29.9 51.4-58.7 58.7-2.8.7-2.8 4.3 0 5z"
    />
    <path
      fill="#FCD667"
      d="M6.7 188.3c50.8 12.9 90.8 52.9 103.7 103.7 1.2 4.9 7.5 4.9 8.8 0 12.9-50.8 52.9-90.8 103.7-103.7 4.9-1.2 4.9-7.5 0-8.8C172 166.7 132 126.7 119.2 75.9c-1.2-4.9-7.5-4.9-8.8 0-12.9 50.8-52.9 90.8-103.7 103.7-4.9 1.2-4.9 7.5 0 8.7z"
    />
    <path
      fill="#FBBC04"
      d="M180 350.7c76 19.3 135.9 79.1 155.1 155.1 1.9 7.3 11.3 7.3 13.1 0 19.3-76 79.1-135.9 155.1-155.1 7.3-1.9 7.3-11.3 0-13.1-76-19.3-135.9-79.1-155.1-155.1-1.9-7.3-11.3-7.3-13.1 0-19.3 76-79.1 135.9-155.1 155.1-7.3 1.8-7.3 11.2 0 13.1z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
