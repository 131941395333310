import { z } from 'zod';
import { BroadcastType, SocketInvalidationType } from '../../enums';

export const ZMandatoryVersionUpdateBroadcastData = z.object({
  version: z.string(),
});

export type IMandatoryVersionUpdateBroadcastData = z.infer<typeof ZMandatoryVersionUpdateBroadcastData>;

export const ZInvalidateDataBroadcastData = z.object({
  invalidationTypes: z.nativeEnum(SocketInvalidationType).array(),
  timeRange: z
    .object({
      min: z.number(),
      max: z.number(),
    })
    .optional()
    .default({
      min: 500,
      max: 2500,
    }),
});

export type IInvalidateDataBroadcastData = z.infer<typeof ZInvalidateDataBroadcastData>;

export const ZBroadcastRoomArgs = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(BroadcastType.mandatoryVersionUpdate),
    data: ZMandatoryVersionUpdateBroadcastData,
  }),
  z.object({
    type: z.literal(BroadcastType.invalidateData),
    data: ZInvalidateDataBroadcastData,
  }),
]);

export const ZBroadcastRoomRequest = z.object({
  body: ZBroadcastRoomArgs,
});

export type IBroadcastRoomArgs = z.infer<typeof ZBroadcastRoomArgs>;
