import { z } from 'zod';
import { ZUserSettings } from '../entities';

export const ZChangeUserSettingsArgs = ZUserSettings.omit({
  userId: true,
}).partial();

export type IChangeUserSettingsArgs = z.infer<typeof ZChangeUserSettingsArgs>;

export const ZChangeUserSettingsRequest = z.object({
  body: ZChangeUserSettingsArgs,
});
