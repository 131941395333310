import { z } from 'zod';
import { ZUserPattern } from '../entities';

export const ZLogUserPatternArgs = ZUserPattern.pick({
  type: true,
  isMatched: true,
});

export type ILogUserPatternArgs = z.infer<typeof ZLogUserPatternArgs>;

export const ZLogUserPatternRequest = z.object({
  body: ZLogUserPatternArgs,
});

export type ILogUserPatternRequest = z.infer<typeof ZLogUserPatternRequest>;
