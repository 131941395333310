import { z } from 'zod'
import { ZUserBase } from '../../user';

export const ZRegisterArgs = ZUserBase.pick({
    email: true,
    userName: true
}).extend({
    password: z.string().min(8).max(25)
})

export const ZRegisterRequest = z.object({
    body: ZRegisterArgs
});

export type IRegisterArgs = z.infer<typeof ZRegisterArgs>;

export const ZRegisterResponse = z.object({
    success: z.boolean(),
})

export type IRegisterResponse = z.infer<typeof ZRegisterResponse>;