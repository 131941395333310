import { IGetUserShowcaseResponse } from '@guardian/common';
import { useWebsiteApiQuery } from '../../hooks';
import { hasUserId } from './showcase.css';
import { useParams } from 'react-router-dom';

type ShowcaseProps = {
  userId: string;
};

export const ShowcaseInternal: React.FC<ShowcaseProps> = ({ userId }) => {
  const response = useWebsiteApiQuery<void, IGetUserShowcaseResponse>(`showcase/${userId}`);

  if (response.isLoading || !response.data) return <div className={hasUserId}>Loading...</div>;

  if (!response.data.success) {
    return <div className={hasUserId}>This user is not a verified Guardian user</div>;
  }

  return <div className={hasUserId}>Verified Guardian user {response.data.data.userName}</div>;
};

export const Showcase: React.FC = () => {
  const { userId } = useParams();

  if (!userId) return null;

  return <ShowcaseInternal userId={userId} />;
};
