import { z } from 'zod';
import { ZGuardianBehaviour } from '../../behaviour';
import { ZMatch, ZMatchPlayer, ZMatchRound } from '../entities';

export const ZGetMatchDetailsRequest = z.object({
  params: z.object({
    matchId: z.string(),
    isFromQuery: z.coerce.boolean().optional(),
  }),
});

export const ZMatchRoundView = ZMatchRound.extend({
  behaviours: ZGuardianBehaviour.array(),
});

export type IMatchRoundView = z.infer<typeof ZMatchRoundView>;

export const ZMatchDetails = ZMatch.extend({
  players: ZMatchPlayer.array(),
  rounds: ZMatchRoundView.array(),
});

export type IMatchDetails = z.infer<typeof ZMatchDetails>;
