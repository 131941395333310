import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="#8C8B8C"
    viewBox="0 0 511.998 511.998"
    {...props}
  >
    <path d="M442.471 76.017h-65.737V52.262C376.734 23.445 353.289 0 324.472 0H187.528c-28.818 0-52.262 23.445-52.262 52.262v23.754H69.529c-24.316 0-44.097 19.781-44.097 44.097v31.984c0 24.316 19.781 44.097 44.097 44.097h3.052l41.601 301.329c1.146 8.296 8.237 14.475 16.612 14.475h250.408c8.375 0 15.467-6.179 16.612-14.475l41.601-301.329h3.053c24.316 0 44.097-19.781 44.097-44.097v-31.984c.003-24.315-19.779-44.096-44.094-44.096zM168.805 52.262c0-10.324 8.399-18.724 18.724-18.724h136.943c10.324 0 18.724 8.399 18.724 18.724v23.754h-174.39V52.262zm197.786 426.199H145.408l-38.97-282.266h299.123l-38.97 282.266zm86.438-326.363c0 5.822-4.736 10.558-10.558 10.558H69.529c-5.822 0-10.558-4.736-10.558-10.558v-31.985c0-5.822 4.736-10.558 10.558-10.558h372.942c5.822 0 10.558 4.736 10.558 10.558v31.985z" />
    <path d="M255.137 230.524c-9.261 0-16.769 7.508-16.769 16.769v172.243c0 9.261 7.508 16.769 16.769 16.769s16.769-7.508 16.769-16.769V247.293c0-9.261-7.508-16.769-16.769-16.769zM209.266 417.146l-24.803-172.243c-1.32-9.167-9.813-15.534-18.987-14.208-9.167 1.32-15.528 9.821-14.208 18.987l24.803 172.243c1.321 9.176 9.831 15.528 18.987 14.208 9.167-1.32 15.528-9.821 14.208-18.987zM346.524 230.696c-9.152-1.32-17.668 5.042-18.987 14.208l-24.804 172.241c-1.319 9.166 5.041 17.668 14.208 18.987 9.157 1.32 17.667-5.035 18.987-14.208l24.804-172.241c1.321-9.166-5.04-17.668-14.208-18.987z" />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
