import { z } from 'zod';
import { MatchOutcomes, ValorantAgent } from '../../../types';

export const ZRecentMatch = z.object({
  agent: z.nativeEnum(ValorantAgent).or(z.literal('Unknown')),
  won: z.number(),
  lost: z.number(),
  outcome: z.nativeEnum(MatchOutcomes),
  score: z.number(),
});

export const ZAgentInsights = z.object({
  agent: z.nativeEnum(ValorantAgent).or(z.literal('Unknown')),
  averageScore: z.coerce.number(),
  maxScore: z.coerce.number(),
  matchesPlayed: z.coerce.number(),
  matchesWon: z.coerce.number(),
  matchesLost: z.coerce.number(),
  matchesDrawn: z.coerce.number(),
  qualityScore: z.coerce.number(),
  stdevScore: z.coerce.number(),
  lastPlayedOnAgent: z.coerce.date(),
});

export type IAgentInsights = z.infer<typeof ZAgentInsights>;

export const ZAgentInsightsWithMatches = ZAgentInsights.extend({
  recentMatches: ZRecentMatch.array(),
});

export type IAgentInsightsWithMatches = z.infer<typeof ZAgentInsightsWithMatches>;
export type IRecentMatch = z.infer<typeof ZRecentMatch>;
