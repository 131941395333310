export enum ValorantAgent {
  Phoenix = 'Phoenix',
  Raze = 'Clay',
  Breach = 'Breach',
  Brimstone = 'Sarge',
  Viper = 'Pandemic',
  Omen = 'Wraith',
  Sova = 'Hunter',
  Sage = 'Thorne',
  Jett = 'Wushu',
  Cypher = 'Gumshoe',
  Reyna = 'Vampire',
  Killjoy = 'Killjoy',
  KAYO = 'Grenadier',
  Yoru = 'Stealth',
  Skye = 'Guide',
  Astra = 'Rift',
  Chamber = 'Deadeye',
  Neon = 'Sprinter',
  Fade = 'BountyHunter',
  Harbor = 'Mage',
  Gekko = 'Aggrobot',
  Deadlock = 'Cable',
  Iso = 'Sequoia',
  Clove = 'Smonk',
  Vyse = 'Nox',
}

export const AssistAgentToValorantAgent: Record<string, ValorantAgent> = {
  ['TX_Killfeed_KAYO']: ValorantAgent.KAYO,
  ['TX_Killfeed_Pheonix']: ValorantAgent.Phoenix,
  ['TX_Killfeed_Neon']: ValorantAgent.Neon,
  ['TX_Killfeed_Killjoy1']: ValorantAgent.Killjoy,
  ['TX_Killfeed_Viper']: ValorantAgent.Viper,
  ['TX_Killfeed_Omen']: ValorantAgent.Omen,
  ['TX_Killfeed_Yoru']: ValorantAgent.Yoru,
  ['TX_Killfeed_Jett']: ValorantAgent.Jett,
  ['TX_Killfeed_Skye']: ValorantAgent.Skye,
  ['TX_Killfeed_Sage1']: ValorantAgent.Sage,
  ['TX_Killfeed_Sova1']: ValorantAgent.Sova,
  ['TX_Killfeed_Reyna']: ValorantAgent.Reyna,
  ['TX_Killfeed_Chamber']: ValorantAgent.Chamber,
  ['TX_Killfeed_Brimstone']: ValorantAgent.Brimstone,
  ['TX_Killfeed_Fade']: ValorantAgent.Fade,
  ['TX_Killfeed_Deadlock']: ValorantAgent.Deadlock,
  ['TX_Killfeed_Raze']: ValorantAgent.Raze,
  ['TX_Killfeed_Gekko']: ValorantAgent.Gekko,
  ['TX_Killfeed_Breach']: ValorantAgent.Breach,
  ['TX_Killfeed_Cypher']: ValorantAgent.Cypher,
  ['TX_Killfeed_Astra']: ValorantAgent.Astra,
  ['TX_Killfeed_Harbor']: ValorantAgent.Harbor,
  ['TX_Killfeed_Iso']: ValorantAgent.Iso,
  ['TX_Killfeed_Clove']: ValorantAgent.Clove,
  ['TX_Killfeed_Vyse']: ValorantAgent.Vyse,
};

export const OWAgentNamesList: ValorantAgent[] = [
  ValorantAgent.Phoenix,
  ValorantAgent.Raze,
  ValorantAgent.Breach,
  ValorantAgent.Brimstone,
  ValorantAgent.Viper,
  ValorantAgent.Omen,
  ValorantAgent.Sova,
  ValorantAgent.Sage,
  ValorantAgent.Jett,
  ValorantAgent.Cypher,
  ValorantAgent.Reyna,
  ValorantAgent.Killjoy,
  ValorantAgent.KAYO,
  ValorantAgent.Yoru,
  ValorantAgent.Skye,
  ValorantAgent.Astra,
  ValorantAgent.Chamber,
  ValorantAgent.Neon,
  ValorantAgent.Fade,
  ValorantAgent.Harbor,
  ValorantAgent.Gekko,
  ValorantAgent.Deadlock,
  ValorantAgent.Iso,
  ValorantAgent.Clove,
  ValorantAgent.Vyse,
];

export const ValorantAgentToAgentName: Record<ValorantAgent | 'Unknown', string> = {
  Unknown: 'Teammate',
  [ValorantAgent.Phoenix]: 'Phoenix',
  [ValorantAgent.Raze]: 'Raze',
  [ValorantAgent.Breach]: 'Breach',
  [ValorantAgent.Brimstone]: 'Brimstone',
  [ValorantAgent.Viper]: 'Viper',
  [ValorantAgent.Omen]: 'Omen',
  [ValorantAgent.Sova]: 'Sova',
  [ValorantAgent.Sage]: 'Sage',
  [ValorantAgent.Jett]: 'Jett',
  [ValorantAgent.Cypher]: 'Cypher',
  [ValorantAgent.Reyna]: 'Reyna',
  [ValorantAgent.Killjoy]: 'Killjoy',
  [ValorantAgent.KAYO]: 'KAY/O',
  [ValorantAgent.Yoru]: 'Yoru',
  [ValorantAgent.Skye]: 'Skye',
  [ValorantAgent.Astra]: 'Astra',
  [ValorantAgent.Chamber]: 'Chamber',
  [ValorantAgent.Neon]: 'Neon',
  [ValorantAgent.Fade]: 'Fade',
  [ValorantAgent.Harbor]: 'Harbor',
  [ValorantAgent.Gekko]: 'Gekko',
  [ValorantAgent.Deadlock]: 'Deadlock',
  [ValorantAgent.Iso]: 'Iso',
  [ValorantAgent.Clove]: 'Clove',
  [ValorantAgent.Vyse]: 'Vyse',
};

export const MeAgentToAgent: Record<string, ValorantAgent> = {
  [`${ValorantAgent.Phoenix}_PC_C`]: ValorantAgent.Phoenix,
  [`${ValorantAgent.Raze}_PC_C`]: ValorantAgent.Raze,
  [`${ValorantAgent.Breach}_PC_C`]: ValorantAgent.Breach,
  [`${ValorantAgent.Brimstone}_PC_C`]: ValorantAgent.Brimstone,
  [`${ValorantAgent.Viper}_PC_C`]: ValorantAgent.Viper,
  [`${ValorantAgent.Omen}_PC_C`]: ValorantAgent.Omen,
  [`${ValorantAgent.Sova}_PC_C`]: ValorantAgent.Sova,
  [`${ValorantAgent.Sage}_PC_C`]: ValorantAgent.Sage,
  [`${ValorantAgent.Jett}_PC_C`]: ValorantAgent.Jett,
  [`${ValorantAgent.Cypher}_PC_C`]: ValorantAgent.Cypher,
  [`${ValorantAgent.Reyna}_PC_C`]: ValorantAgent.Reyna,
  [`${ValorantAgent.Killjoy}_PC_C`]: ValorantAgent.Killjoy,
  [`${ValorantAgent.KAYO}_PC_C`]: ValorantAgent.KAYO,
  [`${ValorantAgent.Yoru}_PC_C`]: ValorantAgent.Yoru,
  [`${ValorantAgent.Skye}_PC_C`]: ValorantAgent.Skye,
  [`${ValorantAgent.Astra}_PC_C`]: ValorantAgent.Astra,
  [`${ValorantAgent.Chamber}_PC_C`]: ValorantAgent.Chamber,
  [`${ValorantAgent.Neon}_PC_C`]: ValorantAgent.Neon,
  [`${ValorantAgent.Fade}_PC_C`]: ValorantAgent.Fade,
  [`${ValorantAgent.Harbor}_PC_C`]: ValorantAgent.Harbor,
  [`${ValorantAgent.Gekko}_PC_C`]: ValorantAgent.Gekko,
  [`${ValorantAgent.Deadlock}_PC_C`]: ValorantAgent.Deadlock,
  [`${ValorantAgent.Iso}_PC_C`]: ValorantAgent.Iso,
  [`${ValorantAgent.Clove}_PC_C`]: ValorantAgent.Clove,
  [`${ValorantAgent.Vyse}_PC_C`]: ValorantAgent.Vyse,
};

export const AgentNameToAgent: Record<string, ValorantAgent> = {
  Phoenix: ValorantAgent.Phoenix,
  Raze: ValorantAgent.Raze,
  Breach: ValorantAgent.Breach,
  Brimstone: ValorantAgent.Brimstone,
  Viper: ValorantAgent.Viper,
  Omen: ValorantAgent.Omen,
  Sova: ValorantAgent.Sova,
  Sage: ValorantAgent.Sage,
  Jett: ValorantAgent.Jett,
  Cypher: ValorantAgent.Cypher,
  Reyna: ValorantAgent.Reyna,
  Killjoy: ValorantAgent.Killjoy,
  KAYO: ValorantAgent.KAYO,
  Yoru: ValorantAgent.Yoru,
  Skye: ValorantAgent.Skye,
  Astra: ValorantAgent.Astra,
  Chamber: ValorantAgent.Chamber,
  Neon: ValorantAgent.Neon,
  Fade: ValorantAgent.Fade,
  Harbor: ValorantAgent.Harbor,
  Gekko: ValorantAgent.Gekko,
  Deadlock: ValorantAgent.Deadlock,
  Iso: ValorantAgent.Iso,
  Clove: ValorantAgent.Clove,
  Vyse: ValorantAgent.Vyse,
};

export enum RoundPhase {
  gameStart = 'game_start',
  shopping = 'shopping',
  combat = 'combat',
  end = 'end',
  gameEnd = 'game_end',
}

export enum Team {
  attacker = 'attack',
  defender = 'defense',
}

export enum RoundOutcome {
  win = 'win',
  loss = 'loss',
}

export enum MatchOutcomes {
  victory = 'victory',
  draw = 'draw',
  defeat = 'defeat',
}

export enum OWGameModes {
  bomb = 'bomb',
  spikeRush = 'quick_bomb',
  deathmatch = 'deathmatch',
  escalation = 'escalation',
  swiftPlay = 'swift',
  range = 'range',
  teamDeathmatch = 'team_deathmatch',
}

export enum RankedMode {
  custom = '0',
  ranked = '1',
  unranked = '2',
}

export enum Ranks {
  unranked,
  iron1 = 3,
  iron2,
  iron3,
  bronze1,
  bronze2,
  bronze3,
  silver1,
  silver2,
  silver3,
  gold1,
  gold2,
  gold3,
  platinum1,
  platinum2,
  platinum3,
  diamond1,
  diamond2,
  diamond3,
  ascendant1,
  ascendant2,
  ascendant3,
  immortal1,
  immortal2,
  immortal3,
  radiant,
}

export enum ShieldTiers {
  none = 0,
  light = 1,
  heavy = 2,
  unknown = 3,
}

export enum AgentAbilities {
  // Astra
  gravityWell = 'gravityWell',
  novaPulse = 'novaPulse',
  nebula = 'nebula',
  cosmicDivide = 'cosmicDivide',

  // Brimstone
  incendiary = 'incendiary',
  skySmoke = 'skySmoke',
  stimBeacon = 'stimBeacon',
  orbitalStrike = 'orbitalStrike',

  // Pheonix
  curveball = 'curveball',
  hotHands = 'hotHands',
  blaze = 'blaze',
  runItBack = 'runItBack',

  // Sage
  slowOrb = 'slowOrb',
  healingOrb = 'healingOrb',
  barrierOrb = 'barrierOrb',
  resurrection = 'resurrection',

  // Sova
  shockBolt = 'shockBolt',
  reconBolt = 'reconBolt',
  owlDrone = 'owlDrone',
  huntersFury = 'huntersFury',

  // Viper
  poisonCloud = 'poisonCloud',
  toxicScreen = 'toxicScreen',
  snakeBite = 'snakeBite',
  vipersPit = 'vipersPit',

  // Cypher
  cyberCage = 'cyberCage',
  spyCam = 'spyCam',
  trapWire = 'trapWire',
  neuralTheft = 'neuralTheft',

  // Reyna
  devour = 'devour',
  dismiss = 'dismiss',
  leer = 'leer',
  empress = 'empress',

  // Killjoy
  alarmbot = 'alarmbot',
  turret = 'turret',
  nanoswarm = 'nanoswarm',
  lockdown = 'lockdown',

  // Breach
  flashpoint = 'flashpoint',
  faultLine = 'faultLine',
  afterShock = 'afterShock',
  rollingThunder = 'rollingThunder',

  // Omen
  paranoia = 'paranoia',
  darkCover = 'darkCover',
  shroudedStep = 'shroudedStep',
  fromTheShadows = 'fromTheShadows',

  // Jett
  updraft = 'updraft',
  tailwind = 'tailwind',
  cloudBurst = 'cloudBurst',
  bladeStorm = 'bladeStorm',

  // Raze
  blastPack = 'blastPack',
  paintShells = 'paintShells',
  boombot = 'boombot',
  showstopper = 'showstopper',

  // Skye
  trailBlazer = 'trailBlazer',
  guidingLight = 'guidingLight',
  regrowth = 'regrowth',
  seekers = 'seekers',

  // Yoru
  blindside = 'blindside',
  gatecrash = 'gatecrash',
  fakeout = 'fakeout',
  dimensionalDrift = 'dimensionalDrift',

  // KAYO
  flashdrive = 'flashdrive',
  zeropoint = 'zeropoint',
  fragment = 'fragment',
  nullcmd = 'nullcmd',

  // Chamber
  headHunter = 'headHunter',
  rendezvous = 'rendezvous',
  trademark = 'trademark',
  tourDeForce = 'tourDeForce',

  // Neon
  relayBolt = 'relayBolt',
  highGear = 'highGear',
  fastLane = 'fastLane',
  overdrive = 'overdrive',

  // Fade
  seize = 'seize',
  haunt = 'haunt',
  prowler = 'prowler',
  nightfall = 'nightfall',

  // Harbor
  cove = 'cove',
  highTide = 'highTide',
  cascade = 'cascade',
  reckoning = 'reckoning',

  // Gekko
  wingman = 'wingman',
  dizzy = 'dizzy',
  moshPit = 'moshPit',
  thrash = 'thrash',

  // Deadlock
  sonicSensor = 'sonicSensor',
  barrierMesh = 'barrierMesh',
  gravnet = 'gravnet',
  annihilation = 'annihilation',

  // Iso
  undercut = 'undercut',
  doubleTap = 'doubleTap',
  contingency = 'contingency',
  killContract = 'killContract',

  // Clove
  meddle = 'meddle',
  ruse = 'ruse',
  pickMeUp = 'pickMeUp',
  notDeadYet = 'notDeadYet',

  // Vyse
  arcRose = 'arcRose',
  shear = 'shear',
  razorvine = 'razorvine',
  steelGarden = 'steelGarden',
}

export enum NonLethalUltimates {
  resurrection = 'TX_Thorne_Heal', // Sage Res
  // TODO: Add Iso, Skye, Kayo, Astra
}

export enum LethalUltimates {
  brimstoneUlt = 'TX_Sarge_OrbitalStrike',
  chamberUlt = 'TX_Hud_Deadeye_X_GiantSlayer',
  jettUlt = 'TX_Hud_Wushu_X_Dagger',
  neonUlt = 'TX_Neon_Ult',
  razeUlt = 'TX_Clay_RocketLauncher',
  sovaUlt = 'TX_Hunter_BowBlast',
  // TODO: Add deadlock ult
}

export enum LethalAbilities {
  headHunter = 'TX_Hud_Deadeye_Q_Pistol',
  shockdart = 'TX_Hunter_ShockArrow',
  boombot = 'TX_Clay_Boomba',
  nanoSwarm = 'TX_KJ_Bees',
  afterShock = 'TX_Breach_FusionBlast',
  paintShells = 'TX_Clay_ClusterBomb',
  snakeBite = 'TX_Pandemic_AcidLauncher',
  hotHands = 'TX_Pheonix_Molotov',
  blaze = 'TX_Pheonix_FireWall',
  incindiary = 'TX_Sarge_MolotovLauncher',
  trailBlazer = 'TX_Guide4',
  turret = 'tx_KJ_turret',
  blastPack = 'TX_Clay_Satchel',
  fragment = 'TX_Gren_Icon',
  moshPit = 'TX_Aggrobot_Bubbles',
}

export enum Weapons {
  classic = 'TX_Hud_Pistol_Glock_S',
  shorty = 'TX_Hud_Shotgun_SawedOff_S',
  frenzy = 'TX_Hud_AutoPistol',
  ghost = 'TX_Hud_Pistol_Luger_S',
  sheriff = 'TX_Hud_Pistol_Revolver_S',
  bucky = 'TX_Hud_Pump',
  judge = 'TX_Hud_Shotguns_Spas12_S',
  stinger = 'TX_Hud_Vector',
  spectre = 'TX_Hud_SMG_MP5_S',
  bulldog = 'TX_Hud_Burst',
  guardian = 'tx_hud_dmr',
  phantom = 'TX_Hud_Assault_AR10A2_S',
  vandal = 'TX_Hud_Volcano',
  marshal = 'TX_Hud_Sniper_BoltAction_S',
  operator = 'TX_Hud_Operator',
  ares = 'TX_Hud_LMG',
  odin = 'TX_Hud_HMG',
  knife = 'TX_Hud_Knife_Standard_S',
  fallDamage = 'TX_Icon_FallenDamage_S',
  outlaw = 'TX_Hud_DoubleSniper',
}

export enum WeaponTiers {
  S = 'S',
  A = 'A',
  B = 'B',
  C = 'C',
}

const ValorantWeponTiers: Record<WeaponTiers, Array<Weapons>> = {
  [WeaponTiers.S]: [Weapons.vandal, Weapons.phantom, Weapons.operator, Weapons.odin, Weapons.knife],
  [WeaponTiers.A]: [Weapons.guardian, Weapons.bulldog, Weapons.sheriff, Weapons.judge, Weapons.marshal],
  [WeaponTiers.B]: [Weapons.stinger, Weapons.spectre, Weapons.bucky, Weapons.ghost, Weapons.ares],
  [WeaponTiers.C]: [Weapons.classic, Weapons.shorty, Weapons.frenzy],
};

export function getWeaponTier(weapon: Weapons): WeaponTiers | undefined {
  for (const [tier, weapons] of Object.entries(ValorantWeponTiers) as [WeaponTiers, Weapons[]][]) {
    if (weapons.includes(weapon)) {
      return tier;
    }
  }

  return undefined;
}

export function isBiggerWeaponTier(tier1: WeaponTiers, tier2: WeaponTiers): boolean {
  const tiers = ['S', 'A', 'B', 'C'];
  return tiers.indexOf(tier1) > tiers.indexOf(tier2);
}

export type LethalWeapons = Weapons | LethalUltimates | LethalAbilities;

export enum ValorantMap {
  haven = 'Triad',
  bind = 'Duality',
  split = 'Bonsai',
  ascent = 'Ascent',
  icebox = 'Port',
  breeze = 'Foxtrot',
  fracture = 'Canyon',
  pearl = 'Pitt',
  lotus = 'Jam',
  sunset = 'Juliett',
  district = 'HURM_Alley',
  piazza = 'HURM_Yard',
  kasbah = 'HURM_Bowl',
  range = 'Range',
  drift = 'HURM_Helix',
  abyss = 'Infinity',
}

export const ValorantMapToMapName: Record<ValorantMap | 'Unknown', string> = {
  [ValorantMap.haven]: 'Haven',
  [ValorantMap.bind]: 'Bind',
  [ValorantMap.split]: 'Split',
  [ValorantMap.ascent]: 'Ascent',
  [ValorantMap.icebox]: 'Icebox',
  [ValorantMap.breeze]: 'Breeze',
  [ValorantMap.fracture]: 'Fracture',
  [ValorantMap.pearl]: 'Pearl',
  [ValorantMap.lotus]: 'Lotus',
  [ValorantMap.sunset]: 'Sunset',
  [ValorantMap.district]: 'District',
  [ValorantMap.piazza]: 'Piazza',
  [ValorantMap.kasbah]: 'Kasbah',
  [ValorantMap.range]: 'Range',
  [ValorantMap.drift]: 'Drift',
  [ValorantMap.abyss]: 'Abyss',
  Unknown: '',
};

export enum ShopEventData {
  open = 'open',
  close = 'close',
}

export enum OWValorantEvent {
  matchStart = 'match_start',
  matchEnd = 'match_end',
  kill = 'kill',
  assist = 'assist',
  headshot = 'headshot',
  death = 'death',
  spikeDefused = 'spike_defused',
  spikePlanted = 'spike_planted',
  spikeDetonated = 'spike_detonated',
  killFeed = 'kill_feed',
  shop = 'shop',
}

export enum ScenesThatArentMaps {
  characterSelection = 'CharacterSelectPersistentLevel',
  mainMenu = 'MainMenu',
  init = 'Init',
}

export enum ClientState {
  waitingToSTart = 'WaitingToStart',
  leavingMap = 'LeavingMap',
  aborted = 'Aborted',
  inProgress = 'InProgress',
  init = 'Init',
  waitingPostMatch = 'WaitingPostMatch',
}

export enum OWGameFeature {
  gepInternal = 'gep_internal',
  me = 'me',
  gameInfo = 'game_info',
  matchInfo = 'match_info',
  kill = 'kill',
  death = 'death',
}
