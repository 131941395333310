import { z } from 'zod';

export const ZPatchNotes = z
  .object({
    version: z.string(),
    newFeatures: z.array(z.string()),
    bugFixes: z.array(z.string()),
    qolImprovements: z.array(z.string()),
  })
  .transform(data => {
    if (data.version.indexOf('.') !== -1) {
      data.version = data.version.replaceAll('.', '-');
    }

    return data;
  });

export type IPatchNotes = z.infer<typeof ZPatchNotes>;
