import { z } from 'zod';
import { ZMatchRound } from '../entities';
import { ZGuardianBehaviour } from '../../behaviour';

export const ZRoundReportArgs = z.object({
  matchRound: ZMatchRound,
  guardianBehaviours: ZGuardianBehaviour.array(),
});

export const ZRoundReportRequest = z.object({
  body: ZRoundReportArgs,
});

export type IRoundReportArgs = z.infer<typeof ZRoundReportArgs>;
export type IRoundReportRequest = z.infer<typeof ZRoundReportRequest>;

export const ZRoundRequest = z.object({
  body: ZMatchRound,
});
