import { OverwolfEventErrorInfo } from '@/shared/communication/effector-events/events';
import {
  GameMode,
  GuardianBehaviour,
  OWData,
  Ranks,
  ShieldTiers,
  Team,
  ValorantAgent,
  ValorantMap,
  ZMatchRound,
  ZPlayerNameSchema,
  ZScoreSchema,
  ZValorantScene,
} from '@guardian/common';
import { GuardianEvent, ZPlayerInfo } from '../types';
import { z } from 'zod';

export const ZPlayerKDA = z.object({
  kills: z.number(),
  assists: z.number(),
  deaths: z.number(),
  kdRatio: z.number(),
});

export type IPlayerKda = z.infer<typeof ZPlayerKDA>;

export const ZMatchPlayerBase = z.object({
  playerId: z.string().optional(),
  name: ZPlayerNameSchema.optional(),
  agent: z.nativeEnum(ValorantAgent).optional(),
});

export type IMatchPlayerBase = z.infer<typeof ZMatchPlayerBase>;

export const ZMatchStatePlayerTactical = z.object({
  firstKills: z.number(),
  firstDeaths: z.number(),
  firstAttackDeaths: z.number(),
  firstDefendDeaths: z.number(),
  firstAttackKills: z.number(),
  firstDefendKills: z.number(),
  traded: z.number(),
  tradesClosed: z.number(),
  tradesFailed: z.number(),
  gainedAdvantage: z.number(),
  lostAdvantage: z.number(),
  evenedAdvantage: z.number(),
  gaveAdvantage: z.number(),
});

export type IMatchPlayerTactical = z.infer<typeof ZMatchStatePlayerTactical>;

export const ZMatchStatePlayer = ZMatchPlayerBase.extend({
  isTeammate: z.boolean(),
  isMe: z.boolean(),
  money: z.number(),
  isAlive: z.boolean(),
  shield: z.nativeEnum(ShieldTiers).optional(),
  weapon: z.string().optional(),
  kda: ZPlayerKDA,
  killsPerWeapon: z.record(z.number()).optional(),
  rank: z.nativeEnum(Ranks).optional(),
  tactical: ZMatchStatePlayerTactical,
  ultPointsMax: z.number(),
  ultPoints: z.number(),
  guardianScore: z.number(),
  didDieThisRound: z.boolean(), // Once combat state starts, this should be set to false
});

export type IMatchPlayer = z.infer<typeof ZMatchStatePlayer>;

export const ZMatchStateSnapshot = z.object({
  attackPlayersAlive: z.number(),
  attackersHasAdvantage: z.boolean(),
  defensePlayersAlive: z.number(),
  defenseHasAdvantage: z.boolean(),
  noPlayersAdvantage: z.boolean(),
  roundNumber: z.number(),
  isRoundStarted: z.boolean(),
});

export type IMatchStateSnapshot = z.infer<typeof ZMatchStateSnapshot>;

export type MatchStateReportTeammate = {
  id: string;
  playerName?: string;
  agent?: ValorantAgent;
  guardianScore: number;
};

export type MatchStateReportPlayer = {
  playerAgent: ValorantAgent;
  guardianScore: number;
};

export type IMatchStateReport = {
  player?: MatchStateReportPlayer;
  teamPlayers?: MatchStateReportTeammate[];
  map?: ValorantMap | null;
};

export const StaticMatchStateJsonRepoUrl = 'F:/Guardian/Match States/Static Matches';

export const ZPotentialTrade = z.object({
  id: z.string(),
  eventId: z.string(),
  attacker: ZMatchStatePlayer,
  victim: ZMatchStatePlayer,
  startTimestamp: z.number(),
  timeoutLevel1: z.boolean(),
  timedOut: z.boolean(),
  traded: z.boolean(),
  roundNumber: z.number(),
});

export type PotentialTrade = z.infer<typeof ZPotentialTrade>;

export const ZLivePotentialTrade = ZPotentialTrade.extend({
  snapshot: ZMatchStateSnapshot,
});

export type LivePotentialTrade = z.infer<typeof ZLivePotentialTrade>;

export const ZTrade = z.object({
  potentialTradeId: z.string(),
  id: z.string(),
  timeForTrade: z.number(),
  timestamp: z.number(),
  timeInMatchString: z.string(),
  attacker: ZMatchStatePlayer,
  victim: ZMatchStatePlayer,
  traded: ZMatchStatePlayer,
  roundNumber: z.number(),
  includesLocalPlayer: z.boolean(),
});

export type Trade = z.infer<typeof ZTrade>;

export const ZTeamStatus = z.object({
  team: z.nativeEnum(Team).optional(),
  playersAlive: z.number(),
  clutch: z
    .object({
      player: ZMatchStatePlayer,
      enemies: z.array(ZMatchStatePlayer),
    })
    .optional(),
  hasPlayersAdvantage: z.boolean(),
});

export type ITeamStatus = z.infer<typeof ZTeamStatus>;

export const ZEconomyPlayer = z.object({
  currentMoney: z.number(),
  startRoundMoney: z.number(),
});

export const ZEconomyMap = z.record(z.string(), ZEconomyPlayer);

export type IEconomyMap = z.infer<typeof ZEconomyMap>;

export const ZMatchState = z.object({
  isReset: z.boolean(),
  isSavedToFile: z.boolean(),
  uniqueId: z.string(),
  startedAt: z.coerce.date().optional(),
  isLive: z.boolean(),
  map: z.nativeEnum(ValorantMap).nullable().optional(),
  gameMode: z.nativeEnum(GameMode),
  isCurrentGameModeSupported: z.boolean(),
  scoreboard: z.array(ZMatchStatePlayer),
  economyTracker: ZEconomyMap,
  scene: ZValorantScene.optional(),
  gameEventsWorkedDuringMatch: z.boolean(),
  isSurrendered: z.boolean(),
  // TODO: Zodify
  tactical: z.object({
    potentialTrades: ZPotentialTrade.array(),
    livePotentialTrades: ZLivePotentialTrade.array(),
    trades: ZTrade.array(),
  }),
  player: ZPlayerInfo.extend({
    startingTeam: z.nativeEnum(Team).optional(),
    potentialTradeId: z.string().optional(),
    tradeId: z.string().optional(),
    snapshotUponDeath: ZMatchStateSnapshot.optional(),
  }),
  kda: ZPlayerKDA.extend({
    headshots: z.number(),
    hsPercentage: z.number(),
    totalHeadshotHits: z.number(),
    totalFinalHeadshots: z.number(),
    totalHits: z.number(),
    totalDamage: z.number(),
  }),
  round: z.object({
    uniqueId: z.string(),
    roundNumber: z.number(),
    isFirstBlood: z.boolean(),
    isRoundStarted: z.boolean(),
    isSpikePlanted: z.boolean(),
    isBuyPhase: z.boolean(),
    isLive: z.boolean(),
    phase: z.literal('buy').or(z.literal('live')).or(z.literal('post')),
    spikePlantedTimestamp: z.number().optional(),
  }),
  matchRounds: ZMatchRound.array(),
  previousMyTeamStatus: ZTeamStatus.optional(),
  previousEnemyTeamStatus: ZTeamStatus.optional(),
  myTeamStatus: ZTeamStatus,
  enemyTeamStatus: ZTeamStatus,
  score: ZScoreSchema,
  previousEngagementTimestamp: z.number(),
});

export type IMatchState = z.infer<typeof ZMatchState>;

export type IOMatchState = {
  owEvents: OWData[];
  owEventErrors: OverwolfEventErrorInfo[];
  events: GuardianEvent[];
  behaviours: GuardianBehaviour[];
  state: IMatchState;
};
