import { appLogo, title, websiteHeaderCss } from './website-header.css';
import LogoUrl from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';

export const WebsiteHeader: React.FC = () => {
  const navigate = useNavigate();

  const onLogoClicked = () => {
    navigate('/');
  };

  return (
    <div className={websiteHeaderCss}>
      <img src={LogoUrl} className={appLogo} onClick={onLogoClicked} />
      <span className={title}>Guardian</span>
    </div>
  );
};
