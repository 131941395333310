import { z } from 'zod';
import {
  BehaviourNature,
  CharacterMood,
  GameMode,
  GameSessionCheckpointTypes,
  GameSessionRecommendationTypes,
  IValorantScene,
  MatchOutcomes,
} from '@guardian/common';
import { GuardianMatchChannelMessageTypes } from '@/shared/types';

export enum GameSessionEventTypes {
  matchStart = 'matchStart',
  matchEnd = 'matchEnd',
  gameOpen = 'gameOpen',
  gameRunning = 'gameRunning',
  gameClose = 'gameClose',
  sceneChange = 'sceneChange',
  roundStart = 'roundStart',
  roundEnd = 'roundEnd',
}

export type GameSessionGameRunningEvent = {
  type: GameSessionEventTypes.gameRunning;
  time: number;
  data: void;
};

export type GameSessionRoundStartEvent = {
  type: GameSessionEventTypes.roundStart;
  time: number;
  data: {
    round: number;
  };
};

export type GameSessionRoundEndEvent = {
  type: GameSessionEventTypes.roundEnd;
  time: number;
  data: {
    round: number;
  };
};

export type GameSessionSceneChangeEvent = {
  type: GameSessionEventTypes.sceneChange;
  time: number;
  data: {
    scene: IValorantScene;
  };
};

export type GameSessionMatchStartEvent = {
  type: GameSessionEventTypes.matchStart;
  time: number;
  data: {
    gameMode: GameMode;
  };
};

export type GameSessionMatchEndEvent = {
  type: GameSessionEventTypes.matchEnd;
  time: number;
  data: {
    gameMode: GameMode;
    outcome: MatchOutcomes;
  };
};

export type GameSessionGameOpenEvent = {
  type: GameSessionEventTypes.gameOpen;
  time: number;
  data: void;
};

export type GameSessionGameCloseEvent = {
  type: GameSessionEventTypes.gameClose;
  time: number;
  data: void;
};

export type GameSessionEvent =
  | GameSessionMatchStartEvent
  | GameSessionMatchEndEvent
  | GameSessionGameOpenEvent
  | GameSessionGameCloseEvent
  | GameSessionSceneChangeEvent
  | GameSessionRoundStartEvent
  | GameSessionRoundEndEvent;

export const ZGameSessionEntityBase = z.object({
  time: z.number(),
  characterMood: z.nativeEnum(CharacterMood).optional(),
});

export const ZGameSessionRecommendationBase = ZGameSessionEntityBase.extend({
  message: z.string(),
  tags: z
    .object({
      nature: z.nativeEnum(BehaviourNature),
      tag: z.string(),
    })
    .array()
    .optional(),
});

export const ZGameSessionCheckpoint = z.discriminatedUnion('type', [
  ZGameSessionEntityBase.extend({
    type: z.literal(GameSessionCheckpointTypes.warmupStart),
    data: z.object({ gameMode: z.nativeEnum(GameMode) }).optional(),
  }),
  ZGameSessionEntityBase.extend({
    type: z.literal(GameSessionCheckpointTypes.warmupEnd),
    data: z.object({ gameMode: z.nativeEnum(GameMode) }).optional(),
  }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.matchStart), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.victory), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.defeat), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.draw), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.gameOpen), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.gameClose), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.gameRunning), data: z.null().optional() }),
  ZGameSessionEntityBase.extend({ type: z.literal(GameSessionCheckpointTypes.enterLobby), data: z.null().optional() }),
]);

export type IGameSessionCheckpoint = z.infer<typeof ZGameSessionCheckpoint>;

export const ZGameSessionRecommendation = z.discriminatedUnion('type', [
  ZGameSessionRecommendationBase.extend({
    type: z.literal(GameSessionRecommendationTypes.takeBreak),
    data: z.object({
      breakLengthInSeconds: z.number(),
    }),
  }),
  ZGameSessionRecommendationBase.extend({
    type: z.literal(GameSessionRecommendationTypes.startMatch),
    data: z.null().optional(),
  }),
  ZGameSessionRecommendationBase.extend({
    type: z.literal(GameSessionRecommendationTypes.startWarmup),
    data: z.null().optional(),
  }),
  ZGameSessionRecommendationBase.extend({
    type: z.literal(GameSessionRecommendationTypes.endSession),
    data: z.null().optional(),
  }),
  ZGameSessionRecommendationBase.extend({
    type: z.literal(GameSessionRecommendationTypes.reviewMatch),
    data: z.null().optional(),
  }),
]);

export type IGameSessionRecommendation = z.infer<typeof ZGameSessionRecommendation>;

export const ZGameSessionCompletedMatch = z.object({
  gameMode: z.nativeEnum(GameMode),
  outcome: z.nativeEnum(MatchOutcomes),
  endTime: z.number(),
});

export const ZGameSessionCompletedWarmup = z.object({
  gameMode: z.nativeEnum(GameMode),
  endTime: z.number(),
});

export const ZGameSession = z.object({
  startDate: z.coerce.date(),
  latestEventTime: z.number(),
  recommendations: z.array(ZGameSessionRecommendation),
  checkpoints: z.array(ZGameSessionCheckpoint),
  preCalculatedValues: z.object({
    firstMatchStarted: z.boolean(),
    firstMatchEnded: z.boolean(),
    completedMatchCount: z.number(),
    warmupStartTime: z.number(),
    warmupEndTime: z.number(),
    completedMatches: z.array(ZGameSessionCompletedMatch),
    completedWarmups: z.array(ZGameSessionCompletedWarmup),
    previousMatchOutcome: z.nativeEnum(MatchOutcomes).optional(),
    latestMatchOutcome: z.nativeEnum(MatchOutcomes).optional(),
  }),
});

export type GameSession = z.infer<typeof ZGameSession>;

export function getNewGameSession(): GameSession {
  const now = new Date();
  return {
    startDate: now,
    latestEventTime: now.getTime(),
    recommendations: [],
    checkpoints: [],
    preCalculatedValues: {
      firstMatchEnded: false,
      completedMatchCount: 0,
      warmupEndTime: 0,
      warmupStartTime: 0,
      firstMatchStarted: false,
      completedMatches: [],
      completedWarmups: [],
    },
  };
}

export type IGameSessionEventEmitter = {
  [GameSessionEventTypes.gameOpen]: void;
  [GameSessionEventTypes.gameClose]: GameSessionGameCloseEvent['data'];
  [GameSessionEventTypes.matchStart]: GameSessionMatchStartEvent['data'];
  [GameSessionEventTypes.matchEnd]: GameSessionMatchEndEvent['data'];
  [GameSessionEventTypes.roundEnd]: GameSessionRoundEndEvent['data'];
  [GameSessionEventTypes.roundStart]: GameSessionRoundStartEvent['data'];
  [GameSessionEventTypes.sceneChange]: GameSessionSceneChangeEvent['data'];
  [GameSessionEventTypes.gameRunning]: GameSessionGameRunningEvent['data'];
};

export type CheckpointOrderDescription = {
  followingTypes: GameSessionCheckpointTypes[] | '*';
  previousTypes: GameSessionCheckpointTypes[] | '*';
};
