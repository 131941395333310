import { AppStatus, ResolutionSizeType } from '@guardian/common';
import { z } from 'zod';

const KEY_PREFIX = 'GU';

export enum LocalStorageKey {
  UserToken = 'USER_TOKEN',
  UserId = 'USER_ID',
  AppStatus = 'APP_STATUS',
  ResolutionSizeType = 'RESOLUTION_SIZE_TYPE',
  UserName = 'USER_NAME',
}

export const LocalStorageSchema = {
  [LocalStorageKey.UserToken]: z.string(),
  [LocalStorageKey.UserId]: z.string(),
  [LocalStorageKey.AppStatus]: z.nativeEnum(AppStatus),
  [LocalStorageKey.ResolutionSizeType]: z.nativeEnum(ResolutionSizeType),
  [LocalStorageKey.UserName]: z.string(),
};

export const getLocalStorageKey = (key: LocalStorageKey) => `${KEY_PREFIX}_${key}` as const;

export const getItem = <TKey extends LocalStorageKey>(key: TKey): z.infer<(typeof LocalStorageSchema)[TKey]> | null => {
  const item = localStorage.getItem(getLocalStorageKey(key));
  if (!item) return null;
  return LocalStorageSchema[key].parse(JSON.parse(item));
};

export const setItem = <TKey extends LocalStorageKey>(key: TKey, value: z.infer<(typeof LocalStorageSchema)[TKey]>): void => {
  const safeNewValue = LocalStorageSchema[key].parse(value);
  const stringifiedNewValue = JSON.stringify(safeNewValue);
  localStorage.setItem(getLocalStorageKey(key), stringifiedNewValue);
  window.dispatchEvent(new GuardianStorageEvent(key, localStorage.getItem(getLocalStorageKey(key)), stringifiedNewValue));
  return;
};

export const removeItem = (key: LocalStorageKey): void => {
  localStorage.removeItem(getLocalStorageKey(key));
};

export class GuardianStorageEvent<TKey extends LocalStorageKey> extends StorageEvent {
  readonly pdKey: TKey;
  static readonly STORAGE_EVENT_NAME = 'guardian-storage';
  constructor(key: TKey, oldValue: string | null, newValue: string | null) {
    super(GuardianStorageEvent.STORAGE_EVENT_NAME, {
      newValue,
      oldValue,
      key: getLocalStorageKey(key),
    });
    this.pdKey = key;
  }

  get newValueParsed() {
    if (!this.newValue || !this.pdKey) return null;
    return LocalStorageSchema[this.pdKey].parse(JSON.parse(this.newValue));
  }
}

export const subscribe = <TKey extends LocalStorageKey>(callback: (event: GuardianStorageEvent<TKey>) => void) => {
  window.addEventListener(GuardianStorageEvent.STORAGE_EVENT_NAME, callback as EventListener);
  return () => {
    window.removeEventListener(GuardianStorageEvent.STORAGE_EVENT_NAME, callback as EventListener);
  };
};
