export enum MatchAnalysisTitle {
  unfairMatchSmurf = 'unfairMatchSmurf',
  unfairMatchTeammateLeft = 'unfairMatchTeammateLeft',
  comebackMatch = 'comebackMatch',
  throwMatch = 'throwMatch',
  stompMatch = 'stompMatch',
  roughMatch = 'roughMatch',
  closeMatch = 'closeMatch',
  nineThreeCurseWin = 'nineThreeCurseWin',
  nineThreeCurseLoss = 'nineThreeCurseLoss',

  flawlessMatch = 'flawlessMatch',
  thrillerMatch = 'thrillerMatch',
  clutchMatch = 'clutchMatch', // TODO : Check player behaviours and tags
  quickyMatch = 'quickyMatch',
}

export enum MatchPlayerAnalysisTitle {
  leaver = 'leaver',
  smurf = 'smurf',
  carry = 'carry',
  thrower = 'thrower',
  clutch = 'clutch',
  choker = 'choker',
}
